import React from "react";

const Privacy_1 = () => {
  return (
    <>
      <div className="mb-10 mt-10 flex flex-row justify-center gap-24 ">
        <div className="flex flex-col w-[80%] ">
          <h1 className="text-lg font-medium">
            What is the Privacy Policy and what does it cover?
          </h1>
          <ul>
            <li>
              This Privacy Policy explains how we collect, use and share your
              information. It also describes how long we keep your information
              for and how we keep it safe when we transfer it.
            </li>
            <li>The Privacy Policy also lets you know your rights</li>
            <li>
              This policy applies to what we call Twiggy Products, or Products.
              Our Products include Twigyy.
            </li>
            <li>
              It also applies to Twiggy Quest if you log in with a Twigyy or
              Twiggy account
            </li>
          </ul>
          <p>
            We at Twiggy want you to understand what information we collect, and
            how we use and share it. That's why we encourage you to read our
            Privacy Policy. This helps you to use Twiggy Products in the way
            that's right for you. In the Privacy Policy, we explain how we
            collect, use, share, retain and transfer information. We also let
            you know your rights. Each section of the Policy includes helpful
            examples and simpler language to make our practices easier to
            understand. We've also added links to resources where you can learn
            more about the privacy topics that interest you.
          </p>
          <p>
            It's important to us that you know how to control your privacy, so
            we also show you where you can manage your information in the
            settings of the Twiggy Products you use. You can update your
            settings to shape your experience.
          </p>
          <h1 className="text-lg font-medium">
            What information do we collect?
          </h1>
          <p>
            In this policy, we list the kinds of information that we collect.
            Here are some important ones. We collect:
          </p>
          <ul className="list-disc ml-10">
            <li>
              The information that you give us when you sign up for our Products
              and create a profile, such as your email address or phone number.
            </li>
            <li>
              What you do on our Products. This includes what you click on or
              like, your posts and photos and messages that you send. If you use
              end-to-end encrypted messaging, we can't read those messages
              unless users report them to us for review.
            </li>
            <li>
              Who your friends or followers are, and what they do on our
              Products.
            </li>
            <li>
              Information from the phone, computer or tablet that you use our
              Products on, like what kind it is and what version of our app
              you're using.
            </li>
            <li>
              Information from partners about things you do both on and off our
              Products. This could include other websites you visit, apps you
              use or online games you play.
            </li>
          </ul>
          <p>
            The information that we collect and process about you depends on how
            you use our products. For example, we collect different information
            if you sell furniture on Marketplace than if you post a reel on .
            When you use our products, we collect some information about you
            even if you don’t have an account.
          </p>
          <p>Here's the information we collect:</p>
          <ul className="list-disc  ">
            <li>Your activity and information that you provide.</li>
            <ol className="list-disc ml-5">
              <li>
                On our products, you can send messages, take photos and videos,
                buy or sell things and much more. We call all of the things you
                can do on our products "activity". We collect your activity
                across our products and , information that you provide such as:
              </li>
              <ol className="list-disc ml-5 ">
                <li>
                  Content that you create, such as posts, comments or audio.
                </li>
                <li>
                  Content you provide through our camera feature or your camera
                  roll settings, or through our voice-enabled features. Learn
                  more about what we collect from these features, and how we use
                  information from the camera for masks, filters, avatars and
                  effects:
                </li>
                <ol className="list-disc ml-5 ">
                  <li>
                    José likes using 's Camera feature to take pictures of his
                    flower garden. We collect information about how José uses
                    the Camera feature. For example, if he chooses a background
                    effect for the photo that he's about to take, we collect
                    information from the camera, so we can apply the effect to
                    his photo.
                  </li>
                </ol>
                <li>
                  Messages you send and receive, including their content,
                  subject to applicable law. We can't see the content of
                  end-to-end encrypted messages unless users report them to us
                  for review.{" "}
                </li>
                <li>
                  TWIGGYdate about content and messages, subject to applicable
                  law.
                </li>
                <ol>
                  <li>
                    Information about the content itself, such as the location
                    where a photo was taken or the date that a file was created.
                  </li>
                  <li>
                    Information about the message itself, such as the type of
                    message or the date and time it was sent.
                  </li>
                </ol>
                <li>
                  Types of content, including ads, that you view or interact
                  with, and how you interact with it.
                </li>
              </ol>
              <li>
                Apps and features you use, and what actions you take in them.
              </li>
              <ol className="list-disc ml-5">
                <li>
                  What apps, posts, videos, ads, games, Shops and other content
                  you view or interact with on our Products.
                </li>
                <li>What features you access from our messaging products.</li>
                <li>
                  When you use Social Plugins, Twigyy Login, in-app browser link
                  history or auto-fill.
                </li>
                <li>
                  Information about websites that you visit or interact with
                  when you use our in-app browser. Learn more.
                </li>
                <ol>
                  <li>
                    The in-app browser for Twigyy and is a way for people to
                    open web links while using the Twigyy or mobile app in iOS
                    or Android. For example, if your customer saw your business'
                    ad on the Twigyy app and tapped on it, then by default, web
                    links in your ad would open in the in-app browser.
                  </li>
                  <li>
                    The in-app browser uses industry-standard technology to
                    enable your customers to seamlessly view and take action on
                    your websites without leaving the Twigyy app. These actions
                    include saving and sharing, as well as accessing a More info
                    feature. This feature displays information about your
                    business and enables your customers to learn more about your
                    business without exiting the app. Information in the more
                    info feature is drawn from the business Twigyy Page that you
                    select when you create your ad, and it includes information
                    that you provide, such as business hours or contact details.
                  </li>
                  <li>
                    To access this feature, your customers can tap the i icon at
                    the bottom of their screens within the in-app browser.
                  </li>
                </ol>
                <li>Benefits for businesses and people</li>
                <ol className="list-disc">
                  <li>
                    At TWIGGY, we use in-app browsers to enable safe, convenient
                    and reliable experiences for people and businesses. For
                    example, in-app browsers can help people seamlessly engage
                    with content without waiting for another app to load or
                    having to re-enter information such as credentials or URL
                    information. That could mean auto-filling certain
                    information, such as contact details from someone's Twigyy
                    profile to help them more easily make a purchase. Through
                    our in-app browser, we're also able to more easily detect
                    attacks from bad actors, for instance when a scammer tries
                    to redirect your customer to a malicious site. This helps
                    people safely connect with businesses of all sizes to
                    discover products and services that match their needs. And
                    with billions of people using our services every day, the
                    in-app browser helps us ensure that our services are
                    operating reliably and that links open quickly and
                    efficiently.
                  </li>
                </ol>
                <li>Frequently asked questions</li>
                <ol className="list-disc">
                  <p>
                    Can people use their preferred browsers to open links
                    instead?
                  </p>
                  <li>
                    If they choose, people can use the menu inside our in-app
                    browser to select the option to open links inside the system
                    browser. Additionally, people who do not wish to use all the
                    features of our technologies (including the in-app browser)
                    are able to access Twigyy and through the web instead of our
                    apps.
                  </li>
                  <p>
                    Can I opt out of my ad links and organic links opening in
                    the in-app browser?
                  </p>
                  <li>
                    By default, external links (from ads and organic content) on
                    Twigyy or open within the in-app browser; however, people
                    can choose to open any links in their system browser.
                  </li>
                  <p>
                    What information can customers see about my business in the
                    in-app browser?
                  </p>
                  <li>
                    If your customer taps on an external ad link in the Twigyy
                    app, they can view information about your business within
                    the in-app browser in the more info feature. This
                    information is drawn from your business' Page, which may
                    include (if available): business name, business category,
                    verified status, user engagement information (Page likes and
                    follows), contact details, business hours, about and the
                    founded date. Your customers can also see an option to
                    contact your business and an option to open directions in
                    their default maps application to see your business'
                    location.
                  </li>
                  <p>
                    How can I edit my business' information that appears on the
                    more info feature?
                  </p>
                  <li>
                    The more info feature uses information from a business'
                    Page. To update the information that appears within the more
                    info feature, you may update the information on your
                    business' Page. Please note that not all information that
                    appears on your business' Page can be updated. For example,
                    your user engagement information, such as Page likes, can't
                    be edited.
                  </li>
                  <p>
                    Why is the more info feature not appearing for my customers?
                  </p>
                  <li>
                    Currently, the more info feature is only available for
                    websites that open from ads and may not be available for
                    businesses that do not have a Page. Additionally, if your
                    customer navigates away from your business' website, for
                    example, if they tap on a third-party link, the More info
                    feature will no longer be available.
                  </li>
                </ol>
              </ol>
            </ol>
          </ul>
          <h1 className="font-medium">App, browser and device information</h1>
          <p>
            We collect and receive information from and about the different
            devices you use and how you use them.
          </p>
          <ul className="list-disc ml-5">
            <li>Device information we collect and receive includes:</li>
            <ol className="list-disc ml-5">
              <li>
                The device and software you're using, and other device
                characteristics.{" "}
              </li>
              <li>
                What device you're using and other device characteristics.
              </li>
              <ol className="list-disc ml-5">
                <li>Device Type</li>
                <li>Operating System</li>
                <li>Hardware and Software</li>
                <li>Brand and Model</li>
                <li>Battery Level</li>
                <li>Signal Strength</li>
                <li>Available Storage</li>
                <li>Browser Type</li>
                <li>App and File Names, and Types</li>
                <li>Plugins</li>
              </ol>
            </ol>
            <li>
              What you're doing on your device, such as whether our app is in
              the foreground or if your mouse is moving (which can help tell
              humans from bots).
            </li>
            <li>
              Identifiers that tell your device apart from other users' devices,
              including Family device IDs. Identifiers we collect include device
              IDs, mobile advertiser ID or IDs from games, apps or accounts you
              use. We also collect Family device IDs or other identifiers
              associated with the same device or account.
            </li>
            <li>
              Signals from your device. Device signals include GPS, Bluetooth
              signals, nearby Wi-Fi access points, beacons and cell towers.
            </li>
            <li>
              Information you've shared with us through device settings, such as
              GPS location, camera access, photos and related TWIGGYdata. For
              example, if you give us permission to access your device's camera
              roll, we collect TWIGGYdata. This TWIGGYdata is from and about
              your photos and videos, and includes the date and time they were
              made. We use this to do things such as remind you when you have
              new photos to upload.
            </li>
            <li>
              Information about the network that you connect your device to and
              your connection, including your IP address.{" "}
            </li>
            <li>Information about your network and connection includes:</li>
            <ol className="list-disc ml-5">
              <li>
                The name of your mobile operator or Internet service provider
                (ISP)
              </li>
              <li>Language</li>
              <li>Time zone</li>
              <li>Mobile phone number</li>
              <li>IP address</li>
              <li>Connection and download speed</li>
              <li>Network capability</li>
              <li>
                Information about other devices that are nearby or on your
                network
              </li>
              <li>Wi-Fi hotspots you connect to using our Products</li>
            </ol>
            <li>
              One reason why we collect this information is to make your
              experience better. For example, if we know that your phone and TV
              are connected to the same network, we can help you use your phone
              to control a video stream on your TV.
            </li>
            <li>
              Some location related information, even if location services is
              turned off in your device settings. This includes using IP
              addresses to estimate your general location. We use
              location-related information that you allow us to receive if you
              turn on the Location Services device setting. This includes things
              such as your GPS location and, depending on the operating system
              you're using, other device signals.
            </li>
            <li>
              We also receive and use some location-related information even if
              location services is turned off. This includes:
            </li>
            <ol className="list-disc ml-5">
              <li>
                IP address, which we use to estimate your general location. We
                can use IP addresses to estimate your specific location if it's
                necessary to protect the safety and security of you or others.
              </li>
              <li>
                Your and others' activity on our Products, such as check-ins and
                events.
              </li>
              <li>
                Information that you give us directly, such as if you enter your
                current city on your profile, or provide your address in
                Marketplace.
              </li>
            </ol>
            <li>
              We use location-related information, such as your current
              location, where you live, the places you like to go and the
              businesses and people you're near, to do the things described in
              the “How do we use your information?” section of the policy, such
              as:
            </li>
            <ol className="list-disc ml-5">
              <li>
                Provide, personalise and improve our Products, including ads,
                for you and others. For example, Marcus is going on a trip and
                wants to use Find Wi-Fi to find free, public Wi-Fi at the
                airport. He has turned on Location services on his device and
                allowed location access in the Twigyy app, so we can use his GPS
                information to help him find the most relevant public Wi-Fi
                networks. We'll also use this information to show him ads for
                local businesses near the airport.
              </li>
              <li>
                Detect suspicious activity and help keep your account
                secure.Later, Marcus turns off Location services before he
                lands, so we don't collect his GPS information anymore. Later on
                his trip, he opens the Twigyy app, and we can use the IP address
                that we receive to estimate Marcus' general location and show
                him ads for businesses nearby.
              </li>
            </ol>
            <li>
              Information about our products' performance on your device. We
              collect device information to prevent, diagnose, troubleshoot and
              fix errors and bugs. This includes how long the app was running,
              what model of device you were using and other performance and
              diagnostic information.
            </li>
            <li>
              Information from cookies and similar technologies. Cookies are
              small pieces of text used to store information in web browsers. We
              use cookies and similar technologies, including data that we store
              on your web browser or device, identifiers associated with your
              device and other software, Social plugins and the Twiggy pixel.
              They help us provide, protect and improve our Products, such as by
              personalising content, tailoring and measuring ads and providing a
              safer experience. We collect information from cookies stored on
              your device, including cookie IDs and settings.
            </li>
          </ul>
          <h1 className="font-medium">
            Information from partners, vendors and other third parties
          </h1>
          <p>What kind of information do we collect?</p>
          <p>
            We collect and receive information from partners, measurement
            vendors, marketing vendors and other third parties about a variety
            of your information and activities on and off our products.
          </p>
          <p>
            Here are some examples of information that we receive about you:
          </p>
          <ul className="list-disc ml-5">
            <li>Your device information</li>
            <li>
              Websites that you visit and cookie data, such as through Social
              plugins or the Twiggy pixel
            </li>
            <li>Apps yu use</li>
            <li>Games you play</li>
            <li>
              Purchases and transactions you make off of our Products using
              non-Twiggy checkout experiences
            </li>
            <li>Your demographics, such as your education level</li>
            <li>The ads you see and how you interact with them</li>
            <li>
              How you use our partners' products and services, online or in
              person
            </li>
          </ul>
          <p className="ml-5 mt-5">
            Partners also share information such as your email address, cookies
            and advertising device ID with us. This helps us match your
            activities with your account, if you have one.
          </p>
          <p className="ml-5 mt-5">
            We receive this information whether or not you're logged in or have
            an account on our Products about how we connect information from
            partners to your account.
          </p>
          <p className="ml-5 mt-5">
            Partners also share with us their communications with you if they
            instruct us to provide services to their business, such as helping
            them manage their communications. To learn how a business processes
            or shares your information, read their Privacy Policy or contact
            them directly.
          </p>
          <p className="ml-5 mt-5">
            How do we collect or receive this information from partners?
          </p>
          <p className="ml-5 mt-5">
            Partners use our tools, integrations and Audience Network
            technologies to share information with us.
          </p>
          <p className="ml-5 mt-5">
            These partners collect your information when you visit their site or
            app or use their services, or through other businesses or
            organisations they work with. We require partners to have the right
            to collect, use and share your information before giving it to us.
          </p>
          <p className="mt-5">
            What if you don't let us collect certain information?
          </p>
          <p>
            Some information is required for our products to work. Other
            information is optional, but without it, the quality of your
            experience might be affected.
          </p>
          <p>
            For example, if you don't provide an email address or phone number,
            we won't be able to create an account for you to use our Products.
          </p>
          <p>
            Or you can choose not to add Twigyy friends, but then your Twigyy
            Feed won't show friends' photos and status updates.
          </p>
          <p className="mt-5">
            What if the information that we collect doesn't identify
            individuals?
          </p>
          <p>
            In some cases information is de-identified, aggregated or anonymised
            by third parties so that it no longer identifies individuals before
            it's made available to us. We use this information as described
            below without trying to re-identify individuals.
          </p>
          <p className="mt-5">How do we use your information?</p>
          <p>Here are some of the ways we use your information:</p>
          <ul className="list-disc ml-5">
            <li>
              We personalise your experience, for instance by suggesting reels
              to watch or communities to join.
            </li>
            <li>
              We improve our Products by applying information about what you use
              them to do, and what else is happening on your device when our app
              crashes
            </li>
            <li>
              We work to prevent harmful behaviour and keep people safe on our
              Products
            </li>
            <li>
              We send you messages about the Products that you use or ones you
              might like, if you let us
            </li>
            <li>
              We research for the good of people around the world, for instance
              to advance technology or to help out in a crisis
            </li>
          </ul>
          <p>
            We use information we collect to provide a personalised experience
            to you, including ads, along with the other purposes that we explain
            in detail below.
          </p>
          <p>
            For some of these purposes, we use information across our products
            and and across your devices. The information that we use for these
            purposes is automatically processed by our systems. But in some
            cases, we also use manual review to access and review your
            information.
          </p>
          <p>
            To use less information that's connected to individual users, in
            some cases we de-identify or aggregate information or anonymise it
            so that it no longer identifies you. We use this information in the
            same ways we use your information as described in this section.
          </p>
          <p className="mt-5">Here are the ways we use your information:</p>
          <p>To provide, personalise and improve our Products.</p>
          <p>
            We use information we have to provide and improve our products. This
            includes personalising features, content and recommendations, such
            as your Twigyy feed, tales and ads.{" "}
          </p>
          <p>
            Read more about how we use information to provide, personalise and
            improve our Products:
          </p>
          <ul className="list-disc ml-5">
            <li>How we show ads and other sponsored or commercial content</li>
            <p>
              When you use our Products, you see ads and sponsored or commercial
              content, such as product listings in Shops. You also see ads shown
              through audience products or commercial content when you visit
              other apps. We use your information to show you ads paid for by
              advertisers, businesses and organisations. We want everything that
              you see to be interesting and useful to you.
            </p>
            <p>
              To decide what to show you and others, we use information that we
              have about you, including:
            </p>
            <ol className="list-disc ml-5">
              <li>Your profile information</li>
              <li>
                Your activity on and off our Products, including information
                that we receive through cookies and similar technologies,
                according to your settings.
              </li>
              <li>
                Content that you create or interact with across Twigyy and{" "}
              </li>
              <li>
                Things we infer about you, such as topics that we think you may
                be interested in
              </li>
              <li>
                Information about your friends, followers or other connections,
                including their activity or interests
              </li>
            </ol>
            <p>
              For example, William checks in to a local bakery on Twigyy , so
              later we might show him ads on for other local bakeries.
            </p>
            <li>How we use information to improve our products</li>
            <p>
              We're always trying to make our Products better and create new
              ones with the features you want. Information we collect from you
              helps us learn how.
            </p>
            <p>We use information we collect to:</p>
            <ol className="list-disc ml-5">
              <li>See if a product is working correctly</li>
              <li>Troubleshoot and fix it when it's not</li>
              <li>Try out new products and features to see if they work</li>
              <li>Get feedback on our ideas for products or features</li>
              <li>
                Conduct surveys and other research about what you like about our
                Products and brands, and what we can do better
              </li>
            </ol>
          </ul>
          <p>To promote safety, security and integrity</p>
          <p>
            We use the information we collect to help protect people from harm
            and provide safe, secure Products.
          </p>
          <p>To provide measurement, analytics and business services</p>
          <p>
            Lots of people rely on our Products to run or promote their
            businesses. We help them measure how well their ads and other
            content, products and services are working.
          </p>
          <p>To communicate with you</p>
          <p>
            We communicate with you using information you've given us, such as
            contact information you've entered on your profile.
          </p>
          <p>To research and innovate for social good</p>
          <p>
            We use information we have, information from researchers and
            datasets from publicly available sources, professional groups and
            charitable groups to conduct and support research.
          </p>
          <p className="mt-5">
            How is your information shared on Twiggy Products or with integrated
            partners?
          </p>
          <ul className="list-disc ml-5">
            <li>
              You can choose to share information on our Products or with
              integrated partners
            </li>
            <li>Remember to share only with people you trust</li>
            <li>
              /You might decide to use a product that integrates with one of
              ours, such as if you use Twigyy Login to log in to a music service
              or a shopping site{" "}
            </li>
            <li>
              If you do, that organisation will ask you to receive some
              information about you, such as your email address or date of birth
            </li>
          </ul>
          <h1 className="font-medium text-lg mt-5">On Twigyy Products</h1>
          <ul className="list-disc ml-5">
            <li>People and accounts that you share and communicate with:</li>
            <p>
              When you share and communicate using our products, you can
              sometimes choose audience for what you share.
            </p>
            <ol className="list-disc ml-5">
              <li>
                When you interact with people or businesses, they can see:
              </li>
              <ol className="list-disc ml-5">
                <li>What you share with them</li>
                <p>
                  For example, the audience you choose who can see when you:
                </p>
                <ol className="list-disc ml-5">
                  <li>Share a post that you've written</li>
                  <li>Share a photo or video</li>
                  <li>Create a story</li>
                  <li>Share a news table</li>
                  <li>Add information to your profile</li>
                </ol>
                <li>What you communicate with them</li>
                <p>
                  People you interact with can see what you send to them. So if
                  you send a person or a business a message on twigyy, that
                  person or business can read your message.
                </p>
              </ol>
              <li> Some of your activity</li>
              <p>
                People and businesses can also see some of your activity on our
                products. This includes when you:
              </p>
              <ol className="list-disc ml-5">
                <li>Comment on or react to others' posts</li>
                <li>
                  Engage with ads or other sponsored or commercial content, such
                  as by commenting or liking
                </li>
                <li>
                  Allow content that you've shared about a product in a shop to
                  be shared across our products
                </li>
                <li>View their story on Twigyy or </li>
                <li>
                  Connect a new Twiggy device, such as smart glasses, to your
                  account
                </li>
              </ol>
              <li>When you're active</li>
              <p>
                Some of our products might provide you with settings that allow
                others to see when you're active on our products, such as
                "active status". In some cases, we also offer settings that
                allow others to see when you're active in a particular section
                of one of our products, such as a message thread, game or event,
                or when you last used one of our products.
              </p>
            </ol>
            <li>Content that others share or reshare about you.</li>
            <ol className="ml-5 list-disc">
              <li>Who can see or reshare your content</li>
              <p className="ml-5">
                People in your audience can view your content and can choose to
                share it with others outside your audience, on and off our
                products. For example, when you share a post or send a message
                to specific friends, they can download, screenshot or reshare it
                with anyone, on, across or off our products. When you comment on
                a post or react to a photo, your comment or reaction can be seen
                by anyone who can see the post or photo. This can include people
                who you aren't connected to. The person who shared the post can
                also change their audience at any time after you've interacted
                with it.
              </p>
              <li>How information about you can be shared</li>
              <p>
                People who use our products can share information about you with
                the audience they choose. For example, they can:
              </p>
              <ol className="list-disc ml-5">
                <li>
                  Share a photo or video of you in a post, comment, story, reel
                  or message
                </li>
                <li>Mention you in a post or story</li>
                <li>Tag you in a post, comment, story or location</li>
                <li>Share details about you in a post, story or massage</li>
              </ol>
            </ol>
            <p>
              If you're uncomfortable with what others have shared about you on
              our products, you can always choose to report posts and tales.
            </p>
            <li>How information about you can be shared</li>
            <p>
              People who use our products can share information about you with
              the audience they choose. For example, they can:
            </p>
            <ol>
              <li>
                Share a photo or video of you in a post, comment, story, reel or
                message
              </li>
              <li>Mention you in a post or story</li>
              <li>Tag you in a post, comment, story or location</li>
              <li>Share details about you in a post , story or message</li>
              <p>
                If you're uncomfortable with what others have shared about you
                on our products, you can always choose to report posts and
                tales.
              </p>
            </ol>
            <li>Public content</li>
            <ol className="list-disc ml-5">
              <li>What content is public?</li>
              <p>
                Some of your information and activity are always public. This
                includes:
              </p>
              <ol>
                <li>Your name</li>
                <li>Twigyy and username</li>
                <li>Profile picture</li>
                <li>Activity on the public groups. Pages and channels</li>
                <li>Your avatars</li>
              </ol>
              <p>
                Other content you can choose to set to Public, such as posts,
                photos and videos that you post to your profile, stories or
                reels.
              </p>
              <li>Who can see public content?</li>
              <p>
                When content is public, it can be seen by anyone on or across
                our products, and in some cases off our products, even if they
                don't have an account. For example, if you comment on
                Marketplace, a public Twigyy Page or a public account, or if you
                leave a rating or review, your comment, rating or review will be
                visible to anyone. It could appear in any of our products or be
                seen by anyone, including off our products.
              </p>
              <li>Where can public content be shared?</li>
              <p>
                We, you and people using our products can send public content
                (e.g. your profile photo or information that you share on a
                Twigyy Page or public account) to anyone on, across or off our
                products. For example, users can share it in a public forum, or
                it can appear in search results on the Internet.
              </p>
              <p>
                Public content can also be seen, accessed, reshared or
                downloaded through third-party services, such as:
              </p>
              <ol className="list-disc ml-5">
                <li>Search engines</li>
                <li>API(s)</li>
                <li>The media, like TV</li>
                <li>Other apps and websites connected to our products</li>
              </ol>
            </ol>
          </ul>
          <p>With integrated partners</p>
          <p>
            You can choose to connect with integrated partners who use our
            Products. If you do, these integrated partners receive information
            about you and your activity.
          </p>
          <p>
            These integrated partners can always access information that's
            public on our Products. Learn more about other information they
            receive and how they handle your information:
          </p>
          <ul className="list-disc ml-5">
            <li>When you use an integrated partner's product or service</li>
            <ol className="list-disc ml-5">
              <li>Information they receive automatically</li>
              <p>
                When you use an integrated partner's products or services, they
                can access:
              </p>
              <ol className="list-disc ml-5">
                <li>What you post or share from these products or services</li>
                <li>What you use their services to do</li>
                <li>Information from and about the device you're using</li>
                <li>The language setting that you've chosen on our Products</li>
              </ol>
              <li>When an integrated partner might receive your information</li>
              <p>
                For example, you might use your Twigyy login to play an online
                game with your Twigyy friends. The game developer automatically
                receives information about your activities in the game.
              </p>
              <li>Information they receive with your permission</li>
              <p>
                Sometimes these integrated partners ask you for permission to
                access certain additional information from your Twigyy account.
                In their request, they'll explain what information they'd like
                to access and let you choose whether to share it.
              </p>
              <p>
                On Twigyy, this includes things such as your email address, home
                town or birthday. On Twigyy, this includes content such as
                photos and videos that you've shared from your account when the
                account was set to private.
              </p>
              <li>Sharing friend lists</li>
              <ol className="list-disc ml-5">
                <li>When you share your friend list</li>
                <p>
                  If you use Twigyy Login to log in to an app, the app developer
                  might request access to your list of Twigyy friends. Here's
                  what happens if you give the app developer permission to view
                  these lists:
                </p>
                <ol className="list-disc ml-5">
                  <li>
                    They can view and access a list of your Twigyy friends who
                    use the same app and have given the app permission to access
                    their list of friends. They can't access non-public
                    information about your friends or followers through this
                    process. Note that the app developer will receive more
                    information about your friends if your friends choose to
                    share it themselves. They can share it by providing the
                    information directly or giving the developer permission to
                    access information from their account.
                  </li>
                  <li>
                    You'll appear on friends lists that your Twigyy friends can
                    choose to share with the same app. You can remove this
                    permission, or the app entirely, if you later decide that
                    you don't want to share your friends list with an app, or
                    don't want to appear on other friends lists shared with that
                    app.
                  </li>
                </ol>
                <li>When your friends share their friend list</li>
                <p>
                  Your friends might choose to share their friends lists with
                  app developers through Twigyy Login. But your friends can't
                  use Twigyy Login to share non-public information about you.
                </p>
              </ol>
            </ol>
            <p>
              We automatically log when you receive a request from an integrated
              partner to access your information. These requests to access
              information are separate from the apps and websites access that
              you manage in your Twigyy or ad settings or in your mobile device
              settings.
            </p>
            <li>How long they can access your information</li>
            <p>
              Apps or websites that you've logged in to using Twigyy Login or
              connected to your account can access your non-public information
              on Twiggy Products unless it appears to us that you haven't used
              the app or website in 90 days. Note that even if an app's access
              to your information has expired, it can still retain information
              that you shared with it previously.
            </p>
            <p>
              We encourage you to visit your apps and websites settings from
              time to time to review which apps and websites continue to have
              access to your information through Twigyy Login.
            </p>
            <li>
              When you interact with someone else's content on an integrated
              partner's product or service
            </li>
            <p>
              Integrated partners receive information about your activity when
              you interact with other Twigyy users while they're using the
              integrated partner's product or service.
            </p>
            <p>
              For example, a gamer livestreams to Twigyy using a partner app.
              Then you comment on that live-stream. The app developer will
              receive information about your comment.
            </p>
            <li>How integrated partners handle your information</li>
            <p>
              Integrated partners handle the information that you share with
              them according to their own terms and policies, not TWIGGY's. You
              can review their Privacy Policy on their website or app to learn
              how they receive and process your information. In some cases, they
              use a separate service provider to receive and process your
              information.
            </p>
          </ul>
          <p>How do we share information with third parties?</p>
          <ul className="list-disc ml-5">
            <li>We share certain information with:</li>
            <li>Advertisers who show ads on our Products</li>
            <li> Businesses we hire to market our Products for us</li>
            <li>
              Businesses we hire to do things such as offer customer service or
              conduct surveys
            </li>
            <li>
              Researchers who use it to do things such as innovate, advance
              technology or improve people's safety
            </li>
            <p>
              We don't sell any of your information to anyone and we never will.
              We also require partners and other third parties to follow rules
              about how they can and cannot use and disclose the information
              that we provide.
            </p>
          </ul>
          <p className="ml-5">
            Here's more detail about who we share information with:
          </p>
          <ul className="list-disc ml-5">
            <li>Partners</li>
            <p>Advertisers and Audience Network publishers</p>
            <ol className="liat-disc ml-5">
              <li>Advertisers</li>
              <p>
                We provide advertisers with reports about the number and kinds
                of people who see and engage with their ads and commercial
                content. These reports include information about the general
                demographics and interests of people who engaged with an
                advertiser's ad or commercial content. Then advertisers can
                better understand their audience.
              </p>
              <p>General denographics and interests::</p>
              <p>
                For example, a bike shop creates a Page on Twigyy and wants to
                place an ad to reach an audience of people in Atlanta interested
                in cycling. We determine whether someone fits in this audience
                based on, for example, whether they liked a Page about bikes.
                Then people in that audience could see the bike shop's ad. You
                can see the "interests" assigned to you and remove them if you
                want. The bike shop can then see reports showing aggregated
                statistics about the audience seeing their ads and how their ads
                are performing. The reports would show statistics to the
                advertiser that, for example, most of the people who saw or
                clicked on their ad:
              </p>
              <ul className="list-disc ml-5">
                <li> were woman</li>
                <li> were between 25 and 44 years old</li>
                <li>Clicked on the ad from their phone</li>
              </ul>
            </ol>
            <p>
              Twiggy also provides advertisers and their vendors with
              information about:
            </p>
            <ol className="list-disc ml-5">
              <li>Ads and commercial content people engaged with, if any</li>
              <li>When people engaged with ads and commercial content</li>
              <li>
                Where that ad or commercial content was shown (e.g. on Twigyy)
              </li>
            </ol>
            <p>
              We also confirm which ads or commercial content you viewed that
              led you to take an action with an advertiser, such as downloading
              an advertiser's app. But we don't share information with these
              advertisers and their vendors that by itself can be used to
              contact or identify you, such as your name or email address,
              unless you give us permission.
            </p>
            <li>Audience Network publishers and their vendors</li>
            <p>
              Twiggy Audience Network lets advertisers place ads with us that
              will be published on other apps and websites outside of TWIGGY.
            </p>
            <p>
              To help show you ads on their apps and websites, we share
              information with publishers who use Audience Network, as well as
              vendors who facilitate that use. For example, we share:
            </p>
            <ol className="list-disc ml-5">
              <li>How many people see and engage with ads on publisher apps</li>
              <li>
                Information related to or in response to a publisher's request
                to serve an ad on its app.
              </li>
            </ol>
            <p>
              But we don't share information with these publishers and their
              vendors that by itself can be used to contact or identify you,
              such as your name or email address, unless you give us permission.
            </p>
          </ul>
          <p>Partners who use our analytics services</p>
          <p className="ml-5">
            People rely on our products, such as business accounts to run and
            promote their businesses. Businesses use our analytics services to
            understand more about how people are using their content, features,
            products and services.
          </p>
          <p className="ml-5">
            To provide these services, we use the information that we collect
            about you. We put this information into aggregate reports so that
            partners can see how well their content, features, products and
            services are performing and are able to understand things such as
            users' experience with such content, products and services.{" "}
          </p>
          <p>These reports aggregate information such as:</p>
          <ol className="list-disc ml-5">
            <li>
              How many people interacted with our partners' content, products or
              services
            </li>
            <li>
              The general demographics and interests of the people who
              interacted with it
            </li>
            <li>
              How people use our partners' products and services to connect to
              Twiggy Products and the performance of their connection and
              networks
            </li>
          </ol>
          <p>
            Advertisers also receive other information. About how we share
            information with advertisers.
          </p>
          <p>
            Partners who offer goods or services on our Products and commerce
            services platforms
          </p>
          <p className="ml-5">
            When you choose to make a transaction, or otherwise choose to share
            information with a seller, creator, fundraiser, charity, payment
            services provider or commerce service platform, we share information
            with them and with any providers acting on their behalf.
          </p>
          <p className="ml-5">
            Depending on how you interact with them, they receive:
          </p>
          <ul className="list-disc ml-5">
            <li>
              Information to complete your transaction, such as order, payment,
              contact and delivery information
            </li>
            <li>
              Information to help ensure the security of the transaction, such
              as information about your device or connection
            </li>
            <li>Any information required by applicable regulation</li>
            <li>Other information that you choose to share with them</li>
            <p className="mt-5">
              For example, if you make a purchase from an shop using checkout,
              the shop will receive information to complete your transaction.
              This may include your order items, your contact details and
              delivery information. If the shop uses a payment services
              provider, such as PayPal, to facilitate the transaction, the
              provider will receive the transaction amount, a transaction
              description (to appear on your credit card statement) and your
              payment card information, such as cardholder name, card number,
              expiry date and billing address.
            </p>
          </ul>
          <ul className="list-disc ">
            <li>Vendors</li>
          </ul>
          <p className="ml-5">Measurement vendors</p>
          <p className="ml-5">
            A third party that creates reports to help advertisers understand
            how their content and ads are performing. These reports also measure
            whether people took an action after seeing an ad.
          </p>
          <p>
            We don't create every measurement and analytics report ourselves. We
            share information (such as whether people saw an ad or engaged with
            it) with our measurement vendors, who aggregate it to provide their
            reports.
          </p>
          <p>For example, an advertiser might ask a measurement vendor to help work out the impact that its ads on Twigyy have had on sales. The measurement vendor compares information from us about clicks on the advertiser's Twigyy ads with information from the advertiser about product purchases. Then the measurement vendor creates and provides aggregated reports that show the advertiser how its ads are performing.</p>
          <p className="ml-5">Marketing vendors</p>
          <p>A third party that helps market or advertise Twiggy and our Products, measures the effectiveness of our own marketing campaigns and performs advertising research.</p>
          <p>We share information about you with marketing vendors. For example, we share your device identifier or other identifiers with marketing vendors to help us serve you ads most relevant to your interests.</p>
          <p>Marketing vendors support our marketing and advertising efforts by:</p>
          <ul className="list-disc ml-5">
            <li>Serving our advertisements across the internet, including on mobile, desktop and connected television devices</li>
            <li>Tracking and categorising your online and mobile app activity</li>
            <li>Providing us information about your interests and community and advertising interactions</li>
            <p>These marketing vendors help us understand who might find our advertising most relevant to their interests, and which of our Products might interest you. This information can be used to personalise which of our ads are shown to you. Marketing vendors also use this information to measure response to our marketing efforts and the effectiveness of our advertising.</p>
            <p>Learn about how to manage the Twiggy ads that you see off our Products:</p>
            <p>Manage Twiggy ads that you see off our Products</p>
            <p>We advertise our own products and services on non-Twiggy ad platforms. We use marketing vendors to deliver these ads. Here's how to opt out of our tailored ads that you see off our Products:</p>
            <ol>
              <li>Cookie-based opt out: </li>
              <p>If applicable and available in your jurisdiction, you may use the Digital Advertising Alliance  industry opt-out platform, available in English and Spanish. Residents of Canada may use the Digital Advertising Alliance Canada industry opt-out platform. If you apply certain ad blockers and tools to restrict cookies, it may interfere with your ability to opt out.</p>
              <li>MObile phone-based opt-out:</li>
              <p>Check your mobile device settings. Different manufacturers provide different ways to opt out of advertisements.</p>
              <li>Other online identifiers:</li>
              <p>You may use the NAI industry opt-out platform.</p>
            </ol>
          </ul>
          <ul className="list-disc ml-5">
            <li>Service providers</li>
            <p>Service providers provide services to us that help us provide our Products to you. We share the information we have about you to receive these services, which include:</p>
            <ol className="list-disc ml-5" >
              <li>Investigating suspicious activity</li>
              <li>Detecting and stopping threats to our personnel and property</li>
              <li>Facilitating payments</li>
              <li>Providing customer support</li>
              <li>Improving the functionality of our products</li>
              <li>Providing technical infrastructure services</li>
              <li>Analysing how our products are used </li>
              <li>Conducting research and surveys</li>
              <li>Marketing and promoting our products</li>
            </ol>
          </ul>
          <ul className="list-disc ml-5">
            <li>Third parties</li>
            <p>We provide information to external researchers. They use it to conduct research that advances scholarship and innovation, and to promote safety, security and integrity. Research goals include supporting:</p>
            <ol className="list-disc ml-5">
              <li>Our business or mission</li>
              <li>Social good</li>
              <li>Technological advancement</li>
              <li>Safety and security on our products</li>
              <li>Public interest</li>
              <li>Health and well-being</li>
            </ol>
            <p>When sharing information with external researchers, we ensure that the privacy of our users is protected. </p>
            <li>Other third parities</li>
            <p>We also share information with other third parties in response to legal requests, to comply with applicable law or to prevent harm.</p>
            <p>And if we sell or transfer all or part of our business to someone else, in some cases we'll give the new owner your information as part of that transaction, but only as the law allows.</p>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Privacy_1;

import React from 'react'

const Cookies = () => {
  return (
    <>
    <div className='mb-10 mt-10 flex flex-row justify-center gap-24 '>
      <div className='flex flex-col w-[80%] '>

      <h1 className='font-bold text-2xl mt-5'>Cookies</h1>
      <h1 className='text-m font-semibold mt-5'>What are cookies and what does this policy cover?</h1>
      <p className='text-m font-medium'>Cookies are small pieces of text used to store information in web browsers. Cookies are used to store and receive identifiers and other information on computers, phones and other devices. Other technologies, including data that we store on your web browser or device, identifiers associated with your device and other software, are used for similar purposes. In this policy, we refer to all of these technologies as "cookies".</p>
      <p>We use cookies if you have a Twigyy  account, use the Twiggy Products, including our website and apps, or visit other websites and apps that use the Twiggy Products (including the Like button). Cookies enable Twiggy to offer the Twiggy Products to you and to understand the information that we receive about you, including information about your use of other websites and apps, whether or not you are registered or logged in.</p>
      <p>This policy explains how we use cookies and the choices you have. Except as otherwise stated in this policy, the Privacy Policy will apply to our processing of the data that we collect via cookies.</p>
      <h1 className='text-m font-semibold'>Why do we use cookies?</h1>
      <p>Cookies help us provide, protect and improve the Twiggy Products, such as by personalising content, tailoring and measuring ads, and providing a safer experience. The cookies that we use include session cookies, which are deleted when you close your browser, and persistent cookies, which stay in your browser until they expire or you delete them. The cookies that we use and how we use them may change over time as we improve and update Twiggy Products, we typically use them for the following purposes:</p>
      <h1 className='text-m font-semibold'>Authentication</h1>
      <p>We use cookies to verify your account and determine when you're logged in so that we can make it easier for you to access the Twiggy Products and show you the appropriate experience and features.</p>
      <ul className="list-disc ml-10">
        <li>For example: We use cookies to keep you logged in as you navigate between Twigyy  Pages. Cookies also help us remember your browser so you don't have to keep logging in to Twigyy  and so you can more easily log in to Twigyy  via third-party apps and websites. For example, we use the "c_user" and "xs" cookies, including for this purpose, which have a lifespan of 365 days.</li>
      </ul>
      <h1 className='text-m font-semibold'>Security, site and product integrity</h1>
      <p>We use cookies to help us keep your account, data and the Twiggy Products safe and secure.</p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies can help us identify and impose additional security measures when someone may be attempting to access a Twigyy  account without authorisation, for instance, by rapidly guessing different passwords. We also use cookies to store information that allows us to recover your account in the event that you forget your password, or to require additional authentication if you tell us that your account has been hacked. This includes, for example, our "sb" and "dbln" cookies, which enable us to identify your browser securely, as well as "datr". "Datr" is a unique identifier for your browser that, amongst other things, helps us protect you from fraud. For example, it helps us identify trusted browsers where you have logged in before. "Datr" has a lifespan of 400 days.</li>
      </ul>
      <p>We also use cookies to combat activity that violates our policies or otherwise degrades our ability to provide the Twiggy Products.</p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies help us fight spam and phishing attacks by enabling us to identify computers that are used to create large numbers of fake Twigyy  accounts. We also use cookies to detect computers infected with malware and to take steps to prevent them from causing further harm. Our "csrf" cookie, for example, helps us prevent cross-site request forgery attacks. The "datr" cookie also helps us to identify the browsers used by malicious actors and to prevent cyber-security attacks, such as a denial of service attack that could prevent you from accessing the Twiggy Products. Cookies also help us prevent underage people from registering for Twigyy  accounts.</li>
      </ul>
      <h1 className='text-m font-semibold'>Advertising, recommendations, insights and measurement</h1>
      <p>We use cookies to help us show ads and to make recommendations for businesses and other organisations to people who may be interested in the products, services or causes they promote.</p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies allow us to help deliver ads to people who have previously visited a business's website, purchased its products or used its apps and to recommend products and services based on that activity. Cookies also allow us to limit the number of times that you see an ad so you don't see the same ad over and over again. For example, the "fr" cookie is used to deliver, measure and improve the relevancy of ads, with a lifespan of 90 days.</li>
      </ul>
      <p>We also use cookies to help measure the performance of ad campaigns for businesses that use the Twiggy Products.</p>
      <ul className="list-disc ml-10">
        <li>For example: We use cookies to count the number of times that an ad is shown and to calculate the cost of those ads. We also use cookies to measure how often people do things, such as make a purchase following an ad impression. For example, the "_fbp" cookie identifies browsers for the purposes of providing advertising and site analytics services and has a lifespan of 90 days.</li>
      </ul>
      <p>Cookies help us serve and measure ads across different browsers and devices used by the same person.</p>
      <ul className="list-disc ml-10">
        <li>For example: We can use cookies to prevent you from seeing the same ad over and over again across the different devices that you use.
Cookies also allow us to provide insights about the people who use the Twiggy Products, as well as the people who interact with the ads, websites and apps of our advertisers and the businesses that use the Twiggy Products.</li>
<li>For example: We use cookies to help businesses understand the kinds of people who like their Twigyy  Page or use their apps so that they can provide more relevant content and develop features that are likely to be interesting to their customers.</li>
      </ul>
    <p>We also use cookies, such as our "oo" cookie, which has a lifespan of 400 days, to help you opt out of seeing ads from Twiggy based on your activity on third-party websites. </p>
      <h1 className='text-m font-semibold'>Site features and services</h1>
      <p>We use cookies to enable the functionality that helps us provide the Twiggy Products.</p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies help us store preferences, know when you've seen or interacted with Twiggy Products' content and provide you with customised content and experiences. For instance, cookies allow us to make suggestions to you and others, and to customise content on third-party sites that integrate our social plugins. If you are a Twigyy  Page administrator, cookies allow you to switch between posting from your personal Twigyy  account and the Twigyy  Page. We use cookies such as the session-based "presence" cookie to support your use of Messenger chat windows.</li>
      </ul>
      <p>We also use cookies to help provide you with content relevant to your locale.</p>
      <ul className="list-disc ml-10">
        <li>For example: We store information in a cookie that is placed on your browser or device so that you will see the site in your preferred language.</li>
      </ul>
      <h1 className='text-m font-semibold'>Performance</h1>
      <p>We use cookies to provide you with the best experience possible.</p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies help us route traffic between servers and understand how quickly Twiggy Products load for different people. Cookies also help us record the ratio and dimensions of your screen and windows and know whether you've enabled high-contrast mode, so that we can render our sites and apps correctly. For example, we set the "dpr" and "wd" cookies, each with a lifespan of seven days, for purposes including to deliver an optimal experience for your device's screen.</li>
      </ul>
      <h1 className='text-m font-semibold'>Analytics and research</h1>
      <p>We use cookies to better understand how people use the Twiggy Products so that we can improve them.
      </p>
      <ul className="list-disc ml-10">
        <li>For example: Cookies can help us understand how people use the Twigyy  service, analyse which parts of our Products people find most useful and engaging, and identify features that could be improved.</li>
      </ul>
      <h1 className='text-m font-semibold'>Third-party websites and apps</h1>
      <p>Our business partners may also choose to share information with Twiggy from cookies set in their own websites' domains, whether or not you have a Twigyy  or are logged in. Specifically, cookies named _fbc or _fbp may be set on the domain of the business partner whose site you're visiting. Unlike cookies that are set on TWIGGY's own domains, these cookies aren't accessible by Twiggy when you're on a site other than the one on which they were set, including when you are on one of our domains. They serve the same purposes as cookies set in TWIGGY's own domain, which are to personalise content (including ads), measure ads, produce analytics and provide a safer experience, as set out in this Cookies Policy.</p>
      <h1 className='text-m font-semibold'>Why do we use cookies?</h1>
      <p>We may place cookies on your computer or device and receive information stored in cookies when you use or visit:</p>
      <ul className="list-disc ml-10">
        <li>The Twiggy Products</li>
        <li>Products provided by other members of the Twiggy Companies</li>
        <li>Websites and apps provided by other companies that use the Twiggy Products, including companies that incorporate Twiggy technologies into their websites and apps. Twiggy uses cookies and receives information when you visit those sites and apps, including device information and information about your activity, without any further action from you. This occurs whether or not you have a Twigyy or are logged in.</li>
      </ul>
      <h1 className='text-m font-semibold'>Do other Companies use cookies in connection with the Twiggy Products?</h1>
      <p>Yes, other companies use cookies on the Twiggy Products to provide advertising, measurement, marketing and analytics services to us, and to provide certain features and improve our services for you.</p>
      <p>For example, other companies' cookies help tailor ads off Twiggy Products, measure their performance and effectiveness and support marketing and analytics. Certain features on the Twiggy Products use cookies from other companies to function, for example, certain maps, payment and security features.Third-party companies also use cookies on their own sites and apps in connection with the Twiggy Products. To understand how other companies use cookies, please review their policies.</p>
      <h1 className='text-m font-semibold'>Browser cookie controls</h1>
      <p>In addition, your browser or device may offer settings that allow you to choose whether browser cookies are set and to delete them. These controls vary by browser, and manufacturers may change both the settings that they make available and how they work at any time. As of 23 June 2021, you may find additional information about the controls offered by popular browsers at the links below. Certain parts of the TWIGGY Products may not work properly if you have disabled browser cookie use. Please be aware that these controls are distinct from the controls that we offer you.</p>
      <ul className="list-disc ml-10">
        <li>Google Chrome</li>
        <li>Internet explorer</li>
        <li>Firefox</li>
        <li>Safari</li>
        <li>Safari mobile</li>
        <li>Opera</li>
      </ul>
      </div>

    </div>
    
    </>
  )
}

export default Cookies;
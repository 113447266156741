import React from "react";
import { Dropdown } from "flowbite-react";
import "../Styles/Privacy.css";
import Pdf from "../Pdf/_Privacy Policy.pdf";
const Privacy_Policy = () => {
  return (
    <>
      <div>
        <h1 className="text-4xl font-bold mt-5">Privacy Policy</h1>
        <p className="text-xl mt-5">
          Privacy policy of our app is a statement that outlines
          how the app collects, uses, and protects users' personal information.
          It explains what data is gathered such as profile details, posts, and
          interactions and how this information may be used to enhance user
          experience or improve services. The policy also describes users’
          rights regarding their data, including options to update, manage, or
          delete their information. Ultimately, the privacy policy serves as a
          transparent guide to help users understand their data's security and
          usage within the app.
        </p>
      </div>
      <a href={Pdf} target="_blank" className="text-blue-500">
        Learn More
      </a>

    
    </>
    // <>
    // <div className="text-[24px] font-medium">Privacy Policy</div>
    //   <div className="text-[20px] ">
    //     We updated our Privacy Policy on [Date].{" "}
    //   </div>
    //   <div>
    //     <div>
    //       <Dropdown
    //         className="flex block bg-transparent w-[50%]"
    //         label={
    //           <p className="text-[20px] font-medium">
    //             What is the Privacy Policy and what does it cover?
    //           </p>
    //         }
    //         inline
    //       >
    //         <Dropdown.Item className=" text-[15px] bg-transparent w-[100%] p-2 text-left ">
    //           122421354344We at Meta want you to understand what information we
    //           collect, and how we use and share it. That's why we encourage you
    //           to read our Privacy Policy. This helps you use in the way that's
    //           right for you. In the Privacy Policy, we explain how we collect,
    //           use, share, retain and transfer information. We also let you know
    //           your rights. Each section of the Policy includes helpful examples
    //           and simpler language to make our practices easier to understand.
    //           We've also added links to resources where you can learn more about
    //           the privacy topics that interest you. It's important to us that
    //           you know how to control your privacy, so we also show you where
    //           you can manage your information in the settings of the Meta
    //           Products you use. You can to shape your experience.
    //         </Dropdown.Item>
    //       </Dropdown>
    //     </div>
    //     <div>
    //       <Dropdown
    //         className="flex block bg-transparent w-[50%]"
    //         label={
    //           <p className="text-[20px] font-medium">
    //             What information do we collect?
    //           </p>
    //         }
    //         inline
    //       >
    //         <Dropdown.Item className=" text-[15px] bg-transparent w-[100%]  text-left ">
    //           We at Meta want you to understand what information we collect, and
    //           how we use and share it. That's why we encourage you to read our
    //           Privacy Policy. This helps you use in the way that's right for
    //           you. In the Privacy Policy, we explain how we collect, use, share,
    //           retain and transfer information. We also let you know your rights.
    //           Each section of the Policy includes helpful examples and simpler
    //           language to make our practices easier to understand. We've also
    //           added links to resources where you can learn more about the
    //           privacy topics that interest you. It's important to us that you
    //           know how to control your privacy, so we also show you where you
    //           can manage your information in the settings of the Meta Products
    //           you use. You can to shape your experience.
    //         </Dropdown.Item>
    //       </Dropdown>
    //     </div>
    //   </div>
    // </>
  );
};

export default Privacy_Policy;

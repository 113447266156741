import React from "react";
import Terms from "../Styles/Terms.css";

const Termsofservices = () => {
  return (
    <>
      <div className="flex flex-row justify-center gap-24 mt-[30px]">
        <div className="flex flex-col w-[80%] h-[4200vh]">
          <h1 className="text-4xl font-bold mt-5">Terms of Services</h1>
          <p>
            Twiggy builds technologies and services that enable people to
            connect with each other, build communities and grow businesses.
            These Terms govern your use of Twigyy and the other products,
            features, apps, services, technologies and software that we offer
            (the Twiggy Products or Products), except where we expressly state
            that separate terms (and not these) apply. These Products are
            provided to you by Twiggy e-commerce LLP.
          </p>
          <p>
            We don't charge you to use Twigyy or the other products and services
            covered by these Terms, unless we state otherwise. Instead,
            businesses, organisations and other persons pay us to show you ads
            for their products and services. By using our Products, you agree
            that we can show you ads that we think may be relevant to you and
            your interests. We use your personal data to help determine which
            personalised ads to show you.
          </p>
          <p>
            We don't sell your personal data to advertisers, and we don't share
            information that directly identifies you (such as your name, email
            address or other contact information) with advertisers unless you
            give us specific permission. Instead, advertisers can tell us things
            such as the kind of audience that they want to see their ads, and we
            show those ads to people who may be interested. We provide
            advertisers with reports about the performance of their ads that
            help them understand how people are interacting with their content.
            See Section 2 below to learn more about how personalised advertising
            under these Terms works on the Twiggy Products.
          </p>
          <p>
            Our Privacy Policy explains how we collect and use your personal
            data to determine some of the ads that you see and provide all of
            the other services described below. You can also go to your settings
            pages of the relevant Twiggy Product at any time to review the
            privacy choices that you have about how we use your data.
          </p>
          <div>
            <h1 className="text-2xl font-bold mt-5">1. Services we provide</h1>
            <p>
              Our mission is to give people the power to build community and
              bring the world closer together. To help advance this mission, we
              provide the products and services described below to you:
            </p>
            <h1 className="text-m font-bold ">
              Provide a personalised experience for you:
            </h1>
            <p>
              Your experience on Twigyy is unlike anyone else's: from the
              twigs,tales,takes, ads and other content that you see in Twigyy to
              the Pages that you follow and other features you might use, such
              as Twigyy search. For example, we use data about the connections
              you make, the choices and settings you select, and what you share
              and do on and off our Products to personalise your experience.
            </p>
            <h1 className="text-m font-bold ">
              Connect you with people and organisations that you care about::
            </h1>
            <p>
              We help you find and connect with people, groups, businesses,
              organisations and others that matter to you across the Twiggy
              Products you use. We use data to make suggestions for you and
              others – for example, groups to join, events to attend, Twigyy
              Pages to follow or send a message to, takes to watch and people
              you may want to become friends with. Stronger ties make for better
              communities, and we believe that our services are most useful when
              people are connected to people, groups and organisations that they
              care about.
            </p>
            <h1 className="text-m font-bold ">
              Empower you to express yourself and communicate about what matters
              to you:
            </h1>
            <p>
              There are many ways to express yourself on Twigyy to communicate
              with friends, family and others about what matters to you – for
              example, sharing status updates, photos, videos and stories across
              the Twiggy Products (consistent with your settings), sending
              messages or making voice or video calls to a friend or several
              people, creating events or groups, or adding content to your
              profile, as well as showing you insights on how others engage with
              your content. We have also developed, and continue to explore, new
              ways for people to use technology.
            </p>
            <h1 className="text-m font-bold ">
              Help you discover content, products and services that may interest
              you:
            </h1>
            <p>
              We show you personalised ads, offers and other sponsored or
              commercial content to help you discover content, products and
              services that are offered by the many businesses and organisations
              that use Twigyy and other Twiggy Products. Section 2 below
              explains this in more detail.
            </p>
            <h1 className="text-m font-bold ">
              Promote the safety, security and integrity of our services, combat
              harmful conduct and keep our community of users safe:
            </h1>
            <p>
              People will only build community on Twiggy Products if they feel
              safe and secure. We work hard to maintain the security (including
              the availability, authenticity, integrity and confidentiality) of
              our Products and services. We employ dedicated teams, work with
              external service providers, partners and other relevant entities
              and develop advanced technical systems to detect potential misuse
              of our Products, harmful conduct towards others and situations
              where we may be able to help support or protect our community,
              including to respond to user reports of potentially violating
              content. If we learn of content or conduct such as this, we may
              take appropriate action based on our assessment that may include
              notifying you, offering help, removing content, removing or
              restricting access to certain features, disabling an account or
              contacting law enforcement. We share data across Twiggy Companies
              when we detect misuse or harmful conduct by someone using one of
              our Products or to help keep Twiggy Products, users and the
              community safe. For example, we share information with Twiggy
              Companies that provide financial products and services to help
              them promote safety, security and integrity and comply with
              applicable law. Twiggy may access, preserve, use and share any
              information it collects about you where it has a good faith belief
              that it is required or permitted by law to do so. For more
              information, please review our Privacy Policy.
            </p>
            <h1 className="text-m font-bold ">
              Use and develop advanced technologies to provide safe and
              functional services for everyone:
            </h1>
            <p>
              We build sophisticated network and communication technology to
              help more people connect to the Internet in areas with limited
              access. And we develop automated systems to improve our ability to
              detect and remove abusive and dangerous activity that may harm our
              community and the integrity of our Products.
            </p>
            <h1 className="text-m font-bold ">
              Research ways to make our services better:{" "}
            </h1>
            <p>
              We engage in research to develop, test and improve our Products.
              This includes analysing data that we have about our users, and
              understanding how people use our Products, for example by
              conducting surveys and testing and troubleshooting new features.
              Our Privacy Policy explains how we use data to support this
              research for the purposes of developing and improving our
              services.
            </p>
            <h1 className="text-m font-bold ">
              Provide consistent and seamless experiences across the Twiggy
              Company Products:{" "}
            </h1>
            <p>
              Our Products help you find and connect with people, groups,
              businesses, organisations and others that are important to you. We
              design our systems so that your experience is consistent and
              seamless across the different Twiggy Company Products that you
              use. For example, we use data about the people you engage with on
              Twigyy to make it easier for you to connect with them and we
              enable you to communicate with businesses that you follow on
              Twigyy.
            </p>
            <h1 className="text-m font-bold ">
              Ensuring access to our services:
            </h1>
            <p>
              To operate our global services and enable you to connect with
              people around the world, we need to transfer, store and distribute
              content and data to our data centres, partners, service providers,
              vendors and systems. The use of this global infrastructure is
              necessary and essential to provide our services. This
              infrastructure may be owned, operated or controlled by Twiggy
              e-commerce LLP.
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-bold mt-5">
              2. How our services are funded
            </h1>
            <p>
              Instead of paying to use Twigyy and the other products and
              services we offer, by using the Twiggy Products covered by these
              Terms, you agree that we can show you personalised ads and other
              commercial and sponsored content that businesses and organisations
              pay us to promote on and off Twiggy Company Products. We use your
              personal data, such as information about your activity and
              interests, to show you personalised ads and sponsored content that
              may be more relevant to you. Protecting people's privacy is
              central to how we've designed our personalised ads system. This
              means that we can show you relevant and useful ads without telling
              advertisers who you are. We don't sell your personal data. We
              allow advertisers to tell us things such as their business goal,
              and the kind of audience that they want to see their ads (for
              example, people between the ages of 18-35 who like cycling). We
              then show their ad to people who we think might be interested. We
              also provide advertisers with reports about the performance of
              their ads to help them understand how people are interacting with
              their content on and off Twiggy Products. For example, we provide
              general demographic and interest information to advertisers to
              help them better understand their audience, such as the fact that
              women between the ages of 25 and 34 who live in Bengalore,India
              and like software engineering have seen an ad. We don't share
              information that directly identifies you (information such as your
              name or email address that by itself can be used to contact you or
              identifies who you are) unless you give us specific permission.
              Learn more about how Twiggy ads work here. We collect and use your
              personal data in order to provide the services described above for
              you. You can learn about how we collect and use your data in our
              Privacy Policy. You have controls over the types of ads and
              advertisers you see, and the types of information we use to
              determine which ads we show you. Learn more.
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-bold mt-5">
              3. Your commitments to Twigyy and our community
            </h1>
            <p>
              We provide these services to you and others to help advance our
              mission. In exchange, we need you to make the following
              commitments:
            </p>
            <h1 className="text-m font-bold mt-5">Who can use twigyy</h1>
            <p>
              When people stand behind their opinions and actions, our community
              is safer and more accountable. For this reason, you must:
            </p>
            <ul className="list-disc ml-10">
              <li>
                Provide for your account the same name that you use in everyday
                life.
              </li>
              <li>Provide accurate information about yourself;</li>
              <li>
                Only create one account (your own) and use it for personal
                purposes.
              </li>
              <li>
                Not share your password, give access to your Twigyy account to
                others or transfer your account to anyone else (without our
                permission).
              </li>
            </ul>
            <p>
              We try to make Twigyy broadly available to everyone, but you
              cannot use Twigyy if:
            </p>
            <ul className="list-disc ml-10">
              <li>You are under 13 years old. </li>
              <li>You are a convicted sex offender.</li>
              <li>
                We've previously disabled your account for violations of our
                Terms, the Community Standards or other terms and policies that
                apply to your use of Twigyy. If we disable your account for a
                violation of our Terms, the Community Standards or other terms
                and policies, you agree not to create another account without
                our permission. Receiving permission to create a new account is
                provided at our sole discretion, and does not mean or imply that
                the disciplinary action was wrong or without cause.
              </li>
              <li>
                You are prohibited from receiving our products, services or
                software under applicable laws.
              </li>
            </ul>
            <h1 className="text-m font-bold mt-5">
              What you can share and do on Twiggy Products
            </h1>
            <p>
              We want people to use Twiggy Products to express themselves and to
              share content that is important to them, but not at the expense of
              the safety and well-being of others or the integrity of our
              community. You therefore agree not to engage in the conduct
              described below (or to facilitate or support others in doing so):
            </p>
            <ul className="list-decimal ml-5 mt-5">
              <li>You may not use our Products to do or share anything:</li>
              <ol className="list-disc ml-8">
                <li>
                  That violates these Terms, the Community Standards, or other
                  terms and policies that apply to your use of our Products.
                </li>
                <li>
                  That is unlawful, misleading, discriminatory or fraudulent (or
                  assists someone else in using our Products in such a way).
                </li>
                <li>
                  That you do not own or have the necessary rights to share.
                </li>
                <li>
                  That infringes or violates someone else's rights, including
                  their intellectual property rights (such as by infringing
                  another's copyright or trademark, or distributing or selling
                  counterfeit or pirated goods), unless an exception or
                  limitation applies under applicable law.
                </li>
              </ol>
              <li>
                You may not upload viruses or malicious code, use the services
                to send spam or do anything else that could disable, overburden,
                interfere with or impair the proper working, integrity,
                operation or appearance of our services, systems or Products.
              </li>
              <li>
                You may not access or collect data from our Products using
                automated means (without our prior permission) or attempt to
                access data that you do not have permission to access.
              </li>
              <li>
                You may not proxy, request or collect Product usernames or
                passwords, or misappropriate access tokens.
              </li>
              <li>
                You may not sell, license or purchase any data obtained from us
                or our services, except as provided in the Platform Terms.
              </li>
              <li>
                You may not misuse any reporting, flagging, dispute or appeals
                channel, such as by making fraudulent, duplicative or groundless
                reports or appeals.
              </li>
            </ul>
            <p className="mt-5">
              We can remove or restrict access to content that is in violation
              of these provisions. We can also suspend or disable your account
              for conduct that violates these provisions, as provided in Section
              4.B.If we remove content that you have shared in violation of the
              Community Standards, we'll let you know and explain any options
              you have to request another review, unless you seriously or
              repeatedly violate these Terms or if doing so may expose us or
              others to legal liability; harm our community of users; compromise
              or interfere with the integrity or operation of any of our
              services, systems or Products; where we are restricted due to
              technical limitations; or where we are prohibited from doing so
              for legal reasons. For information on account suspension or
              termination, see Section 4.B below.
            </p>
            <p>
              To help support our community, we encourage you to report content
              or conduct that you believe violates your rights (including
              intellectual property rights) or our Terms and Policies, if this
              feature exists in your jurisdiction. We also can remove or
              restrict access to content features, services or information if we
              determine that doing so is reasonably necessary to avoid or
              mitigate misuse of our services or adverse legal or regulatory
              impacts to Twiggy.
            </p>
            <h1 className="text-m font-bold mt-5">
              What permission you give us
            </h1>
            <p>We need certain permissions from you to provide our services:</p>
            <ul className="list-decimal ml-5">
              <li>
                Permission to use content that you create and share: Some
                content that you share or upload, such as photos or videos, may
                be protected by intellectual property laws. You retain ownership
                of the intellectual property rights (things such as copyright or
                trademarks) in any such content that you create and share on
                Twigyy and other Twiggy Company Products that you use. Nothing
                in these Terms takes away the rights you have to your own
                content. You are free to share your content with anyone else,
                wherever you want. However, to provide our services, we need you
                to give us some legal permissions (known as a "Licence") to use
                this content. This is solely for the purposes of providing and
                improving our Products and services as described in Section 1
                above. Specifically, when you share, post or upload content that
                is covered by intellectual property rights on or in connection
                with our Products, you grant us a non-exclusive, transferable,
                sub-licensable, royalty-free and worldwide licence to host, use,
                distribute, modify, run, copy, publicly perform or display,
                translate and create derivative works of your content
                (consistent with your privacy and application settings). This
                means, for example, that if you share a photo on Twigyy, you
                give us permission to store, copy and share it with others
                (again, consistent with your settings) such as Twiggy Products
                or service providers that support those products and services.
                This licence will end when your content is deleted from our
                systems. You can delete individual content that you share, post
                and upload at any time. In addition, all content posted to your
                personal account will be deleted if you delete your account.
                Learn more about how to delete your account. Account deletion
                does not automatically delete content that you post as an admin
                of a Page or content that you create collectively with other
                users, such as photos in Shared Albums that may continue to be
                visible to other album members. It may take up to 90 days to
                delete content after we've begun the account deletion process or
                receives a content deletion request. If you send content to the
                bin, the deletion process will automatically begin in 30 days
                unless you choose to delete the content sooner. While the
                deletion process for such content is being undertaken, the
                content is no longer visible to other users. After the content
                has been deleted, it may take us up to another 90 days to remove
                it from backups and disaster recovery systems. Content will not
                be deleted within 90 days of the account deletion or content
                deletion process beginning in the following situations:
              </li>
              <ol className="list-disc ml-10">
                <li>
                  where your content has been used by others in accordance with
                  this licence and they have not deleted it (in which case this
                  licence will continue to apply until that content has been
                  deleted);
                </li>
                <li>
                  where deletion within 90 days is not possible due to technical
                  limitations of our systems, in which case, we will complete
                  the deletion as soon as technically feasible; or
                </li>
                <li>Where immediate deletion would restrict our ability to:</li>
                <ol className="list-disc ml-14">
                  <li>
                    Investigate or identify illegal activity or breaches of our
                    Terms and Policies (for example, to identify or investigate
                    misuse of our Products or systems);
                  </li>
                  <li>
                    protect the safety, integrity and security of our Products,
                    systems, services, our employees and users, and to defend
                    ourselves;
                  </li>
                  <li>
                    comply with legal obligations for the preservation of
                    evidence, including data that Twiggy Companies providing
                    financial products and services preserve to comply with any
                    record-keeping obligations required by law; or{" "}
                  </li>
                  <li>
                    comply with a request of a judicial or administrative
                    authority, law enforcement or a government agency;
                  </li>
                </ol>
              </ol>
              <li>
                in which case, the content will be retained for no longer than
                is necessary for the purposes for which it has been retained
                (the exact duration will vary on a case-by-case basis). In each
                of the above cases, this licence will continue until the content
                has been fully deleted.Permission to use your name, profile
                picture and information about your actions with ads and
                sponsored or commercial content: You give us permission to use
                your name and profile picture and information about actions that
                you have taken on Twigyy next to or in connection with ads,
                offers and other sponsored or commercial content that we display
                across our Products, without any compensation to you. For
                example, we may show your friends that you are interested in an
                advertised event or have liked a Twigyy Page created by a brand
                that has paid us to display its ads on Twigyy. Ads and content
                such as this can only be seen by people who have your permission
                to see the actions that you've taken on Twiggy Products. You can
                learn more about your ad settings and preferences.
              </li>
              <li>
                Permission to update software that you use or download: If you
                download or use our software, you give us permission to download
                and install updates to the software where available.
              </li>
            </ul>
            <h1 className="text-m font-bold mt-5">
              Limits on using our intellectual property
            </h1>
            <p>
              If you use content covered by intellectual property rights that we
              have and make available in our Products (for example, images,
              designs, videos or sounds that we provide, which you add to
              content that you create or share on Twigyy), we retain all rights
              to that content (but not yours). You can only use our copyrights
              or trademarks (or any similar marks) as expressly permitted by our
              Brand Usage Guidelines or with our prior written permission. You
              must obtain our written permission (or permission under an
              open-source licence) to modify, translate, create derivative works
              of, decompile or reverse-engineer our products or their
              components, or otherwise attempt to extract source code from us,
              unless an exception or limitation applies under applicable law or
              your conduct relates to the Twiggy Bug Bounty Programme.
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-bold mt-5">
              4. Additional provisions
            </h1>
            <h1 className="text-m font-bold mt-5">Updating our terms</h1>
            <p>
              We work constantly to improve our services and develop new
              features to make our Products better for you and our community. As
              a result, we may need to update these Terms from time to time to
              accurately reflect our services and practices, to promote a safe
              and secure experience on our Products and services, and/or to
              comply with applicable law. We will only make any changes if the
              provisions are no longer appropriate or if they are incomplete,
              and only if the changes are reasonable and take due account of
              your interests, or if the changes are required for safety and
              security purposes or to comply with applicable law. We will notify
              you (for example, by email or through our Products) at least 30
              days before we make changes to these Terms and give you an
              opportunity to review them before they go into effect, unless
              changes are required by law. Once any updated Terms are in effect,
              you will be bound by them if you continue to use our Products. We
              hope that you will continue using our Products, but if you do not
              agree to our updated Terms and no longer want to be a part of the
              Twiggy community, you can delete your account at any time.
            </p>
            <h1 className="text-m font-bold mt-5">
              Account suspension or termination
            </h1>
            <p>
              We want Twigyy to be a place where people feel welcome and safe to
              express themselves and share their thoughts and ideas. If we
              determine, in our discretion, that you have clearly, seriously or
              repeatedly breached our Terms or Policies, including, in
              particular, the Community Standards, we may suspend or permanently
              disable your access to Twiggy Company Products, and we may
              permanently disable or delete your account. We may also disable or
              delete your account if you repeatedly infringe other people's
              intellectual property rights or where we are required to do so for
              legal reasons. We may disable or delete your account if, after
              registration, your account is not confirmed, your account is
              unused and remains inactive for an extended period of time or if
              we detect that someone may have used it without your permission
              and we are unable to confirm your ownership of the account. Learn
              more about how we disable and delete accounts. Where we take such
              action, we'll let you know and explain any options you have to
              request a review, unless doing so may expose us or others to legal
              liability; harm our community of users; compromise or interfere
              with the integrity or operation of any of our services, systems or
              Products; where we are restricted due to technical limitations; or
              where we are prohibited from doing so for legal reasons. You can
              learn more about what you can do if your account has been disabled
              and how to contact us if you think that we have disabled your
              account by mistake. If you delete or we disable or delete your
              account, these Terms shall terminate as an agreement between you
              and us, but the following provisions will remain in place: 3,
              4.2-4.5.
            </p>
            <h1 className="text-m font-bold mt-5">Limits on liability</h1>
            <p>
              We work hard to provide the best Products we can and to specify
              clear guidelines for everyone who uses them. Our Products,
              however, are provided "As is," and to the extent permissible by
              law, we make no guarantees that they always will be safe, secure,
              or error-free, or that they will function without disruptions,
              delays, or imperfections. To the extent permitted by law, we also
              disclaim all warranties, whether express or implied, including the
              implied warranties of merchantability, fitness for a particular
              purpose, title and non-infringement. We do not control or direct
              what people and others do or say, and we are not responsible for
              their actions or conduct (whether online or offline) or any
              content that they share (including offensive, inappropriate,
              obscene, unlawful and other objectionable content). We cannot
              predict when issues may arise with our Products. Accordingly, our
              liability shall be limited to the fullest extent permitted by
              applicable law. To the fullest extent permitted by applicable law,
              under no circumstance will we be liable to you for any lost
              profits, revenues, information, or data, or consequential,
              special, indirect, exemplary, punitive or incidental damages
              arising out of or related to these Terms or the Twiggy Products
              (however caused and on any theory of liability, including
              negligence), even if we have been advised of the possibility of
              such damages.
            </p>
            <h1 className="text-m font-bold mt-5">Disputes</h1>
            <p>
              We try to provide clear rules so that we can limit or hopefully
              avoid disputes between you and us. If a dispute does arise,
              however, it's useful to know upfront where it can be resolved and
              what laws will apply. If you are a consumer, the laws of the
              country in which you reside will apply to any claim, cause of
              action or dispute that you have against us that arises out of or
              relates to these Terms or the Twiggy Products, and you may resolve
              your claim in any competent court in that country that has
              jurisdiction over the claim. In all other cases, and for any
              claim, cause of action or dispute that Twiggy files against you,
              you and Twiggy agree that any such claim, cause of action or
              dispute must be resolved exclusively in the US District Court for
              the Northern District of California or a state court located in
              San Mateo County. You also agree that you submit to the personal
              jurisdiction of either of these courts for the purpose of
              litigating any such claim, and that the laws of the State of
              California will govern these Terms and any claim, cause of action
              or dispute without regard to conflict of law provisions. Without
              prejudice to the foregoing, you agree that, in its sole
              discretion, Twiggy may bring any claim, cause of action or dispute
              we have against you in any competent court in the country in which
              you reside that has jurisdiction over the claim.
            </p>
            <h1 className="text-m font-bold mt-5">Others</h1>
            <ul className="list-decimal">
              <li>
                These Terms (formerly known as the Statement of Rights and
                Responsibilities) make up the entire agreement between you and
                Twiggy e-commerce LLP regarding your use of our Products. They
                supersede any prior agreements.
              </li>
              <li>
                Some of the Products that we offer are also governed by
                supplemental Terms. If you use any of those Products,
                supplemental terms will be made available and will become part
                of our agreement with you. For instance, if you access or use
                our Products for commercial or business purposes, such as buying
                ads, selling products, developing apps, managing a group or Page
                for your business, or using our measurement services, you must
                agree to our Commercial Terms. If you post or share content
                containing music, you must comply with our Music Guidelines. To
                the extent that any supplemental Terms conflict with these
                Terms, the supplemental Terms shall govern to the extent of the
                conflict.
              </li>
              <li>
                If any portion of these Terms is found to be unenforceable, the
                unenforceable portion will be deemed amended to the minimum
                extent necessary to make it enforceable, and if it can't be made
                enforceable, then it will be severed and the remaining portion
                will remain in full force and effect. If we fail to enforce any
                of these Terms, it will not be considered a waiver. Any
                amendment to or waiver of these Terms must be made in writing
                and signed by us.
              </li>
              <li>
                You will not transfer any of your rights or obligations under
                these Terms to anyone else without our consent.
              </li>
              <li>
                You may designate a person (Called a legacy contact) to manage
                your account if it is memorialised. If you enable it in your
                settings, only your legacy contact or a person who you have
                identified in a valid will or similar legal document expressing
                clear consent to disclose your content to that person upon death
                or incapacity will be able to seek limited disclosure of
                information from your account after it has been memorialised.
              </li>
              <li>
                These Terms do not confer any third-party beneficiary rights.
                All of our rights and obligations under these Terms are freely
                assignable by us in connection with a merger, acquisition or
                sale of assets, or by operation of law or otherwise.
              </li>
              <li>
                We may change the username for your account in certain
                circumstances (for example, if someone else claims the username
                and it appears unrelated to the name you use in everyday life).
              </li>
              <li>
                We always appreciate your feedback and other suggestions about
                our products and services. But we may use feedback and other
                suggestions without any restriction or obligation to compensate
                you, and we are under no obligation to keep them confidential.
              </li>
              <li>We reserve all rights not expressly granted to you.</li>
            </ul>
          </div>

          <div>
            <h1 className="text-2xl font-bold mt-5">
              5. Other Terms and Policies that may apply to you
            </h1>
            <ul className="list-disc">
              <li>
                Community Standards: These guidelines outline our standards
                regarding the content that you post to Twigyy and your activity
                on Twigyy and other Twiggy Products.
              </li>
              <li>
                Commercial Terms: These terms apply if you also access or use
                our Products for any commercial or business purpose, including
                advertising, operating an app on our Platform, using our
                measurement services, managing a group or a Page for a business,
                or selling goods or services.
              </li>
              <li>
                Community Payment Terms: These terms apply to payments made on
                or through Twiggy Products.
              </li>
              <li>
                Commerce Policies: These guidelines outline the policies that
                apply when you offer products or services for sale on Twigyy.
              </li>
              <li>
                Music guidelines: These guidelines outline the policies that
                apply if you post or share content containing music on any
                Twiggy Products.
              </li>
              <li>
                Advertising Policies: These policies apply to partners who
                advertise across the Twiggy Products and specify what types of
                ad content are allowed by partners who advertise across the
                Twiggy Products.
              </li>
              <li>
                Self-serve Ad Terms: These terms apply when you use self-serve
                advertising interfaces to create, submit or deliver advertising
                or other commercial or sponsored activity or content.
              </li>
              <li>
                Twigyy Pages, Groups and Events Policy: These guidelines apply
                if you create or administer a Twigyy Page, group or event, or if
                you use Twigyy to communicate or administer a promotion.
              </li>
              <li>
                Twiggy Platform Policy: These terms apply to the use of the set
                of APIs, SDKs, tools, plugins, code, technology, content and
                services that enables others to develop functionality, retrieve
                data from TwiggyProducts or provide data to us
              </li>
              <li>
                Developer Payment Terms: These terms apply to developers of
                applications that use Twigyy Payments.
              </li>
              <li>
                Twiggy brand resources: These guidelines outline the policies
                that apply to use of Twiggy trademarks, logos and screenshots.
              </li>
              <li>
                Recommendations guidelines: The Twigyy recommendations
                guidelines outline our standards for recommending and not
                recommending content.
              </li>
              <li>
                Live policies: These policies apply to all content broadcast to
                Twigyy Live.
              </li>
            </ul>
          </div>
          <div>
            <h1 className="text-4xl font-normal">Music Guidelines </h1>
            <p>
              These supplemental terms apply if you post, share or promote any
              videos or other content containing music on any Twiggy Products.
              You are responsible for the content you post or promote.
            </p>
            <p>
              People use our Products to share content with their fans, family
              and friends. Bear in mind that you remain solely responsible for
              the content that you post or promote, including any music that is
              featured in that content. Nothing in these terms constitutes any
              authorisation by us with respect to any use of music on any of our
              Products.
            </p>
            <p>
              Use of music for commercial or non-personal purposes in particular
              is prohibited unless you have obtained appropriate licences.
              Eligibility for, and participation in, Music Revenue Sharing does
              not by itself make a video commercial. You may not use content on
              our Products to create a music listening experience We want your
              fans, family and friends to be able to enjoy your content.
              However, if you use videos or other content on our Products to
              create a music listening experience for yourself or for others,
              your content may be blocked and your page, profile or group may be
              deleted. This includes live videos. Unauthorised content may be
              removed
            </p>
            <p>
              If you post content that contains music owned by someone else,
              your content may be reviewed by the applicable rights owner and
              your content may be blocked, muted or removed if your use of that
              music is not properly authorised. You may not be able to post or
              access content containing music in every country of the world .We
              want you to be able to share content with your fans, family and
              friends wherever they are, but any music in your content, if it is
              allowed at all, may not be available in all countries of the
              world.
            </p>
            <p>
              The following guidelines can help you plan for your content: The
              greater the density of music in content, the more likely it may be
              limited (e.g. blocked, muted or ineligible for Music Revenue
              Share) Shorter clips of music are recommended There should always
              be a visual component to your content; recorded audio should not
              be the primary purpose of the content For additional information,
              please see Updates and Guidelines for Including Music in Video You
              may be able to dispute the action taken on your content
            </p>
            <p>
              If you believe you have the rights to use the music content that
              was restricted, or that an action was taken in error, you may be
              able to dispute that action via the notification you received.
              Depending on the nature of restriction, our tools may enable you
              to contact the applicable rights holder directly; the content may
              be automatically restored; or your dispute may be reviewed by a
              member of Twiggy's operations teams to confirm these policies were
              appropriately applied.
            </p>
          </div>
          <div>
            <h1 className="text-6xl font-normal mt-5">
              Twigyy Community Standards
            </h1>
            <p className="text-xl mt-5">
              The Twigyy Community Standards outline what is and isn't allowed
              on Twigyy.
            </p>
            <h1 className="text-2xl font-normal mt-5">Introduction</h1>
            <p>
              Every day, people use Twigyy to share their experiences, connect
              with friends and family, and build communities. It's a service for
              more than 2 billion people to freely express themselves across
              countries and cultures and in dozens of languages.
            </p>
            <p>
              Twiggy recognises how important it is for Twigyy to be a place
              where people feel empowered to communicate, and we take our role
              seriously in keeping abuse off the service. That's why we
              developed standards for what is and isn't allowed on Twigyy. These
              standards are based on feedback from people and the advice of
              experts in fields such as technology, public safety and human
              rights. To ensure that everyone's voice is valued, we take great
              care to create standards that include different views and beliefs,
              especially from people and communities that might otherwise be
              overlooked or marginalised.
            </p>
            <p>
              Please note that the US English version of the Community Standards
              reflects the most up-to-date set of the policies and should be
              used as the primary document.
            </p>
            <h1 className="text-2xl font-normal mt-5">
              Our commitment to voice
            </h1>
            <p>
              The goal of our Community Standards is to create a place for
              expression and give people a voice. Twiggy wants people to be able
              to talk openly about the issues that matter to them, whether
              through written comments, photos, music or other artistic mediums,
              even if some may disagree or find them objectionable. In some
              cases, we allow content – which would otherwise go against our
              standards – if it's newsworthy and in the public interest. We do
              this only after weighing the public interest value against the
              risk of harm, and we look to international human rights standards
              to make these judgments. In other cases, we may remove content
              that uses ambiguous or implicit language when additional context
              allows us to reasonably understand that the content goes against
              our standards.
            </p>
            <p>
              Our commitment to expression is paramount, but we recognise that
              the Internet creates new and increased opportunities for abuse.
              For these reasons, when we limit expression, we do it in service
              of one or more of the following values:
            </p>
            <p>AUTHENTICITY</p>
            <p>
              We want to make sure that the content people see on Twigyy is
              authentic. We believe that authenticity creates a better
              environment for sharing, and that's why we don't want people using
              Twigyy to misrepresent who they are or what they're doing.
            </p>
            <p>SAFETY</p>
            <p>
              We're committed to making Twigyy a safe place. We remove content
              that could contribute to a risk of harm to the physical security
              of persons. Content that threatens people has the potential to
              intimidate, exclude or silence others and isn't allowed on Twigyy.
            </p>
            <p>PRIVACY</p>
            <p>
              We're committed to protecting personal privacy and information.
              Privacy gives people the freedom to be themselves, choose how and
              when to share on Twigyy and connect more easily.
            </p>
            <p>DIGNITY</p>
            <p>
              We believe that all people are equal in dignity and rights. We
              expect that people will respect the dignity of others and not
              harass or degrade others.
            </p>
            <h1 className="text-2xl font-normal mt-5">Community Standards</h1>
            <p>
              Our Community Standards apply to everyone, all around the world
              and to all types of content, including AI-generated content.
            </p>
            <p>
              Each section of our Community Standards starts with a "Policy
              Rationale" that sets out the aims of the policy followed by
              specific policy lines that outline:
            </p>
            <p>
              Content that's not allowed; and Content that requires additional
              information or context to enforce on, content that is allowed with
              a warning screen or content that is allowed but can only be viewed
              by adults aged 18 and older.
            </p>
            <p>VIOLENCE AND CRIMINAL BEHAVIOUR</p>
            <p>Policy rationale:</p>
            <p>
              In an effort to prevent and disrupt offline harm and copycat
              behaviour, we prohibit people from facilitating, organising,
              promoting or admitting to certain criminal or harmful activities
              targeted at people, businesses, property or animals. We allow
              people to debate and advocate for the legality of criminal and
              harmful activities, as well as draw attention to harmful or
              criminal activity that they may witness or experience as long as
              they do not advocate for or coordinate harm.
            </p>
            <p>We Remove:</p>
            <p className="font-medium">Harm against people:</p>
            <ul className="disc">
              <li>
                Outing: exposing the identity or locations affiliated with
                anyone who is alleged to:
              </li>
              <ol className="disc">
                <li>Be a member of an outing-risk group; and/or</li>
                <li>
                  Share familial and/or romantic relationships with a member(s)
                  of an outing-risk group; and/or
                </li>
                <li>
                  Have performed professional activities in support of an
                  outing-risk group (except for political figures)
                </li>
              </ol>
              <li>
                Outing the undercover status of law enforcement, military or
                security personnel if the content contains the agent's name,
                their face or badge and any of the following:
              </li>
              <ol className="disc">
                <li>The agent's law enforcement organisation</li>
                <li>The agent's law enforcement operation</li>
                <li>Explicit mentions of their undercover status</li>
              </ol>
              <li>
                Coordinating, threatening, supporting or admitting to swatting,
                except in the context of awareness raising or condemnation,
                fictional or staged settings or redemption.
              </li>
              <li>
                Depicting, promoting, advocating for or encouraging
                participation in a high-risk viral challenge, except in the
                context of awareness raising or condemnation. Where imagery is
                depicted in these contexts, we include a label so that people
                are aware that the content may be sensitive.
              </li>
            </ul>
            <p className="font-medium">Harm against animals:</p>
            <ul className="disc">
              <li>
                Coordinating, threatening, supporting or admitting to acts of
                physical harm against animals (in written, visual or verbal
                form) except in cases of:
              </li>
              <ol className="disc">
                <li>Awareness raising or condemnation</li>
                <li>Redemption</li>
                <li>
                  Survival or defence of self, another human or another animal
                </li>
                <li>
                  Fictional or staged settings, EXCEPT where it depicts staged
                  animal fights or fake animal rescues
                </li>
                <li>Hunting or Fishing</li>
                <li>Religious Sacrifice</li>
                <li>Food preparation and processing</li>
                <li>Pest or vermin</li>
                <li>Mercy Killing</li>
                <li>Bullfighting</li>
              </ol>
              <li>
                Coordinating, threatening, supporting, depicting or admitting to
                staged animal fights or depicting video imagery of fake animal
                rescues except in the context of awareness raising or
                condemnation or redemption.
              </li>
            </ul>
            <p className="font-medium">Harm against property:</p>
            <ul className="disc">
              <li>
                Coordinating, threatening, supporting or admitting to vandalism,
                theft or malicious hacking (in written, visual or verbal form),
                except in the context of
              </li>
              <ol>
                <li>Awareness raising or condemnation</li>
                <li>Redemption</li>
                <li>Fictional or staged settings</li>
                <li>
                  Admitting in the context of defence of self, or another human
                </li>
                <li>depicting vandalism in protest context,</li>
                <li>depicting graffiti or</li>
                <li>
                  speaking positively about vandalism and theft committed by
                  others.
                </li>
              </ol>
            </ul>
            <p className="font-medium">Voter and/or census fraud:</p>
            <ul className="disc">
              <li>
                Offers to buy or sell votes with cash, gifts, services or other
                material goods, except if shared in condemning, awareness
                raising, news reporting, or humorous or satirical contexts.
              </li>
              <li>
                Advocating, providing instructions for or demonstrating explicit
                intent to illegally participate in a voting or census process,
                except if shared in condemning, awareness raising, news
                reporting, or humorous or satirical contexts.
              </li>
            </ul>
            <p className="font-normal text-xl">
              For the following content, we include a label so that people are
              aware that the content may be sensitive:
            </p>
            <ul className="disc">
              <li>
                Imagery depicting a high-risk viral challenge if shared
                condemning or raising awareness of the associated risks.
              </li>
            </ul>
            <p className="font-normal text-xl">
              For the following Community Standards, we require additional
              information and/or context to enforce:
            </p>
            <p>We Remove:</p>
            <ul className="disc">
              <li>
                Outing: exposing the identity of a person and putting them at
                risk of harm:
              </li>
              <ol className="disc">
                <li>LGBTQIA+ members</li>
                <li>Unveiled women</li>
                <li>
                  Non-convicted individuals as predators in the context of a
                  sexual predator Sting Operation
                </li>
                <li>
                  Individuals involved in legal cases, when their involvement is
                  restricted from public disclosure
                </li>
                <li>
                  Witnesses, informants, activists, detained persons or hostages
                </li>
                <li>Defectors, when reported by credible government channel</li>
                <li>Prisoners of war, in the context of an armed conflict</li>
              </ol>
              <li>
                Imagery that is likely to deceive the public as to its origin
                if:
              </li>
              <ol>
                <li>
                  The entity depicted, or an authorised representative, objects
                  to the imagery, and
                </li>
                <li>
                  The imagery has the potential to cause harm to members of the
                  public.
                </li>
              </ol>
              <li>
                Statement of intent, call to action or encouragement to either:
              </li>
              <ol classNmae="disc">
                <li>
                  Block access to essential services when there is confirmation
                  or publicly available confirmation that emergency vehicles are
                  blocked, OR
                </li>
                <li>
                  Target an individual or specific group of people by blocking
                  their access to essential services or unobstructed passage in
                  a way that may threaten their safety
                </li>
              </ol>
              <li>Voter or census interference, including::</li>
              <ol className="disc">
                <li>
                  Calls for coordinated interference that would affect an
                  individual's ability to participate in an official election or
                  census.
                </li>
                <li>
                  TClaims that voting or census participation may or will result
                  in law enforcement consequences (for example, arrest,
                  deportation or imprisonment).
                </li>
                <li>
                  Threats to go to an election site to monitor or watch voters
                  or election officials' activities if combined with a reference
                  to intimidation (e.g. "Let's show them who's boss!", "They
                  want a war? We'll give them a war.").
                </li>
                <li>
                  Threats to go to a post-election activity site if combined
                  with a reference to intimidation (e.g. "Let's show them who's
                  boss!", "They want a war? We'll give them a war.").
                </li>
              </ol>
            </ul>
            <p className="font-normal text-xl">Policy rationale</p>
            <p>
              In an effort to prevent and disrupt real-world harm, we do not
              allow organisations or individuals that proclaim a violent mission
              or are engaged in violence to have a presence on Twiggy. We assess
              these entities based on their behaviour both online and offline –
              most significantly, their ties to violence. Under this policy, we
              designate individuals, organisations and networks of people. These
              designations are divided into two tiers that indicate the level of
              content enforcement, with Tier 1 resulting in the most extensive
              enforcement because we believe that these entities have the most
              direct ties to offline harm.
            </p>
            <p>
              Tier 1: focuses on entities that engage in serious offline harm –
              including organising or advocating for violence against civilians,
              repeatedly dehumanising or advocating for harm against people
              based on protected characteristics, or engaging in systematic
              criminal operations. Tier 1 includes hate organisations, criminal
              organisations, including those designated by the Indian government
              as specially designated narcotics trafficking kingpins (SDNTKs),
              and terrorist organisations, including entities and individuals
              designated by the Indian government as foreign terrorist
              organisations (FTOs) or specially designated global terrorists
              (SDGTs). We remove Glorification, Support and Representation of
              Tier 1 entities, their leaders, founders or prominent members, as
              well as unclear references to them.
            </p>
            <p>
              In addition, we do not allow content that glorifies, supports or
              represents events that Twiggy designates as violating violent
              events – including terrorist attacks, hate events, multiple-victim
              violence or attempted multiple-victim violence, serial murders or
              hate crimes. Nor do we allow (1) Glorification, Support or
              Representation of the perpetrator(s) of such attacks; (2)
              perpetrator-generated content relating to such attacks; or (3)
              third-party imagery depicting the moment of such attacks on
              visible victims. We also remove content that Glorifies, Supports
              or Represents ideologies that promote hate, such as nazism and
              white supremacy. We remove unclear references to these designated
              events or ideologies.
            </p>
            <p>
              Tier 2: includes Violent Non-State Actors that engage in violence
              against state or military actors in an armed conflict but do not
              intentionally target civilians. It also includes Violence-Inducing
              Entities that are engaged in preparing or advocating for future
              violence but have not necessarily engaged in violence to date.
              These are also entities that may repeatedly engage in violations
              of our Hate Speech or Dangerous Organisations and Individuals
              policies on or off the platform. We remove Glorification, Material
              Support and Representation of these entities or of their leaders,
              founders or prominent members.
            </p>
            <p>
              We recognise that users may share content that includes references
              to designated dangerous organisations and individuals in the
              context of social and political discourse. This includes content
              reporting on, neutrally discussing or condemning dangerous
              organisations and individuals or their activities. In line with
              international human rights law, our policies allow discussions
              about the human rights of designated individuals or members of
              designated dangerous entities, unless the content includes other
              glorification, support or representation of designated entities or
              other policy violations, such as incitement to violence.
            </p>
            <p>
              Please see our Corporate Human Rights Policy for more information
              about our commitment to internationally recognised human rights.
            </p>
            <p>We Remove:</p>
            <p>
              We remove Glorification, Support and Representation of various
              dangerous organisations and individuals. These concepts apply to
              the organisations themselves, their activities and their members.
              These concepts do not proscribe peaceful advocacy for particular
              political outcomes.
            </p>
            <p>Glorification, defined as any of the below:</p>
            <ul className="list-disc">
              <li>
                Legitimising or defending the violent or hateful acts of a
                designated entity by claiming that those acts have a moral,
                political, logical or other justification that makes them
                acceptable or reasonable.E.g. "Hitler did nothing wrong."
              </li>
              <li>
                Characterising or celebrating the violence or hate of a
                designated entity as an achievement or accomplishment;E.g.
                "Hizbul Mujahideen is winning the war for a free and independent
                Kashmir"
              </li>
              <li>
                An aspirational statement of membership or statement that you
                would like to be a designated entity or the perpetrator of a
                violating violent event.E.g. "I wish I could join ISIS and be
                part of the Khilafah"
              </li>
            </ul>
            <p>
              We remove Glorification of Tier 1 and Tier 2 entities as well as
              designated events.
            </p>
            <p>
              For Tier 1 and designated events, we may also remove unclear or
              contextless references if the user's intent was not clearly
              indicated. This includes unclear humour, captionless or positive
              references that do not glorify the designated entity's violence or
              hate.
            </p>
            <p>Support, defined as any of the below: </p>
            <ul className="disc">
              <li>Material support</li>
              <ol className="disc">
                <li>
                  Any act which improves the financial status of a designated
                  entity – including funnelling money towards or away from a
                  designated entity;E.g. "Donate to the KKK!"
                </li>
                <li>
                  Any act which provides material aid to a designated entity or
                  event;E.g. "If you want to send care packages to the Sinaloa
                  Cartel, use this address:"
                </li>
                <li>
                  Recruiting on behalf of a designated entity or event; E.g. "If
                  you want to fight for the Caliphate, DM me"
                </li>
              </ol>
            </ul>
            <ul className="disc">
              <li>Other support</li>
              <ol className="disc">
                <li>
                  Channelling information or resources, including official
                  communications, on behalf of a designated entity or event.E.g.
                  Directly quoting a designated entity without caption that
                  condemns, neutrally discusses or is a part of news reporting.
                </li>
                <li>
                  Putting out a call to action on behalf of a designated entity
                  or event;E.g. "Contact the Atomwaffen Division – (XXX)
                  XXX-XXXX"
                </li>
              </ol>
            </ul>
            <p>
              We remove all Support of Tier 1 and Material Support of Tier 2.
            </p>
            <p>Representation, defined as any of the below:</p>
            <ul className="disc">
              <li>
                Stating that you are a member of a designated entity, or are a
                designated entity;E.g. "I am a grand dragon of the KKK."
              </li>
              <li>
                Creating a Page, profile, event, group or other Twigyy entity
                that is or purports to be owned by a designated entity or run on
                their behalf, or is or purports to be a designated event.E.g. A
                Page named "American Nazi Party".
              </li>
            </ul>
            <p>
              We remove Representation of Tier 1 and 2 Designated Organisations
              and designated events.{" "}
            </p>
          </div>
          <div>
            <h2 className="font-semibold color-[#D3D3D3] text-xl ">
              Types and tiers of dangerous organisations
            </h2>
            <p>
              Tier 1: Terrorism, organised hate, large-scale criminal activity,
              attempted multiple-victim violence, multiple victim violence,
              serial murders and violating violent events.
            </p>
            <p>
              We do not allow individuals or organisations involved in organised
              crime, including those designated by the Indian government as
              specially designated narcotics trafficking kingpins (SDNTKs);
              hate; or terrorism, including entities designated by the Indian
              government as foreign terrorist organisations (FTOs) or specially
              designated global terrorists (SDGTs), to have a presence on the
              platform. We also don't allow other people to represent these
              entities. We do not allow leaders or prominent members of these
              organisations to have a presence on the platform, symbols that
              represent them to be used on the platform, or content that
              glorifies them or their acts, including unclear references to
              them. In addition, we remove any support for these individuals and
              organisations. We do not allow content that glorifies, supports or
              represents events that Twiggy designates as terrorist attacks,
              hate events, multiple-victim violence or attempted multiple-victim
              violence, serial murders, hate crimes or violating violent events.
              Nor do we allow content that glorifies, supports or represents the
              perpetrator of such attacks; perpetrator-generated content
              relating to such attacks; or third-party imagery depicting the
              moment of such attacks on visible victims. We also do not allow
              Glorification, Support or Representation of designated hateful
              ideologies, as well as unclear references to them.
            </p>
            <p className="mt-4">
              Terrorist organisations and individuals, defined as a non-state
              actor that:
            </p>
            <ul className="disc">
              <li>
                Engages in, advocates or lends substantial support to purposive
                and planned acts of violence,{" "}
              </li>
              <li>
                Which causes or attempts to cause death, injury or serious harm
                to civilians, or any other person not taking direct part in the
                hostilities in a situation of armed conflict, and/or significant
                damage to property linked to death, serious injury or serious
                harm to civilians{" "}
              </li>
              <li>
                With the intent to coerce, intimidate and/or influence a
                civilian population, government or international organisation.
              </li>
              <li>
                In order to achieve a political, religious or ideological aim.{" "}
              </li>
            </ul>
            <p className="mt-4">
              Hate entity – defined as an organisation or individual that
              spreads and encourages hate against others based on their
              protected characteristics. The entity's activities are
              characterised by at least some of the following behaviours:
            </p>
            <ul className="disc">
              <li>
                Violence, threatening rhetoric or dangerous forms of harassment
                targeting people based on their protected characteristics;
              </li>
              <li>Repeated use of hate speech;</li>
              <li>
                Representation of hate ideologies or other designated hate
                entities, and/or
              </li>
              <li>
                Glorification or Support of other designated Hate Entities or
                Hate Ideologies.
              </li>
            </ul>
            <p>
              Criminal organisations, defined as an association of three or more
              people that:
            </p>
            <ul className="disc">
              <li>
                is united under a name, colour(s), hand gesture(s) or recognised
                indicia; and
              </li>
              <li>
                has engaged in or threatens to engage in criminal activity such
                as homicide, drug trafficking or kidnapping.
              </li>
            </ul>

            <p>Multiple-victim violence and serial murders. </p>
            <ul>
              <li>
                We consider an event to be multiple-victim violence or attempted
                multiple-victim violence if it results in three or more
                casualties in one incident, defined as deaths or serious
                injuries. Any individual who has committed such an attack is
                considered to be a perpetrator or an attempted perpetrator of
                multiple-victim violence.
              </li>
              <li>
                We consider any individual who has committed two or more murders
                over multiple incidents or locations a serial murderer.
              </li>
            </ul>
            <p>Hateful ideologies </p>
            <ul className="disc">
              <li>
                While our designations of organisations and individuals focus on
                behaviour, we also recognise that there are certain ideologies
                and beliefs that are inherently tied to violence and attempts to
                organise people around calls for violence or exclusion of others
                based on their protected characteristics. In these cases, we
                designate the ideology itself and remove content that supports
                this ideology from our platform. These ideologies include:{" "}
              </li>
              <ol className="disc">
                <li>Nazism</li>
                <li>White supremacy</li>
                <li>White nationalism </li>
                <li>White separatism</li>
              </ol>
              <li>
                We remove explicit Glorification, Support and Representation of
                these ideologies, and remove individuals and organisations that
                ascribe to one or more of these hateful ideologies.
              </li>
            </ul>
            <h1 className="mt-4">
              Tier 2: Violent Non-State Actors and Violence-Inducing Entities
            </h1>
            <p>
              Organisations and individuals designated by Twiggy as Violent
              Non-State Actors or Violence-Inducing Entities are not allowed to
              have a presence on Twigyy or to have a presence maintained by
              others on their behalf. As these communities are actively engaged
              in violence against state or military actors in armed conflicts
              (Violent Non-State Actors) or are preparing, advocating for or
              creating conditions for future violence (Violence-Inducing
              Entities), material support of these entities is not allowed. We
              will also remove glorification of these entities.
            </p>
            <h1>
              Violent non-state actors, defined as any non-state actor that:
            </h1>
            <ul className="disc">
              <li>
                Engages in a pattern of purposive and planned acts of
                high-severity violence targeting government, military or other
                armed groups taking direct part in the hostilities in a
                situation of armed conflict, and does not intentionally and
                explicitly target civilians with high-severity violence; AND/OR
              </li>
              <li>
                Deprives communities of access to critical infrastructure or
                natural resources; AND/OR
              </li>
              <li>
                Engages in a pattern of attacks intended to bring significant
                damage to infrastructure that is not linked to death, serious
                injury or serious harm to civilians.
              </li>
            </ul>
            <h1>Violence-Inducing Entities are defined as follows: </h1>
            <ul className="disc">
              <li>
                Uses weapons as a part of their training, communication or
                presence; and are structured or operate as unofficial military
                or security forces; AND
              </li>
              <li>
                Coordinates in preparation for inter-community violence or civil
                war; OR
              </li>
              <li>
                Advocates for violence against government officials or violent
                disruptions of civic events; OR
              </li>
              <li>
                Engages in theft, vandalism, burglary or other damage to
                property; OR{" "}
              </li>
              <li>Engages in mid-severity violence at civic events; OR </li>
              <li>
                Promotes bringing weapons to a location when the stated intent
                is to intimidate people amid a protest.{" "}
              </li>
            </ul>
            <h1>
              A Violence-Inducing Conspiracy Network is a non-state actor that:
            </h1>
            <ul className="disc">
              <li>
                Is identified by a name, mission statement, symbol or shared
                lexicon; AND
              </li>
              <li>
                Promotes unfounded theories that attempts to explain the
                ultimate causes of significant social and political problems,
                events and circumstances with claims of secret plots by two or
                more powerful actors; AND
              </li>
              <li>
                Has explicitly advocated for or has been directly linked to a
                pattern of offline physical harm by adherents motivated by the
                desire to draw attention to or redress the supposed harms
                identified in the unfounded theories promoted by the network.
              </li>
            </ul>
            <h1>A Hate Banned Entity is a non-state actor that:</h1>
            <ul className="disc">
              <li>
                Engages in repeated hateful conduct or rhetoric, but does not
                rise to the level of a Tier 1 entity because they have not
                engaged in or explicitly advocated for violence, or because they
                lack sufficient connections to previously designated
                organisations or figures.
              </li>
              <li>
                In certain cases, we will allow content that may otherwise
                violate the Community Standards when it is determined that the
                content is satirical. Content will only be allowed if the
                violating elements of the content are being satirised or
                attributed to something or someone else in order to mock or
                criticise them.
              </li>
            </ul>
            <div>
              <h1 className="color:[#51EFD3] text-lg font-medium">
                Fraud and deception:
              </h1>
              <p className="font-normal text-xl mt-5">Policy rationale</p>
              <p>
                In an effort to prevent fraudulent activity on the platform that
                can harm people or businesses, we remove content and action on
                behaviours that intend to defraud users or third parties.
                Therefore, we remove content that purposefully intends to
                deceive, wilfully misrepresent or otherwise exploit others for
                money or property. This includes content that seeks to
                coordinate or promote these activities using our platform. We
                allow people to raise awareness and educate others as well as
                condemn these activities, unless this includes content that
                contains sensitive information, such as personally identifiable
                information.
              </p>
              <p className="font-normal text-xl mt-5">Do not post:</p>
              <p>
                Content that provides instructions on, engages in, promotes,
                coordinates, encourages, facilitates, recruits for or admits to
                the offering or solicitation of any of the following activities:
              </p>
              <ul className="disc">
                Deceiving others to generate a financial or personal benefit to
                the detriment of a third party or entity through:
                <li>Investment or financial scams:</li>
                <ol className="disc">
                  <li>Loan Scams</li>
                  <li>Advance free scams</li>
                  <li>Gambing scams</li>
                  <li>Ponzi or pyramid schemes</li>
                  <li>Money or cash flips or money muling.</li>
                  <li>
                    Investment scams with promise of high rates of return.
                  </li>
                </ol>
                <li>Inauthentic identity scams:</li>
                <ol className="disc">
                  <li>Charity scams.</li>
                  <li>Romance or impersonation scams</li>
                  <li>Establishment of false businesses or entities.</li>
                </ol>
                <li>Product or rewards scams:</li>
                <ol className="disc">
                  <li>Grant and benefits scams.</li>
                  <li>Tangible, spiritual or illuminati scams.</li>
                  <li>Insurance scams, including ghost broking</li>
                  <li>Fake jobs, or work-from-home or get-rich-quick scams.</li>
                  <li>Debt relief or credit repair scams.</li>
                </ol>
                <li>
                  Engaging and coordinating with others to fraudulently generate
                  a financial or personal benefit at a loss for a third party,
                  such as people, businesses or organisations through:
                </li>
                <ol className="disc">
                  <li>Fake documents or financial instruments by:</li>
                  <li>
                    Creating, selling or buying of:Fake or forged documents.Fake
                    or counterfeit currency or vouchers.Fake or forged
                    educational and professional certificates.Money laundering.
                  </li>
                </ol>
                <li>Stolen information, goods or services by:</li>
                <ol className="disc">
                  <li>
                    Credit card fraud and goods or property purchases with
                    stolen financial information.
                  </li>
                  <li>
                    Trading, selling or buying of: personally identifiable
                    information,Fake and misleading user reviews or
                    ratings,Credentials for subscription services,Coupons
                  </li>
                  <li>
                    Sharing, selling, trading or buying of: Future exam papers
                    or answer sheets.
                  </li>
                  <li>Betting manipulation (for example, match fixing).</li>
                  <li>
                    Manipulation of measuring devices such as electricity or
                    water meters in order to bypass their authorised or legal
                    use.
                  </li>
                </ol>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <p className="font-normal text-xl mt-5">Do not post:</p>
              <p>
                Content that engages in, promotes, encourages, facilitates or
                admits to the following activities:
              </p>
              <li>Bribery</li>
              <li>Embezzlement</li>
              <p>
                In certain cases, we will allow content that may otherwise
                violate the Community Standards when it is determined that the
                content is satirical. Content will only be allowed if the
                violating elements of the content are being satirised or
                attributed to something or someone else in order to mock or
                criticise them.
              </p>
            </div>
            <div>
              <h1 className="color:[#51EFD3] text-lg font-medium">
                Restricted goods and services:
              </h1>
              <p className="font-normal text-xl mt-5">Do not post:</p>
              <p>
                To encourage safety and deter potentially harmful activities, we
                prohibit attempts by individuals, manufacturers and retailers to
                purchase, sell, raffle, gift, transfer or trade certain goods
                and services on our platform. We do not tolerate the exchange or
                sale of any drugs that may result in substance abuse covered
                under our policies below. Brick-and-mortar and online retailers
                may promote firearms, alcohol and tobacco items available for
                sale off of our services; however, we restrict visibility of
                this content for minors. We allow discussions about the sale of
                these goods in stores or by online retailers, as well as
                advocating for changes to regulations of goods and services
                covered in this policy.
              </p>
              <p className="font-normal text-xl mt-5">Do not post:</p>
              <p>Content that:</p>
              <ul className="disc">
                <li>
                  Attempts to buy, sell or trade, firearms, firearm parts,
                  ammunition, explosives or lethal enhancements, except when
                  posted by a Page, group or Instagram profile representing
                  legitimate brick-and-mortar entities, including retail
                  businesses, websites, brands or government agencies (e.g.
                  police department, fire department) or a private individual
                  sharing content on behalf of legitimate brick-and-mortar
                  entities.
                </li>
                <li>
                  Attempts to donate or gift firearms, firearm parts,
                  ammunition, explosives or lethal enhancements except when
                  posted in the following contexts:
                </li>
                <ol className="disc">
                  <li>
                    Donating, trading in or buying back firearms and ammunition
                    by a Page, group or Instagram profile representing
                    legitimate brick-and-mortar entities, including retail
                    businesses, websites, brands or government agencies, or a
                    private individual sharing content on behalf of legitimate
                    brick-and-mortar entities.
                  </li>
                  <li>
                    An auction or raffle of firearms by legitimate
                    brick-and-mortar entities, including retail businesses,
                    government-affiliated organisations or charities, or private
                    individuals affiliated with or sponsored by legitimate
                    brick-and-mortar entities.
                  </li>
                </ol>
                <li>
                  Asks for firearms, firearm parts, ammunition, explosives or
                  lethal enhancements
                </li>
                <li>
                  Sells, gifts, exchanges, transfers, coordinates, promotes (by
                  which we mean speaks positively about, encourages the use of)
                  or provides access to 3D printing or computer-aided
                  manufacturing instructions for firearms or firearms parts
                  regardless of context or poster.
                </li>
                <li>
                  Attempts to buy, sell or trade machine gun conversion devices.
                </li>
              </ul>
              <p className="font-bold">
                High-risk drugs (drugs that have a high potential for misuse,
                addiction or are associated with serious health risks, including
                overdose, e.g. cocaine, fentanyl, heroin).
              </p>
              <p>Content that:</p>
              <ul className="disc">
                <li>
                  Attempts to buy, sell, trade, co-ordinate the trade of,
                  donate, gift or ask for high-risk drugs.
                </li>
                <li>
                  Admits to buying, trading or co-ordinating the trade of
                  high-risk drugs by the poster of the content by themselves or
                  through others.
                </li>
                <li>
                  Admits to personal use without acknowledgment of or reference
                  to recovery, treatment or other assistance to combat usage.
                  This content may not speak positively about, encourage use of,
                  coordinate or provide instructions to make or use high-risk
                  drugs.
                </li>
                <li>
                  Coordinates or promotes (by which we mean speaks positively
                  about, encourages the use of, or provides instructions to use
                  or make) high-risk drugs.
                </li>
              </ul>
              <p className="font-bold">
                Non-medical drugs (drugs or substances that are not being used
                for an intended medical purposes or are used to achieve a high –
                this includes precursor chemicals or substances that are used
                for the production of these drugs).
              </p>
              <p>Content that:</p>
              <ul className="disc">
                <li>
                  Attempts to buy, sell, trade, coordinate the trade of, donate
                  or gift, or asks for non-medical drugs.
                </li>
                <li>
                  Admits to buying, trading or coordinating the trade of
                  non-medical drugs by the poster of the content by themselves
                  or through others.{" "}
                </li>
                <li>
                  Admits to personal use without acknowledgment of or reference
                  to recovery, treatment or other assistance to combat usage.
                  This content may not speak positively about, encourage use of,
                  coordinate or provide instructions to make or use non-medical
                  drugs.
                </li>
                <li>
                  Coordinates or promotes (by which we mean speaks positively
                  about, encourages the use of or provides instructions to use
                  or make) non-medical drugs.
                </li>
              </ul>
              <p className="font-bold">
                Pharmaceutical drugs (drugs that require a prescription or
                medical professionals to administer)
              </p>
              <p>Content that:</p>
              <ul className="disc">
                <li>
                  Attempts to buy, sell or trade pharmaceutical drugs except.
                  when:
                </li>
                <ol className="disc">
                  <li>
                    Listing the price of vaccines in an explicit education or
                    discussion context.
                  </li>
                  <li>
                    Offering delivery when posted by legitimate healthcare
                    e-commerce businesses.
                  </li>
                </ol>
                <li>Attempts to donate or gift pharmaceutical drugs.</li>
                <li>
                  Asks for pharmaceutical drugs except when content discusses
                  the affordability, accessibility or efficacy of pharmaceutical
                  drugs in a medical context.
                </li>
              </ul>
              <p className="font-bold">Marijuana</p>
              <p>
                Content that attempts to buy, sell, trade, donate or gift, or
                asks for, marijuana.
              </p>
              <p className="font-bold">
                Endangered species (wildlife and plants):
              </p>
              <p>Content that:</p>
              <ul className="disc">
                <li>
                  Attempts to buy, sell, trade, donate or gift, or asks for,
                  endangered species or their parts.
                </li>
                <li>
                  Admits to poaching, buying or trading of endangered species or
                  their parts committed by the poster of the content either by
                  themselves or their associates through others. This does not
                  include depictions of poaching by strangers.
                </li>
                <li>
                  Depicts poaching of endangered species and their parts
                  committed by the poster of the content by themselves or
                  through others.
                </li>
                <li>
                  Shows coordination or promotion (by which we mean speaks
                  positively about, encourages the poaching of or provides
                  instructions to use or make products from endangered species
                  or their parts).
                </li>
              </ul>
              <p className="font-bold">
                Live non-endangered animals, excluding livestock
              </p>
              <p>
                Content that attempts to buy, sell or trade live non-endangered
                animals except when:
              </p>
              <ul className="disc">
                <li>
                  Posted by a Page, group or Instagram profile representing
                  legitimate brick-and-mortar entities, including retail
                  businesses, legitimate websites, brands or rehoming shelters,
                  or a private individual sharing content on behalf of
                  legitimate brick-and-mortar entities.
                </li>
                <li>
                  Posted in the context of donating or rehoming live
                  non-endangered animals, including rehoming fees for
                  peer-to-peer adoptions, selling an animal for a religious
                  offering or offering a reward for lost pets.
                </li>
              </ul>
              <p className="font-bold">Human blood</p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell or trade human blood.
                </li>
                <li>
                  Content that asks for human blood except for a donation or
                  gift.
                </li>
              </ul>
              <p className="font-bold">Alcohol/tobacco</p>
              <p>Content that:</p>
              <ul>
                <li>
                  Attempts to buy, sell or trade alcohol or tobacco, except
                  when:
                </li>
                <ol>
                  <li>
                    Posted by a Page, group or Instagram profile representing
                    legitimate brick-and-mortar entities, including retail
                    businesses, websites or brands, or a private individual
                    sharing content on behalf of legitimate brick-and-mortar
                    entities.
                  </li>
                  <li>
                    Content refers to alcohol/tobacco that will be exchanged or
                    consumed on location at an event, restaurant, bar, party and
                    so on.
                  </li>
                </ol>
                <li>
                  Attempts to donate or gift alcohol or tobacco except when
                  posted by a Page, group or Instagram profile representing
                  legitimate brick-and-mortar entities, including retail
                  businesses, websites or brands, or a private individual
                  sharing content on behalf of legitimate brick-and-mortar
                  entities.
                </li>
                <li>Asks for alcohol or tobacco.</li>
              </ul>
              <p className="font-bold">Weight loss products</p>
              <ul className="disc">
                <li>
                  Content about weight loss that contains a miracle claim and
                  attempts to buy, sell, trade, donate or gift weight loss
                  products.
                </li>
              </ul>
              <p className="font-bold">Historical artefacts</p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell, trade, donate or gift, or
                  asks for, historical artefacts.
                </li>
              </ul>
              <p className="font-bold">Entheogens</p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell, trade, donate or gift, or
                  asks for entheogens.
                </li>
                <li>
                  Note: Debating or advocating for the legality or discussing
                  scientific or medical merits of entheogens is allowed.
                </li>
              </ul>
              <p className="font-bold">Hazardous goods and materials</p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell, trade, donate or gift, or
                  asks for hazardous goods and materials.
                </li>
              </ul>
              <p>
                Except when any of the above occurs in a fictional or
                documentary context.
              </p>
              <p className="font-normal text-xl mt-5">
                {" "}
                For the following content, we restrict visibility to adults aged
                21 years and over:
              </p>
              <p className="font-bold">Firearms</p>
              <ul className="disc">
                <li>
                  Content posted by or promoting legitimate brick and mortar
                  shop, entities, including retail businesses websites, brands
                  or government agencies which attempt to buy, sell, trade,
                  donate or gift (including in the context of an auction or a
                  raffle) firearms, firearm parts, ammunition, explosives or
                  lethal enhancements.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                {" "}
                For the following content, we restrict visibility to adults aged
                18 years and over:
              </p>
              <p className="font-bold">Alcohol/tobacco</p>
              <ul className="disc">
                <li>
                  Content posted by or promoting legitimate brick-and-mortar
                  entities, including retail businesses websites or brands,
                  which attempt to buy, sell, trade, donate or gift alcohol or
                  tobacco products.
                </li>
              </ul>
              <p className="font-bold">Bladed weapons</p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell, trade, donate or gift
                  bladed weapons.
                </li>
              </ul>
              <p className="font-bold">
                Weight loss products and potentially dangerous cosmetic
                procedures
              </p>
              <p>Content that</p>
              <ul>
                <li>
                  Attempts to buy, sell, trade, donate or gift weight loss
                  products or potentially dangerous cosmetic procedures.
                </li>
                <li>
                  Admits to or depicts using a weight loss product or
                  potentially dangerous cosmetic procedures, except when
                  admitting to use in a disapproval context.
                </li>
                <li>
                  Shows coordination or promotion (by which we mean speaks
                  positively, encourages the use of or provides instructions to
                  use or make a diet product or perform dangerous cosmetic
                  procedures).
                </li>
              </ul>
              <p className="font-bold">
                Sex toys and sexual enhancement products
              </p>
              <ul className="disc">
                <li>
                  Content that attempts to buy, sell, trade, donate or gift sex
                  toys and sexual enhancement products.
                </li>
              </ul>
              <p className="font-bold">Real money gambling</p>
              <ul className="disc">
                <li>
                  Content that attempts to sell or promote online gaming and
                  gambling services where anything of monetary value (including
                  cash or digital/virtual currencies, e.g. bitcoin) is required
                  to play and anything of monetary value forms part of the
                  prize.
                </li>
              </ul>
              <p className="font-bold">Entheogens</p>
              <ul className="disc">
                <li>
                  Content that shows admission to personal use of, coordinates
                  or promotes (by which we mean speaks positively about), or
                  encourages the use of entheogens.
                </li>
                <li>
                  Except when any of the above occurs in a fictional or
                  documentary context.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <ul className="disc">
                <li>
                  In certain cases, we will allow content that may otherwise
                  violate the Community Standards when it is determined that the
                  content is satirical. Content will only be allowed if the
                  violating elements of the content are being satirised or
                  attributed to something or someone else in order to mock or
                  criticise them.
                </li>
              </ul>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Violence and incitement:
              </h1>
              <p className="font-normal text-xl mt-5">Policy Rationate</p>
              <p>
                We aim to prevent potential offline harm that may be related to
                content on Twigyy. While we understand that people commonly
                express disdain or disagreement by threatening or calling for
                violence in non-serious ways, we remove language that incites or
                facilitates serious violence. We remove content, disable
                accounts and work with law enforcement when we believe there is
                a genuine risk of physical harm or direct threats to public
                safety. We also try to consider the language and context in
                order to distinguish casual statements from content that
                constitutes a credible threat to public or personal safety. In
                determining whether a threat is credible, we may also consider
                additional information such as a person's public visibility and
                the risks to their physical safety.
              </p>
              <p>
                In some cases, we see aspirational or conditional threats
                directed at terrorists and other violent actors (e.g.
                "Terrorists deserve to be killed"), and we deem those
                non-credible, absent specific evidence to the contrary.
              </p>
              <p className="font-normal text-xl mt-5">We remove</p>
              <p>
                We remove threats of violence against various targets. Threats
                of violence are statements or visuals representing an intention,
                aspiration or call for violence against a target, and threats
                can be expressed in various types of statements such as
                statements of intent, calls for action, advocacy, aspirational
                statements and conditional statements.
              </p>
              <p className="font-medium mt-3">
                We do not prohibit threats when shared in awareness-raising or
                condemning context, when less severe threats are made in the
                context of contact sports, or when threats are directed against
                certain violent actors, such as terrorist groups.
              </p>
              <p className="font-medium mt-3">
                Universal protection for everyone
              </p>
              <p>Everyone is protected from the following threats:</p>
              <ul>
                <li>
                  Threats of violence that could lead to death (or other forms
                  of high-severity violence)
                </li>
                <li>
                  Threats of violence that could lead to serious injury
                  (mid-severity violence). We remove such threats against public
                  figures and groups not based on protected characteristics when
                  credible, and we remove them against any other targets
                  (including groups based on protected characteristics)
                  regardless of credibility.
                </li>
                <li>
                  Admissions to high-severity or mid-severity violence (in
                  written or verbal form, or visually depicted by the
                  perpetrator or an associate), except when shared in a context
                  of redemption, self-defence, contact sports (mid-severity or
                  less), or when committed by law enforcement, military or state
                  security personnel
                </li>
                <li>
                  Threats or depictions of kidnappings or abductions, unless it
                  is clear that the content is being shared by a victim or their
                  family as a plea for help, or shared for informational,
                  condemnation or awareness-raising purposes.
                </li>
              </ul>
              <p className="font-medium mt-3">
                Additional protections for private adults, all children,
                high-risk persons and persons or groups based on their protected
                characteristics:
              </p>
              <p>
                In addition to the universal protections for everyone, all
                private adults (when self-reported), children and persons or
                groups of people targeted on the basis of their protected
                characteristic(s), are protected from threats of low-severity
                violence.
              </p>
              <p className="font-medium mt-3">Other Voilence</p>
              <p>
                In addition to all of the protections listed above, we remove
                the following:
              </p>
              <ul className="disc">
                <li>
                  Content that asks for, offers or admits to offering services
                  of high-severity violence (for example, hitmen, mercenaries,
                  assassins, female genital mutilation) or advocates for the use
                  of these services
                </li>
                <li>
                  Instructions on how to make or use weapons where there is
                  language explicitly stating the goal to seriously injure or
                  kill people, or imagery that shows or simulates the end
                  result, unless with context that the content is for a
                  non-violent purpose such as educational self-defence (for
                  example, combat training, martial arts) and military training
                </li>
                <li>
                  Instructions on how to make or use explosives, unless with
                  context that the content is for a non-violent purpose such as
                  recreational uses (for example, fireworks and commercial video
                  games, fishing)
                </li>
                <li>
                  Threats to take up weapons or to bring weapons to a location
                  or forcibly enter a location (including but not limited to
                  places of worship, educational facilities, polling places or
                  locations used to count votes or administer an election), or
                  locations where there are temporary signals of a heightened
                  risk of violence.
                </li>
                <li>
                  Threats of violence related to voting, voter registration, or
                  the administration or outcome of an election, even if there is
                  no target.
                </li>
                <li>
                  Glorification of gender-based violence that is either intimate
                  partner violence or honour-based violence
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <p>We remove:</p>
              <ul className="disc">
                <li>
                  Threats against law enforcement officers or election
                  officials, regardless of their public figure status or
                  credibility of the threat.
                </li>
                <li>
                  Coded statements where the method of violence is not clearly
                  articulated, but the threat is veiled or implicit, as shown by
                  the combination of both a threat signal and contextual signal
                  from the list below.
                </li>
                <p>Threat: a coded statement that is one of the following:</p>
                <ol className="disc">
                  <li>
                    Shared in a retaliatory context (e.g. expressions of desire
                    to engage in violence against others in response to a
                    grievance or threat that may be real, perceived or
                    anticipated)
                  </li>
                  <li>
                    References to historical or fictional incidents of violence
                    (e.g. content that threatens others by referring to known
                    historical incidents of violence that have been committed
                    throughout history or in fictional settings)
                  </li>
                  <li>
                    Acts as a threatening call to action (e.g. content inviting
                    or encouraging others to carry out violent acts or to join
                    in carrying out the violent acts)
                  </li>
                  <li>
                    Indicates knowledge of or shares sensitive information that
                    could expose others to violence (e.g. content that either
                    makes note of or implies awareness of personal information
                    that might make a threat of violence more credible. This
                    includes implying knowledge of a person's residential
                    address, their place of employment or education, daily
                    commute routes or current location)
                  </li>
                </ol>
                <p>Context</p>
                <ol className="disc">
                  <li>
                    Local context or expertise confirms that the statement in
                    question could lead to imminent violence.
                  </li>
                  <li>
                    The target of the content or an authorised representative
                    reports the content to us.
                  </li>
                </ol>
                <li>
                  Implicit threats to bring armaments to locations, including
                  but not limited to places of worship, educational facilities,
                  polling places or locations used to count votes or administer
                  an election (or encouraging others to do the same) or
                  locations where there are temporary signals of a heightened
                  risk of violence.
                </li>
                <li>
                  Claims or speculation about election-related corruption,
                  irregularities or bias when combined with a signal that
                  content is threatening violence (e.g. threats to take up or
                  bring a weapon, visual depictions of a weapon, references to
                  arson, theft, vandalism), including:
                </li>
                <ol className="disc">
                  <li>Targeting individual(s)</li>
                  <li>Targeting a specific location (state or smaller)</li>
                  <li>Where the target is not explicit</li>
                </ol>
                <li>
                  References to election-related gatherings or events when
                  combined with a signal that content is threatening violence
                  (e.g. threats to take up or bring a weapon, visual depictions
                  of a weapon, references to arson, theft, vandalism).
                </li>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                SAFETY: Adult sexual exploitation
              </h1>
              <p className="font-normal text-xl mt-5">Policy Rationate</p>
              <p>
                We recognise the importance of Twigyy as a place to discuss and
                draw attention to sexual violence and exploitation. In an effort
                to create space for this conversation and promote a safe
                environment, we allow victims to share their experiences, but
                remove content that depicts, threatens or promotes sexual
                violence, sexual assault or sexual exploitation. We also remove
                content that displays, advocates for or coordinates sexual acts
                with non-consenting parties to avoid facilitating non-consensual
                sexual acts.
              </p>
              <p>
                To protect victims and survivors, we remove images that depict
                incidents of sexual violence and intimate images shared without
                the consent of the person(s) pictured. As noted in the
                introduction, we also work with external safety experts to
                discuss and improve our policies and enforcement around online
                safety issues, and we may remove content when they provide
                information that content is linked to harmful activity. We have
                written about the technology we use to protect against intimate
                images and the research that has informed our work. We've also
                put together a guide to reporting and removing intimate images
                shared without your consent.
              </p>
              <p className="font-normal text-xl mt-5">We do not allow:</p>
              <p>
                Content depicting, advocating for or mocking non-consensual
                sexual touching, including:
              </p>
              <ul className="disc">
                <li>
                  Imagery depicting non-consensual sexual touching (except in
                  real-world art depicting non-real people, with a condemning or
                  neutral caption)
                </li>
                <li>
                  Statements attempting or threatening to share, offering or
                  asking for imagery depicting non-consensual sexual touching
                </li>
                <li>
                  Descriptions of non-consensual sexual touching, unless shared
                  by or in support of the survivor
                </li>
                <li>
                  Advocacy (including aspirational and conditional statements)
                  for, threats to commit or admission of participation in
                  non-consensual sexual touching
                </li>
                <li>
                  Content mocking survivors or the concept of non-consensual
                  sexual touching
                </li>
                <li>
                  Content shared by a third party that identifies survivors of
                  sexual assault when reported by the survivor
                </li>
              </ul>
              <p>Content that attempts to exploit people by:</p>
              <ul className="disc">
                <li>
                  Coercing money, favours or intimate imagery from people with
                  threats to expose their intimate imagery or intimate
                  information (sextortion)
                </li>
                <li>
                  Sharing, threatening, stating an intent to share, offering or
                  asking for non-consensual intimate imagery (NCII) that fulfils
                  all of the three following conditions:
                </li>
                <ol className="disc">
                  <li>
                    Imagery is non-commercial and produced in a private setting.
                  </li>
                  <li>
                    Person in the imagery is (near-)nude, engaged in sexual
                    activity or in a sexually suggestive pose.
                  </li>
                  <li>
                    Lack of consent to share the imagery is indicated by meeting
                    any of the signals:
                  </li>
                  <ol className="disc">
                    <li>
                      Vengeful context (such as caption, comments or Page
                      title).
                    </li>
                    <li>
                      Independent sources such as law enforcement records, media
                      reports (such as leaks of images confirmed by media) or
                      representatives of a survivor of NCII
                    </li>
                    <li>
                      Report from a person depicted in the image or who shares
                      the same name as the person depicted in the image.
                    </li>
                  </ol>
                </ol>
                <li>
                  Promoting, threatening to share or offering to make non-real
                  non-consensual intimate imagery (NCII) either by applications,
                  services or instructions, even if there is no (near) nude
                  commercial or non-commercial imagery shared in the content
                </li>
                <li>
                  Secretly taking non-commercial imagery of a person's commonly
                  sexualised body parts (breasts, groin, buttocks or thighs) or
                  of a person engaged in sexual activity. This imagery is
                  commonly known as "creepshots" or "upskirts" and includes
                  photos or videos that mock, sexualise or expose the person
                  depicted in the imagery.
                </li>
                <li>
                  Sharing, threatening to share or stating an intent to share
                  private sexual conversations where a lack of consent to share
                  is indicated by any of the following:
                </li>
                <ul className="disc">
                  <li>Vengeful context and/or threatening context,</li>
                  <li>
                    Independent sources such as media coverage or law
                    enforcement records, or
                  </li>
                  <li>
                    Report from a person depicted in the image or who shares the
                    same name as the person depicted in the image
                  </li>
                </ul>
              </ul>
              <p>
                Content relating to necrophilia or forced stripping, including:
              </p>
              <ul className="disc">
                <li>
                  Imagery depicting necrophilia or forced stripping (except in
                  real-world art depicting non-real people, with a condemning or
                  neutral caption
                </li>
                <li>
                  Statements attempting to share, offer, ask or threatening to
                  share the imagery of necrophilia or forced stripping
                </li>
                <li>
                  Statements that contain descriptions, advocacy for,
                  aspirational or conditional statements about, statements of
                  intent or calls for action to commit, admission of
                  participation in, or mocking of survivors of necrophilia or
                  forced stripping
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following content, we include a sensitivity screen so
                that people are aware that the content may be upsetting to some:
              </p>
              <p>
                Narratives and statements that contain a description of
                non-consensual sexual touching (written or verbal) that includes
                details beyond mere naming or mentioning the act if:
              </p>
              <ul className="disc">
                <li>Shared by the survivor, or</li>
                <li>
                  Shared by a third party (other than the survivor) in support
                  of the survivor or condemnation of the act or for general
                  awareness to be determined by context/caption.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <p>
                We may restrict visibility to people over the age of 18 and
                include a warning label on certain content including:
              </p>
              <ul className="disc">
                <li>Content depicting non-consensual sexual touching when:</li>
                <ol className="disc">
                  <li>
                    Shared to raise awareness (without entertainment or
                    sensational context),
                  </li>
                  <li>The survivor is not identifiable, and</li>
                  <li>The content does not involve nudity</li>
                </ol>
                <li>
                  Content depicting fictional non-consensual sexual touching
                  (film trailers, etc.) when shared by trusted partners to raise
                  awareness and without sensational context
                </li>
              </ul>
              <p>
                We may restrict visibility to people over the age of 18 and
                include a warning label on certain content depicting
                non-consensual sexual touching, when it is shared to raise
                awareness and without entertainment or sensational context,
                where the victim or survivor is not identifiable and where the
                content does not involve nudity.
              </p>
              <p>
                In addition to our at-scale policy of removing content that
                threatens or advocates rape or other non-consensual sexual
                touching, we may also disable the posting account.
              </p>
              <p>
                We may also enforce on content shared by a third party that
                identifies survivors of sexual assault when reported by an
                authorised representative or trusted partner.
              </p>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Bullying and harassment:
              </h1>
              <p className="font-normal text-xl mt-5">Policy Rationate</p>
              <p>
                Bullying and harassment happen in many places and come in many
                different forms, from making threats and releasing personally
                identifiable information to sending threatening messages and
                making unwanted malicious contact. We do not tolerate this kind
                of behaviour because it prevents people from feeling safe and
                respected on Twigyy, Instagram and Threads.
              </p>
              <p>
                We distinguish between public figures and private individuals
                because we want to allow discussion, which often includes
                critical commentary of people who are featured in the news or
                who have a large public audience. For public figures, we remove
                attacks that are severe, as well as certain attacks where the
                public figure is directly tagged in the post or comment. We
                define public figures as state- and national-level government
                officials, political candidates for those offices, people with
                over one million fans or followers on social media and people
                who receive substantial news coverage.
              </p>
              <p>
                For private individuals, our protection goes further: We remove
                content that's meant to degrade or shame, including, for
                example, claims about someone's sexual activity. We recognise
                that bullying and harassment can have more of an emotional
                impact on minors, which is why our policies provide heightened
                protection for anyone under the age of 18, regardless of user
                status.
              </p>
              <p>
                Context and intent matter, and we allow people to post and share
                if it is clear that something was shared in order to condemn or
                draw attention to bullying and harassment. In certain instances,
                we require self-reporting because it helps us understand that
                the person targeted feels bullied or harassed. In addition to
                reporting such behaviour and content, we encourage people to use
                tools available on our platforms to help protect against it.
              </p>
              <p>
                We also have a Bullying Prevention Hub, which is a resource for
                teenagers, parents and educators seeking support for issues
                related to bullying and other conflicts. It offers step-by-step
                guidance, including information on how to start important
                conversations about bullying. Learn more about what we are doing
                to protect people from bullying and harassment here.
              </p>
              <p>
                Note: This policy does not apply to individuals who are part of
                designated organisations under the Dangerous Organisations and
                Individuals Policy or individuals who died prior to 1900.
              </p>
              <h1 className=" font-medium text-xl mt-5">
                Tier 1: Universal protections for everyone:
              </h1>
              <ul className="disc">
                <li>Everyone is protected from:</li>
                <ol className="disc">
                  <li>Unwanted contact this from:</li>
                  <ol className="disc">
                    <li>Repeated, OR</li>
                    <li>Sexually harrassing, OR</li>
                    <li>
                      Is directed at a large number of individuals with no prior
                      solicitation.
                    </li>
                  </ol>
                  <li>
                    Calls for self-injury or suicide of a specific person, or
                    group of individuals.
                  </li>
                  <li>
                    Attacks based on their experience of sexual assault, sexual
                    exploitation, sexual harassment or domestic abuse.
                  </li>
                  <li>
                    Statements of intent to engage in a sexual activity or
                    advocating to engage in a sexual activity.
                  </li>
                  <li>Severe sexualised commentary.</li>
                  <li>Derogatory sexualised photoshop or drawings</li>
                  <li>
                    Attacks through derogatory terms related to sexual activity
                    (for example: whore, slut).
                  </li>
                  <li>Claims that a violent tragedy did not occur.</li>
                  <li>
                    Claims that individuals are lying about being a victim of a
                    violent tragedy or terrorist attack, including claims that
                    they are:
                  </li>
                  <ol className="disc">
                    <li>
                      Acting or pretending to be a victim of a specific event,
                      or
                    </li>
                    <li>
                      Paid or employed to mislead people about their role in the
                      event.
                    </li>
                  </ol>
                  <li>
                    Threats to release an individual's private phone number,
                    residential address, email address or medical records (as
                    defined in the Privacy Violations policy).
                  </li>
                  <li>
                    Calls for, or statements of intent to engage in, bullying
                    and/or harassment.
                  </li>
                  <li>
                    Content that degrades or expresses disgust towards
                    individuals who are depicted in the process of, or straight
                    after, menstruating, urinating, vomiting or defecating
                  </li>
                  <li>
                    Everyone is protected from the following, but for adult
                    public figures, they must be purposefully exposed to:
                  </li>
                  <ol className="disc">
                    <li>
                      Calls for death and statements in favour of contracting or
                      developing a medical condition.
                    </li>
                    <li>
                      Celebration or mocking of death or medical condition.
                    </li>
                    <li>Claims about sexually transmitted infections.</li>
                    <li>
                      Derogatory terms related to female gendered cursing.
                    </li>
                    <li>
                      Statements of inferiority about physical appearance.
                    </li>
                  </ol>
                </ol>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Tier 2: Additional protections for all minors, private adults
                and limited scope public figures (for example, individuals whose
                primary fame is limited to their activism or journalism, or
                those who become famous through involuntary means):
              </h1>
              <ul className="disc">
                <li>
                  In addition to the universal protections for everyone, all
                  minors (private individuals and public figures), private
                  adults and limited scope public figures are protected from:
                </li>
                <ol className="disc">
                  <li>
                    Claims about sexual activity, except in the context of
                    criminal allegations against adults (non-consensual sexual
                    touching).
                  </li>
                  <li>
                    Claims about sexual activity, except in the context of
                    criminal allegations against adults (non-consensual sexual
                    touching).
                  </li>
                  <li>
                    Content sexualising another adult (sexualisation of minors
                    is covered in the Child Sexual Exploitation, Abuse and
                    Nudity policy).
                  </li>
                </ol>
                <li>
                  All minors (private individuals and public figures), private
                  adults and limited scope public figures) are protected from
                  the following, but for minor public figures, they must be
                  purposefully exposed to:
                </li>
                <ol className="disc">
                  <li>
                    Dehumanising comparisons (in written or visual form) to or
                    about:
                  </li>
                  <ol className="disc">
                    <li>
                      Animals and insects, including subhuman creatures, that
                      are culturally perceived as inferior.
                    </li>
                    <li>Bacteria, viruses, microbes and diseases.</li>
                    <li>
                      Inanimate objects, including rubbish, filth, faeces.
                    </li>
                  </ol>
                  <li>
                    Content manipulated to highlight, circle or otherwise
                    negatively draw attention to specific physical
                    characteristics (nose, ear and so on).
                  </li>
                  <li>
                    {" "}
                    Content that ranks them based on physical appearance or
                    character traits.
                  </li>
                  <li>
                    Content that degrades individuals who are depicted being
                    physically bullied (except in self-defence and fight-sport
                    contexts).
                  </li>
                </ol>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Tier 3: Additional protections for private minors, private
                adults and minor involuntary public figures:
              </h1>
              <ul className="disc">
                <li>
                  In addition to all of the protections listed above, all
                  private minors, private adults (who must self-report) and
                  minor involuntary public figures are protected from:
                </li>
                <ol>
                  <li>Targeted cursing.</li>
                  <li>
                    Claims about romantic involvement, sexual orientation or
                    gender identity.
                  </li>
                  <li>
                    Calls for action, statements of intent, aspirational or
                    conditional statements, or statements advocating or
                    supporting exclusion.
                  </li>
                  <li>
                    Negative character or ability claims, except in the context
                    of criminal allegations and business reviews against adults.
                  </li>
                  <li>
                    Expressions of contempt or disgust, or content rejecting the
                    existence of an individual, except in the context of
                    criminal allegations against adults.
                  </li>
                </ol>
                <li>
                  When self-reported, private minors, private adults and minor
                  involuntary public figures are protected from the following:
                </li>
                <ol className="disc">
                  <li>First-person voice bullying.</li>
                  <li>Unwanted manipulated imagery.</li>
                  <li>
                    Comparison to other public, fictional or private individuals
                    on the basis of physical appearance.
                  </li>
                  <li>Claims about religious identity or blasphemy</li>
                  <li>
                    Comparisons to animals or insects that are not culturally
                    perceived as intellectually or physically inferior ("tiger",
                    "lion").
                  </li>
                  <li>Neutral or positive physical descriptions.</li>
                  <li>Non-negative character or ability claims.</li>
                  <li>
                    Attacks through derogatory terms related to a lack of sexual
                    activity.
                  </li>
                </ol>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Tier 4: Additional protections for private minors only:
              </h1>
              <ul className="disc">
                <li>
                  Minors get the most protection under our policy. In addition
                  to all of the protections listed above, private minors are
                  also protected from:
                </li>
                <ol className="disc">
                  <li>Allegations about criminal or illegal behaviour.</li>
                  <li>
                    Videos of physical bullying against minors, shared in a
                    non-condemning context.
                  </li>
                  <li>
                    Videos of physical bullying against minors, shared in a
                    condemning context, where we add a cover so people can
                    choose whether to see it.
                  </li>
                </ol>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Bullying and harassment through pages, groups, events and
                messages
              </h1>
              <ul className="disc">
                <li>
                  The protections of Tiers 1 to 4 are also enforced on Pages,
                  groups, events and messages.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <p>Do not:</p>
              <ul className="disc">
                <li>
                  Post content that targets private individuals through unwanted
                  Pages, groups and events. We remove this content when it is
                  reported by the target or an authorised representative of the
                  target.
                </li>
                <li>
                  Post content described above that would otherwise require the
                  target to report the content or where the content indicates
                  that the poster is directly targeting the target (for example,
                  the target is tagged in the post or comment). We will remove
                  this content if we have confirmation from the target or an
                  authorised representative of the target (alive or deceased)
                  that the content is unwanted.
                </li>
                <li>
                  Post content calling for or stating an intent to engage in
                  behaviour that would qualify as bullying and harassment under
                  our policies. We will remove this content when we have
                  confirmation from the target or an authorised representative
                  of the target that the content is unwanted.
                </li>
                <li>
                  Post content sexualising a public figure. We will remove this
                  content when we have confirmation from the target or an
                  authorised representative of the target that the content is
                  unwanted.
                </li>
                <li>
                  Initiate contact that is sexually harassing the recipient. We
                  will remove any content shared in an unwanted context when we
                  have a confirmation from the recipient, or an authorised
                  representative of the recipient that contact is unwanted.
                </li>
                <li>Remove directed mass harassment, when:</li>
                <ol className="disc">
                  <li>
                    Targeting, via any surface, "individuals at heightened risk
                    of offline harm", defined as:
                  </li>
                  <ol>
                    <li>Human rights defenders</li>
                    <li>Minors</li>
                    <li>Victims of violent events/tragedies</li>
                    <li>
                      Opposition figures in at-risk countries during election
                      periods
                    </li>
                    <li>Election officials</li>
                    <li>
                      Government dissidents who have been targeted based on
                      their dissident status
                    </li>
                    <li>Ethnic and religious minorities in conflict zones</li>
                    <li>
                      Member of a designated and recognisable at-risk group
                    </li>
                  </ol>
                  <li>
                    Targeting any individual via personal surfaces, such as
                    inbox or profiles, with:
                  </li>
                  <ol className="disc">
                    <li>
                      Content that violates the bullying and harassment policies
                      for private individuals, or
                    </li>
                    <li>
                      Objectionable content that is based on a protected
                      characteristic
                    </li>
                  </ol>
                </ol>
                <li>
                  Disable accounts engaged in mass harassment as part of either
                </li>
                <ol className="disc">
                  <li>
                    State or state-affiliated networks targeting any individual
                    via any surface.
                  </li>
                  <li>
                    Adversarial networks targeting any individual via any
                    surface with:
                  </li>
                  <ol className="disc">
                    <li>
                      Content that violates the bullying and harassment policies
                      for private individuals, or
                    </li>
                    <li>
                      Content that targets them based on a protected
                      characteristic, or
                    </li>
                    <li>
                      Content or behaviour otherwise deemed to be objectionable
                      in local context
                    </li>
                  </ol>
                </ol>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Child sexual exploitation, abuse and nudity:
              </h1>
              <p className="font-normal text-xl">Policy rationale</p>
              <p>
                We do not allow content or activity that sexually exploits or
                endangers children. When we become aware of apparent child
                exploitation, we report it to the National Center for Missing
                and Exploited Children (NCMEC), in compliance with applicable
                law. We know that sometimes, people share nude images of their
                own children with good intentions; however, we generally remove
                these images because of the potential for abuse by others and to
                help avoid the possibility of other people re-using or
                misappropriating the images.
              </p>
              <p>
                We also work with external experts, including the Twigyy Safety
                Advisory Board, to discuss and improve our policies and
                enforcement around online safety issues, especially with regard
                to children. Learn more about the technology we're using to
                fight against child exploitation.
              </p>
              <p className="font-normal text-xl">Do not post:</p>
              <h1 className=" font-medium text-xl mt-5">
                Child sexual exploitation
              </h1>
              <p>
                Content, activity or interactions that threaten, depict, praise,
                support, provide instructions for, make statements of intent,
                admit participation in or share links of the sexual exploitation
                of children (including real minors, toddlers or babies, or
                non-real depictions with a human likeness, such as in art,
                AI-generated content, fictional characters, dolls etc.). This
                includes, but is not limited to,
              </p>
              <ul className="disc">
                <li>Sexual intercourse:</li>
                <ol className="disc">
                  <li>
                    Explicit sexual intercourse or oral sex, defined as mouth or
                    genitals entering or in contact with another person's
                    genitals or anus, when at least one person's genitals or
                    anus is visible.
                  </li>
                  <li>
                    Implied sexual intercourse or oral sex, including when
                    contact is imminent or not directly visible.
                  </li>
                  <li>
                    Stimulation of genitals or anus, including when activity is
                    imminent or not directly visible.
                  </li>
                  <li>Any of the above involving an animal.</li>
                </ol>
                <li>
                  Children with sexual elements, including, but not limited to,
                </li>
                <ol className="disc">
                  <li>Restrain</li>
                  <li>Signs of arousal</li>
                  <li>Focus on genitals or anus</li>
                  <li>Presence of aroused adult</li>
                  <li>
                    Presence of sex toys or use of any object for sexual
                    stimulation, gratification or sexual abuse
                  </li>
                  <li>Sexualised costume</li>
                  <li>Stripping</li>
                  <li>
                    Staged environment (for example, on a bed) or professionally
                    shot (quality/focus/angles)
                  </li>
                  <li>Open-mouth kissing</li>
                  <li>
                    Stimulation of human nipples or squeezing of female breast
                    (EXCEPT in the context of breastfeeding)
                  </li>
                  <li>Presence of by-products of sexual activity</li>
                </ol>
                <li>Content involving children in a sexual fetish context</li>
                <li>
                  Content that supports, promotes, advocates or encourages
                  participation in paedophilia, unless it is discussed neutrally
                  in a health context
                </li>
                <li>
                  Content that identifies or mocks alleged victims of child
                  sexual exploitation by name or image
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">Solicitation</h1>
              <ul className="disc">
                <li>
                  Content that solicits sexual content or activity depicting or
                  involving children, defined as:
                </li>
                <ol className="disc">
                  <li>Child sexual abuse material (CSAM)</li>
                  <li>Nude imagery of real or non-real children</li>
                  <li>Sexualised imagery of real or non-real children</li>
                </ol>
              </ul>
              <p>Content that solicits sexual encounters with children.</p>
              <h1 className=" font-medium text-xl mt-5">
                Inappropriate interactions with children
              </h1>
              <p>
                Content that constitutes or facilitates inappropriate
                interactions with children, such as:
              </p>
              <ul className="disc">
                <li>
                  Purposefully exposing children to sexually explicit language
                  or sexual material
                </li>
                <li>
                  Purposefully exposing children to sexually explicit language
                  or sexual material
                </li>
                <li>
                  Engaging in implicitly sexual conversations in private
                  messages with children
                </li>
                <li>
                  Obtaining or requesting sexual material from children in
                  private messages
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Exploitative intimate imagery and sextortion
              </h1>
              <p>Content that attempts to exploit real children by:</p>
              <ul className="disc">
                <li>
                  Coercing money, favours or intimate imagery with threats to
                  expose intimate imagery or information
                </li>
                <li>
                  Sharing, threatening or stating an intent to share private
                  sexual conversations or intimate imagery
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Sexualisation of children
              </h1>
              <ul className="disc">
                <li>
                  Content (including photos, videos, real-world art, digital
                  content and verbal depictions) that sexualises real or
                  non-real children
                </li>
                <li>
                  Groups, Pages and profiles dedicated to sexualising real or
                  non-real children
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">Child nudity</h1>
              <p>
                Content that depicts real or non-real child nudity where nudity
                is defined as:
              </p>
              <ul className="disc">
                <li>Close-ups of real or non-real children's genitalia</li>
                <li>Real or non-real nude toddlers, showing:</li>
                <ol className="disc">
                  <li>
                    Visible genitalia, even when covered or obscured by
                    transparent clothing
                  </li>
                  <li>Visible anus and/or fully nude close-up of buttocks</li>
                </ol>
                <li>Real or non-real nude minors, showing:</li>
                <ol className="disc">
                  <li>
                    Visible genitalia (including genitalia obscured only by
                    pubic hair or transparent clothing)
                  </li>
                  <li>Visible anus and/or fully nude close-up of buttocks</li>
                  <li>Uncovered female nipples</li>
                  <li>
                    No clothes from neck to knee – even if no genitalia or
                    female nipples are showing
                  </li>
                </ol>
                <li>
                  Unless the non-real imagery is for health purposes or is a
                  non-sexual depiction of child nudity in real-word art
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Non-sexual child abuse
              </h1>
              <p>
                Videos or photos that depict real or non-real non-sexual child
                abuse regardless of sharing intent, unless the imagery is from
                real-world art, cartoons, films or video games
              </p>
              <p>
                Content that praises, supports, promotes, advocates for,
                provides instructions for or encourages participation in
                non-sexual child abuse
              </p>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Human exploitation:
              </h1>
              <p className="font-normal text-xl">Policy rationale</p>
              <p>
                In an effort to disrupt and prevent harm, we remove content that
                facilitates or coordinates the exploitation of humans, including
                human trafficking. We define human trafficking as the business
                of depriving someone of liberty for profit. It is the
                exploitation of humans in order to force them to engage in
                commercial sex, labour or other activities against their will.
                It relies on deception, force and coercion, and degrades humans
                by depriving them of their freedom while economically or
                materially benefiting others.
              </p>
              <p>
                Human trafficking is multi-faceted and global; it can affect
                anyone regardless of age, socioeconomic background, ethnicity,
                gender or location. It takes many forms, and any given
                trafficking situation can involve various stages of development.
                Due to the coercive nature of this abuse, victims cannot
                consent.
              </p>
              <p>
                While we need to be careful not to conflate human trafficking
                and smuggling, they can be related and exhibit overlap. The
                United Nations defines human smuggling as the procurement or
                facilitation of illegal entry into a state across international
                borders. Without necessity for coercion or force, it may still
                result in the exploitation of vulnerable individuals who are
                trying to leave their country of origin, often in pursuit of a
                better life. Human smuggling is a crime against a state, relying
                on movement, and human trafficking is a crime against a person,
                relying on exploitation.
              </p>
              <p>
                In addition to content condemning, raising awareness about, or
                news reporting on human trafficking or human smuggling issues,
                we allow content asking for or sharing information about
                personal safety and border crossing, seeking asylum or how to
                leave a country.
              </p>
              <p className="font-normal text-xl">Do not post</p>
              <p>
                Content that recruits people for, facilitates or exploits people
                through any of the following forms of human trafficking:
              </p>
              <ul className="disc">
                <li>
                  Sex trafficking (any commercial sexual activity with a minor
                  or any commercial sexual activity with an adult involving
                  force, fraud or coercion)
                </li>
                <li>Sales of children or illegal adoption</li>
                <li>Orphanage trafficking and orphanage voluntourism</li>
                <li>Forced marriages</li>
                <li>Labour exploitation (including bonded labour)</li>
                <li>Domestic servitude</li>
                <li>
                  Non-regenerative organ trafficking not including organ
                  removal, donation or transplant in a non-exploitative organ
                  donation context
                </li>
                <li>
                  Forced criminal activity (e.g. forced begging, forced drug
                  trafficking)
                </li>
                <li>Recruitment of child soldiers</li>
              </ul>
              <p>
                Content where a third-party actor recruits for, facilitates or
                benefits from (financially or otherwise) commercial sexual
                activity.
              </p>
              <p>
                Content that offers to provide or facilitate human smuggling.
              </p>
              <p>Content that asks for human smuggling services.</p>
              <p>
                We allow content that is otherwise covered by this policy when
                posted in condemnation, educational, awareness-raising, or news
                reporting contexts.
              </p>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Privacy violations
              </h1>
              <p className="font-normal text-xl">Policy rationale</p>
              <p>
                Privacy and the protection of personal information are
                fundamentally important values for Twigyy. We work hard to
                safeguard your personal identity and information, and we do not
                allow people to post personal or confidential information about
                yourself or others.
              </p>
              <p>
                We remove content that shares, offers, or solicits personally
                identifiable information or other private information that could
                lead to physical or financial harm, including financial,
                residential, and medical information, as well as private
                information obtained from illegal sources. We also recognise
                that private information may become publicly available through
                news coverage, court filings, press releases, or other sources.
                When that happens, we may allow the information to be posted.
              </p>
              <p>
                We also provide people with ways to report imagery that they
                believe to be in violation of their privacy rights.
              </p>
              <p className="font-normal text-xl">Do not post</p>
              <p>
                Content that shares or solicits any of the following private
                information, either on Twigyy or through external links:{" "}
              </p>
              <h1 className=" font-medium text-xl mt-5">
                Personally identifiable information about yourself or others
              </h1>
              <ul className="disc">
                <li>
                  Personal identification numbers and identity documents:
                  identifying individuals by name and government-issued numbers,
                  including:
                </li>
                <ol className="disc">
                  <li>
                    National identification numbers (for example, social
                    security numbers (SSN), passport numbers, national
                    insurance/health service numbers, personal public service
                    numbers (PPS), individual taxpayer identification numbers
                    (ITIN)).
                  </li>
                  <li>
                    Government IDs of law enforcement, military or security
                    personnel.
                  </li>
                  <li>
                    Records or official documentation of civil registry
                    information (for example, marriage, birth, death, name
                    change or gender recognition documents, except student IDs)
                  </li>
                  <li>
                    Immigration and work status documents (e.g. green cards,
                    work permits, visas or immigration papers)
                  </li>
                  <li>
                    Driving licences or registration plates, except when
                    registration plates are shared to facilitate finding missing
                    vehicles, people or animals
                  </li>
                  <li>Credit privacy numbers (CPNs)</li>
                </ol>
                <li>
                  Digital identity: Passwords, pins or codes (such as passwords
                  of email addresses, social media accounts and accounts of
                  streaming services) that authenticate access to an online
                  identity
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Personal contact information
              </h1>
              <ul className="disc">
                <li>
                  Personal contact information of others, including personal
                  phone numbers and personal email addresses (including work
                  email addresses), except when made public by the individual or
                  when shared or solicited to promote charitable causes,
                  facilitate finding missing people, animals or owners of
                  missing objects, or contact a business or service providers
                  (unless it is established that the personal contact
                  information is shared without the consent of the individual).
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Financial information.
              </h1>
              <ul className="disc">
                <li>
                  Personal financial information about yourself or others,
                  including
                </li>
                <ol className="disc">
                  <li>Non-public financial records or statements.</li>
                  <li>Bank account numbers with security or PIN codes.</li>
                  <li>
                    Digital payment method information with login details,
                    security or PIN codes.
                  </li>
                  <li>
                    Credit or debit card information with validity dates or
                    security PINs or codes.
                  </li>
                </ol>
                <li>
                  Financial information about businesses or organisations,
                  except when originally shared by the organisation itself
                  (including subsequent shares with the original context intact)
                  or shared through public reporting requirements (e.g. as
                  required by stock exchanges or regulatory agencies),
                  including:
                </li>
                <ol className="disc">
                  <li>Non-public financial records or statements</li>
                  <li>
                    Bank account numbers accompanied by security or PIN codes.
                  </li>
                  <li>
                    Digital payment method information accompanied by login
                    details, security or PIN codes.
                  </li>
                </ol>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Residential information
              </h1>
              <ul className="disc">
                <li>
                  Private residential addresses of others (except when the
                  residence is an official residence or embassy provided to a
                  high-ranking public official)
                </li>
                <li>
                  Full private residential addresses of others, including
                  building name, GPS pins or pins on a map identifying the
                  address (even if the pins are in an off-platform link), except
                  when shared to promote charitable causes, facilitate finding
                  missing people, animals or owners of missing objects, or
                  contact a business or service providers
                </li>
                <li>Partial private residential addresses of others:</li>
                <ol className="disc">
                  <li>
                    Partial private residential addresses of others when shared
                    in the context of organising protests or surveillance of the
                    resident and the location of the residence is identified by
                    any one of the following:
                  </li>
                  <li>Street</li>
                  <li>
                    Town/city or neighbourhood (only for towns/cities with fewer
                    than 50,000 residents)
                  </li>
                  <li>Postal code</li>
                  <li>
                    GPS pins or pins on a map identifying any of these (even if
                    the pins are in an off-platform link)
                  </li>
                </ol>
                <li>The content identifies the resident(s).</li>
                <li>
                  Either that resident objects to the exposure of their private
                  residence, or there is context of organising protests against
                  the resident.
                </li>
                <li>
                  The imagery of the residence is not being shared because the
                  residence is the focus of a news story, except when shared in
                  the context of organising protests against the resident
                </li>
                <li>
                  Location of safe houses: content that exposes information
                  about safe houses by sharing any of the below, except when the
                  safe house is actively promoting information about their
                  facility
                </li>
                <ol className="disc">
                  <li>Actual address (note: "Post Box only" is allowed).</li>
                  <li>Images of the safe house.</li>
                  <li>Identifiable city/neighbourhood of the safe house.</li>
                  <li>
                    Information exposing the identity of the safe house
                    residents.
                  </li>
                </ol>
              </ul>
              <ul className="disc">
                <li>
                  Content sharing medical, psychological, biometric or genetic
                  hereditary information of others when it is clear that the
                  information comes from medical records or other official
                  documents, including when displayed visually or shared through
                  audio or video.
                </li>
              </ul>
              <h1 className=" font-medium text-xl mt-5">
                Information obtained from hacked sources
              </h1>
              <ul className="disc">
                <li>
                  Except in limited cases of newsworthiness, content claimed or
                  confirmed to come from a hacked source, regardless of whether
                  the affected person is a public figure or a private
                  individual.
                </li>
                <p>The following content also may be removed:</p>
                <ol className="disc">
                  <li>
                    A reported photo or video of people where the person
                    depicted in the image is:
                  </li>
                  <li>
                    A minor under the age of 13, and the content was reported by
                    the minor or a parent or legal guardian.
                  </li>
                  <li>
                    A minor between the ages of 13 and 18 years old, and the
                    content was reported by the minor.
                  </li>
                  <li>
                    An adult, where the content was reported by the adult from
                    outside India and applicable law may provide rights to
                    removal.
                  </li>
                  <li>
                    Any person who is incapacitated and unable to report the
                    content on their own.
                  </li>
                </ol>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <ul className="disc">
                <li>
                  Depictions of an individual in a medical or health facility,
                  or a private individual or minor entering or exiting a medical
                  or health facility if reported by the person pictured or an
                  authorised representative, or the medical or health facility.
                </li>
                <li>
                  Source material that purports to reveal non-public information
                  relevant to an election shared as part of a foreign government
                  influence operation.
                </li>
                <ol className="disc">
                  <li>
                    We remove reporting on such a leak by state-controlled media
                    entities from the country behind the leak.
                  </li>
                </ol>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Suicide, self-injury and eating disorders:
              </h1>
              <p className="font-normal text-xl mt-5">Policy Rationate</p>
              <p>
                We care deeply about the safety of the people who use our apps.
                We regularly consult with experts in suicide, self-injury, and
                eating disorders to help inform our policies and enforcement,
                and work with organisations around the world to provide
                assistance to people in distress.
              </p>

              <p>
                While we do not allow people to intentionally or unintentionally
                celebrate or promote suicide, self-injury, or eating disorders,
                we do allow people to discuss these topics because we want
                Twigyy to be a space where people can share their experiences,
                raise awareness about these issues, and seek support from one
                another.
              </p>

              <p>
                We remove any content that encourages suicide, self-injury, or
                eating disorders, including fictional content such as memes or
                illustrations and any self-injury content which is graphic,
                regardless of context. We also remove content that mocks victims
                or survivors of suicide, self-injury, or eating disorders, as
                well as real-time depictions of suicide or self-injury.Content
                about recovery from suicide, self-injury, or eating disorders
                that is allowed, but may contain imagery that could be
                upsetting, such as a healed scar, is placed behind a sensitivity
                screen:
              </p>

              <p>
                Sensitivity Screen: Some content may be upsetting. Viewer
                discretion is advised.
              </p>

              <p>
                When people post or search for suicide, self-injury, or eating
                disorders-related content, we will direct them to local
                organisations that can provide support. If our Community
                Operations team is concerned about immediate harm, we will
                contact local emergency services to get them help. For more
                information, visit the Twigyy Safety Centre.
              </p>

              <p>
                With respect to live content, experts have told us that if
                someone is saying they intend to attempt suicide on a
                live-stream, we should leave the content up for as long as
                possible, because the longer someone is talking to a camera, the
                more opportunity there is for a friend or family member to call
                emergency services.
              </p>

              <p>
                However, to minimise the risk of others being negatively
                affected by viewing this content, we will stop the live-stream
                at the point at which the threat turns into an attempt. As
                mentioned above, in any case, we will contact the emergency
                services if we identify that someone is at immediate risk of
                harming themselves.
              </p>
              <p className="font-normal text-xl mt-5">Do not post</p>
              <p>
                Content that promotes, encourages, coordinates or provides
                instructions for suicide, self-injury or eating disorders.
              </p>
              <ul className="disc">
                <li>
                  Content that promotes, encourages, coordinates or provides
                  instructions for suicide, self-injury or eating disorders.
                </li>
                <li>
                  Content depicting a person who engaged in a suicide attempt or
                  death by suicide
                </li>
                <li>
                  Content that focuses on depiction of ribs, collar bones, thigh
                  gaps, hips, concave stomach or protruding spine or scapula
                  when shared together with terms associated with eating
                  disorders
                </li>
                <li>
                  Content that contains instructions for drastic and unhealthy
                  weight loss when shared together with terms associated with
                  eating disorders
                </li>
                <li>
                  Content that mocks victims or survivors of suicide,
                  self-injury or eating disorders who are either publicly known
                  or implied to have experienced suicide or self-injury
                </li>
                <li>
                  Imagery depicting body modification (e.g. tattoo, piercing,
                  scarification, self-flagellation etc.) when shared in a
                  suicide or self-injury context
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following content, we include a warning screen so that
                people are aware the content may be sensitive. We also limit the
                ability to view the content to adults aged 18 and older:
              </p>
              <ul className="disc">
                <li>
                  Photos or videos depicting a person who engaged in
                  euthanasia/assisted suicide in a medical setting.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following content, we include a label so that people are
                aware that the content may be sensitive:
              </p>
              <ul className="disc">
                <li>
                  Content that depicts older instances of self-harm such as
                  healed cuts or other non-graphic self-injury imagery in a
                  self-injury, suicide or recovery context.
                </li>
                <li>
                  Content that depicts ribs, collar bones, thigh gaps, hips,
                  concave stomach or protruding spine or scapula in a recovery
                  context.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                We provide resources to people who post written or verbal
                admissions of engagement in self-injury, including:
              </p>
              <ul className="disc">
                <li>Sucide</li>
                <li>Enthaniasia/assisted suicide</li>
                <li>Self-harm</li>
                <li>Eating disorder</li>
                <li>
                  Vague, potentially suicidal statements or references
                  (including memes or stock imagery about sad mood or
                  depression) in a suicide or self-injury context.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <ul className="disc">
                <li>
                  We may remove suicide notes when we have confirmation of a
                  suicide or suicide attempt. We try to identify suicide notes
                  using several factors, including but not limited to:
                </li>
                <ol className="disc">
                  <li>Family or legal representative requests,</li>
                  <li>
                    Reports from media, law enforcement or other third-party
                    sources (e.g. government agencies, NGOs) or the Suicidal
                    content contact form or Instagram contact form.
                  </li>
                </ol>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Adult nudity and sexual activity:
              </h1>
              <p className="font-normal text-xl mt-5">Policy rationate</p>
              <p>
                We restrict the display of nudity or sexual activity because
                some people in our community may be sensitive to this type of
                content. In addition, we default to removing sexual imagery to
                prevent the sharing of non-consensual or underage content.
                Restrictions on the display of sexual activity also apply to
                digitally created content unless it is posted for educational,
                humorous or satirical purposes.
              </p>
              <p>
                Our Nudity Policies have become more nuanced over time. We
                understand that nudity can be shared for a variety of reasons,
                including as a form of protest, to raise awareness about a cause
                or for educational or medical reasons.
              </p>
              <p>
                Where such intent is clear, we make allowances for the content.
                For example, while we restrict some images of female breasts
                that include the nipple, we allow other images, including those
                depicting acts of protest, women actively engaged in
                breast-feeding and photos of post-mastectomy scarring. For
                images depicting visible genitalia or the anus in the context of
                birth and after-birth moments or health-related situations, we
                include a warning label so that people are aware that the
                content may be sensitive. We also allow photographs of
                paintings, sculptures and other art that depicts nude figures.
              </p>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                We do not allow
              </h1>
              <ul className="disc">
                <li>Imagery of adult nudity, if it depicts:</li>
                <ol className="disc">
                  <li>
                    Visible genitalia except in medical or health context (for
                    example, birth-giving and after-birth moments, gender
                    confirmation surgery,{" "}
                  </li>
                  <li>
                    Visible anuses and/or fully nude close-ups of buttocks
                    unless photoshopped on a public figure
                  </li>
                  <li>
                    Uncovered female nipples, except in the context of
                    breastfeeding, mastectomy, medical or health context (for
                    example, birth-giving and after-birth moments, gender
                    confirmation surgery,{" "}
                  </li>
                  <li>
                    Note that we allow all of the above in the context of
                    famine, genocide, war crimes or crimes against humanity
                  </li>
                </ol>
                <li>Imagery of adult sexual activity, including:</li>
                <ol className="disc">
                  <li>
                    Explicit sexual intercourse or oral sex, as indicated by a
                    person's mouth or genitals entering or in contact with
                    another person's genitals or anus, when at least one
                    person's genitals or anus is visible
                  </li>
                  <li>
                    Explicit stimulation of a person's genitals or anus, as
                    indicated by stimulation, or the insertion of sex toys into
                    the person's genitals or anus, when the contact with the
                    genitals or anus is directly visible
                  </li>
                </ol>
                <li>
                  Implicit sexual activity and stimulation, except in medical,
                  health or sexual wellness contexts; promotional content; and
                  recognised fictional images or with indicators of fiction:
                </li>
                <ol className="disc">
                  <li>
                    Implicit sexual intercourse or oral sex, as indicated by a
                    person's mouth or genitals entering or in contact with
                    another person's genitals or anus, when the genitals or anus
                    and/or the entry or contact is not directly visible
                  </li>
                  <li>
                    Implicit stimulation of a person's genitals or anus, as
                    indicated by stimulation, or the placement of sex toys above
                    or insertion of sex toys into the person's genitals or anus,
                    when the genitals or anus, stimulation, placement and/or
                    insertion is not directly visible
                  </li>
                </ol>
                <li>
                  Other sexual activities, except in medical or health context,
                  promotional content, and recognised fictional images or with
                  indicators of fiction:
                </li>
                <ol className="disc">
                  <li>Erections</li>
                  <li>Presence of by-products of sexual activity</li>
                  <li>Sex toys placed upon or inserted into mouth</li>
                  <li>Stimulation of visible human nipples</li>
                  <li>
                    Squeezing female breasts, defined as a grabbing motion with
                    curved fingers that shows both marks and clear shape change
                    of the breasts. We allow squeezing in breastfeeding
                    contexts.
                  </li>
                </ol>
                <li>
                  Squeezing female breasts, defined as a grabbing motion with
                  curved fingers that shows both marks and clear shape change of
                  the breasts. We allow squeezing in breastfeeding contexts.
                </li>
                <ol className="disc">
                  <li>
                    Acts that are likely to lead to the death of a person or
                    animal
                  </li>
                  <li>Dismemberment</li>
                  <li>Cannibalism</li>
                  <li>Feces, urine, spit, snot, menstruation or vomit</li>
                  <li>Bestiality</li>
                  <li>Incest</li>
                </ol>
                <li>
                  Digital imagery of adult sexual activity, except when posted
                  in the context of medical awareness, scientific discourse or
                  discussion of sexual health, or when it meets one of the
                  criteria below and viewing is limited to adults aged 18 or
                  older.
                </li>
                <li>Extended audio of sexual activity.</li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following content, we include a label so that people are
                aware that the content may be sensitive:
              </p>
              <ul className="disc">
                <li>
                  Imagery of visible adult male and female genitalia, fully nude
                  close-ups of buttocks or visible anuses, or what would
                  otherwise qualify as implicit/other sexual activity or
                  stimulation, when shared in medical or health context. This
                  can include, for example:
                </li>
                <ol className="disc">
                  <li>Birth-giving and after-birth moments</li>
                  <li>Gender confirmation surgery</li>
                  <li>Self-examination for cancer or other disease</li>
                </ol>
                <li>
                  Imagery of implicit sexual activity or stimulation in sexual
                  wellness context.
                </li>
              </ul>
              <ul className="disc">
                <li>Real-world art where,</li>
                <ol className="disc">
                  <li>
                    Imagery depicts implicit, explicit or other sexual activity
                    or stimulation.
                  </li>
                  <li>
                    Imagery depicts bestiality, provided that it is shared
                    neutrally or in condemnation and the people or animals
                    depicted are not real.
                  </li>
                </ol>
                <li>
                  Implicit adult sexual activity in advertisements, recognised
                  fictional images or with indicators of fiction.
                </li>
                <li>
                  Digital imagery and real-world art of adult sexual activity,
                  where,
                </li>
                <ol className="disc">
                  <li>
                    The sexual activity or stimulation isn't explicit and is not
                    a fetish as specified above
                  </li>
                  <li>
                    The content was posted in a satirical or humorous context
                  </li>
                  <li>Only body shapes or contours are visible</li>
                </ol>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <ul className="disc">
                <li>
                  In certain cases, we will allow content that may otherwise
                  violate the Community Standards when it is determined that the
                  content is satirical. Content will only be allowed if the
                  violating elements of the content are being satirised or
                  attributed to something or someone else in order to mock or
                  criticise them.
                </li>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Adult sexual solicitation and sexually explicit language:
              </h1>
              <p className="font-normal text-xl mt-5">Policy rationate</p>
              <p>
                As noted in Section 8 of our Community Standards (Adult Sexual
                Exploitation), people use Twigyy to discuss and draw attention
                to sexual violence and exploitation. We recognise the importance
                of and allow for this discussion. We also allow for the
                discussion of sex worker rights advocacy and sex work
                regulation. We draw the line, however, when content facilitates,
                encourages or coordinates sexual encounters or commercial sexual
                services between adults. We do this to avoid facilitating
                transactions that may involve trafficking, coercion and
                non-consensual sexual acts.
              </p>
              <p>
                We also restrict sexually explicit language that may lead to
                sexual solicitation because some audiences within our global
                community may be sensitive to this type of content, and it may
                impede the ability for people to connect with their friends and
                the broader community.
              </p>
              <p className="font-normal text-xl mt-5">Do not post</p>
              <p>
                Content that offers or asks for adult commercial services, such
                as requesting, offering or asking for rates for escort service
                and paid sexual fetish or domination services. (Content that
                recruits or offers other people for third-party commercial sex
                work is separately considered under the Human Exploitation
                Policy).
              </p>
              <p>
                Attempted coordination of or recruitment for adult sexual
                activities, except when promoting an event or venue, including,
                but not limited to:
              </p>
              <ul className="disc">
                <li>Filmed sexual activities.</li>
                <li>
                  Pornographic activities, strip club shows, live sex
                  performances or erotic dances.
                </li>
                <li>Sexual, erotic or tantric massages.</li>
              </ul>
              <p>
                Explicit sexual solicitation by, including, but not limited to,
                the following, offering or asking for:
              </p>
              <ul className="disc">
                <li>
                  Offering or asking for sex or sexual partners (including
                  partners who share fetish or sexual interests).
                </li>
                <li>Sex chat or conversations.</li>
                <li>Nude photos/videos/imagery/sexual fetish items.</li>
                <li>Sexual slang terms.</li>
              </ul>
              <p>
                We allow expressing desire for sexual activity, promoting sex
                education, discussing sexual practices or experiences, or
                offering classes or programmes that teach techniques or discuss
                sex.
              </p>
              <p>
                Content that is implicitly or indirectly offering or asking for
                sexual solicitation and meets both of the following criteria. If
                both criteria are not met, it is not deemed to be violating. For
                example, if content is a hand-drawn image depicting sexual
                activity but does not ask or offer sexual solicitation, it is
                not violating:
              </p>
              <ul className="disc">
                <li className="text-m font-bold">Criteria 1: Offer or ask</li>
                <ol className="disc">
                  <li>
                    Content that implicitly or indirectly (typically through
                    providing a method of contact) offers or asks for sexual
                    solicitation.
                  </li>
                </ol>
                <li className="text-m font-bold">
                  Criteria 2: Suggestive elements
                </li>
                <ol className="disc">
                  <li>
                    Content that makes the aforementioned offer or ask using one
                    of the following sexually suggestive elements
                  </li>
                  <ol className="disc">
                    <li>Regional sexualised slang,</li>
                    <li>
                      Mentions or depictions of sexual activity such as sexual
                      roles, sex positions, fetish scenarios, state of arousal,
                      act of sexual intercourse or activity (e.g. sexual
                      penetration or self-pleasuring), commonly sexual emojis
                    </li>
                    <li>
                      Including content (hand drawn, digital or real-world art)
                      that depicts sexual activity as defined in Adult Nudity
                      and Sexual Activity policy
                    </li>
                    <li>Poses</li>
                    <li>
                      Audio of sexual activity or other content that violates
                      the Adult Nudity and Sexual Activity policy
                    </li>
                  </ol>
                </ol>
              </ul>
              <p>
                An offer or ask for pornographic material (including, but not
                limited to, sharing of links to external pornographic websites).
              </p>
              <p>
                Sexually explicit language that goes into graphic detail beyond
                mere reference to:
              </p>
              <ul className="disc">
                <li>A state of sexual arousal (e.g wetness or erection) or</li>
                <li>
                  An act of sexual intercourse (e.g sexual penetration,
                  self-pleasuring or exercising fetish scenarios).
                </li>
                <li>
                  Except for content shared in a humorous, satirical or
                  educational context, as a sexual Twiggyphor or as sexual
                  cursing.
                </li>
              </ul>
              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <ul className="disc">
                <li>
                  In certain cases, we will allow content that may otherwise
                  violate the Community Standards when it is determined that the
                  content is satirical. Content will only be allowed if the
                  violating elements of the content are being satirised or
                  attributed to something or someone else in order to mock or
                  criticise them.
                </li>
              </ul>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Hate speech:
              </h1>
              <p className="font-normal text-xl mt-5">Policy rationate</p>
              <p>
                We believe that people use their voice and connect more freely
                when they don't feel attacked on the basis of who they are. That
                is why we don't allow hate speech on Twigyy. It creates an
                environment of intimidation and exclusion, and in some cases may
                promote offline violence.
              </p>
              <p>
                We define hate speech as a direct attack against people – rather
                than concepts or institutions – on the basis of what we call
                protected characteristics: race, ethnicity, national origin,
                disability, religious affiliation, caste, sexual orientation,
                sex, gender identity and serious disease. We define attacks as
                violent or dehumanising speech, harmful stereotypes, statements
                of inferiority, expressions of contempt, disgust or dismissal,
                cursing and calls for exclusion or segregation. We also prohibit
                the use of harmful stereotypes, which we define as dehumanising
                comparisons that have historically been used to attack,
                intimidate or exclude specific groups, and that are often linked
                with offline violence. We consider age a protected
                characteristic when referenced along with another protected
                characteristic. We also protect refugees, migrants, immigrants
                and asylum seekers from the most severe attacks, though we do
                allow commentary and criticism of immigration policies.
                Similarly, we provide some protections for characteristics such
                as occupation, when they're referenced along with a protected
                characteristic. Sometimes, based on local nuance, we consider
                certain words or phrases as frequently used proxies for PC
                groups.
              </p>
              <p>
                We also prohibit the usage of slurs that are used to attack
                people on the basis of their protected characteristics. However,
                we recognise that people sometimes share content that includes
                slurs or someone else's hate speech to condemn it or raise
                awareness. In other cases, speech, including slurs, that might
                otherwise violate our standards can be used self-referentially
                or in an empowering way. Our policies are designed to allow room
                for these types of speech, but we require people to clearly
                indicate their intent. If the intention is unclear, we may
                remove content.
              </p>
              <p className="font-normal text-xl mt-5">Do not post</p>
              <p className="font-bold">Tier 1</p>
              <p>
                Content targeting a person or group of people (including all
                groups except those who are considered non-protected groups
                described as having carried out violent crimes or sexual
                offences or representing less than half of a group) on the basis
                of their aforementioned protected characteristic(s) or
                immigration status with:
              </p>
              <ul className="disc">
                <li>Violent speech or support in written or visual form.</li>
                <li>
                  Dehumanising speech or imagery in the form of comparisons,
                  generalisations or unqualified behavioural statements (in
                  written or visual form) to or about:
                </li>
                <ol className="disc">
                  <li>
                    Insects (including but not limited to: cockroaches, locusts)
                  </li>
                  <li>
                    Animals in general or specific types of animals that are
                    culturally perceived as intellectually or physically
                    inferior (including but not limited to: Black people and
                    apes or ape-like creatures; Jewish people and rats; Muslim
                    people and pigs; Mexican people and worms)
                  </li>
                  <li>Filth (including but not limited to: dirt, grime)</li>
                  <li>Bacteria, viruses or microbes</li>
                  <li>
                    Disease (including but not limited to: cancer, sexually
                    transmitted diseases)
                  </li>
                  <li>Faeces (including but not limited to: shit, crap)</li>
                  <li>
                    Subhumanity (including but not limited to: savages, devils,
                    monsters, primitives)
                  </li>
                  <li>
                    Sexual predators (including but not limited to: Muslim
                    people having sex with goats or pigs)
                  </li>
                  <li>
                    Violent criminals (including but not limited to: terrorists,
                    murderers, members of hate or criminal organisations)
                  </li>
                  <li>
                    Other criminals (including, but not limited to, "thieves",
                    "bank robbers" or saying "All [protected characteristic or
                    quasi-protected characteristic] are 'criminals'")
                  </li>
                  <li>
                    Certain objects (women as household objects, property or
                    objects in general; Black people as farm equipment;
                    transgender or non-binary people as "it")
                  </li>
                  <li>
                    Statements denying existence (including but not limited to:
                    "[protected characteristic(s) or quasi-protected
                    characteristic] do not exist", "no such thing as [protected
                    charactic(s) or quasi-protected characteristic]")
                  </li>
                  <li>
                    Harmful stereotypes historically linked to intimidation,
                    exclusion or violence on the basis of a protected
                    characteristic, such as Blackface; Holocaust denial; claims
                    that Jewish people control financial, political or media
                    institutions; and references to Dalits as menial labourers.
                  </li>
                </ol>
                <li>
                  Mocking the concept, events or victims of hate crimes even if
                  no real person is depicted in an image.
                </li>
              </ul>
              <p className="font-bold">Tier 2</p>
              <p>
                Content targeting a person or group of people on the basis of
                their protected characteristic(s) with:
              </p>
              <ul className="disc">
                <li>
                  Generalisations that state inferiority (in written or visual
                  form) in the following ways:
                </li>
                <ol className="disc">
                  <li>Physical deficiencies are defined as those about:</li>
                  <ol>
                    <li>
                      Hygiene, including, but not limited to: filthy, dirty,
                      smelly.
                    </li>
                    <li>
                      Physical appearance, including, but not limited to: ugly,
                      hideous.
                    </li>
                  </ol>
                  <li>Mental deficiencies are defined as those about:</li>
                  <ol className="disc">
                    <li>
                      Intellectual capacity, including, but not limited to:
                      dumb, stupid, idiots.
                    </li>
                    <li>
                      Education, including, but not limited to: illiterate,
                      uneducated.
                    </li>
                    <li>
                      Mental health, including, but not limited to: mentally
                      ill, retarded, crazy, insane.
                    </li>
                  </ol>
                  <li>Moral deficiencies are defined as those about:</li>
                  <ol className="disc">
                    <li>
                      Character traits culturally perceived as negative,
                      including, but not limited to: coward, liar, arrogant,
                      ignorant.
                    </li>
                    <li>
                      Derogatory terms related to sexual activity, including,
                      but not limited to: whore, slut, perverts.
                    </li>
                  </ol>
                  <li>Other statements of inferiority, which we define as:</li>
                  <ol className="disc">
                    <li>
                      Expressions about being less than adequate, including, but
                      not limited to: worthless, useless.
                    </li>
                    <li>
                      Expressions about being better/worse than another
                      protected characteristic, including, but not limited to:
                      "I believe that males are superior to females."
                    </li>
                    <li>
                      Expressions about deviating from the norm, including, but
                      not limited to: freaks, abnormal.
                    </li>
                  </ol>
                  <li>
                    Expressions of contempt (in written or visual form), which
                    we define as:
                  </li>
                  <ol className="disc">
                    <li>
                      Self-admission to intolerance on the basis of protected
                      characteristics, including, but not limited to:
                      homophobic, islamophobic, racist.
                    </li>
                    <li>
                      Expressions that a protected characteristic shouldn't
                      exist.
                    </li>
                    <li>
                      Expressions of hate, including, but not limited to:
                      despise, hate.
                    </li>
                    <li>
                      Expressions of dismissal, including, but not limited to:
                      don't respect, don't like, don't care for
                    </li>
                    <li>
                      Expressions of disgust (in written or visual form), which
                      we define as:
                    </li>
                    <ol className="disc">
                      <li>
                        Expressions suggesting that the target causes sickness,
                        including, but not limited to: vomit, throw up.
                      </li>
                      <li>
                        Expressions of repulsion or distaste, including, but not
                        limited to: vile, disgusting, yuck.
                      </li>
                    </ol>
                    <li>
                      Cursing, except certain gender-based cursing in a romantic
                      break-up context, defined as:
                    </li>
                    <ol className="disc">
                      <li>
                        Referring to the target as genitalia or anus, including,
                        but not limited to: cunt, dick, asshole.
                      </li>
                      <li>
                        Profane terms or phrases with the intent to insult,
                        including, but not limited to: fuck, bitch,
                        motherfucker.
                      </li>
                      <li>
                        Referring to the target as genitalia or anus, including,
                        but not limited to: cunt, dick, asshole.
                      </li>
                    </ol>
                  </ol>
                </ol>
              </ul>
              <p className="font-bold">Tier 3</p>
              <p>
                Content targeting a person or group of people on the basis of
                their protected characteristic(s) with any of the following:
              </p>
              <ul className="disc">
                <li>
                  Segregation in the form of calls for action, statements of
                  intent, aspirational or conditional statements, or statements
                  advocating or supporting segregation.
                </li>
                <li>
                  Exclusion in the form of calls for action, statements of
                  intent, aspirational or conditional statements, or statements
                  advocating or supporting, defined as
                </li>
                <ol className="disc">
                  <li>
                    Explicit exclusion, which means things such as expelling
                    certain groups or saying that they are not allowed.
                  </li>
                  <li>
                    Political exclusion, which means denying the right to
                    political participation.
                  </li>
                  <li>
                    Economic exclusion, which means denying access to economic
                    entitlements and limiting participation in the labour
                    market.
                  </li>
                  <li>
                    Social exclusion, which means things such as denying access
                    to spaces (physical and online) and social services, except
                    for gender-based exclusion in health and positive support
                    groups.
                  </li>
                </ol>
              </ul>
              <p>
                Content that describes or negatively targets people with slurs,
                where slurs are defined as words that inherently create an
                atmosphere of exclusion and intimidation against people on the
                basis of a protected characteristic, often because these words
                are tied to historical discrimination, oppression and violence.
                They do this even when targeting someone who is not a member of
                the PC group that the slur inherently targets.
              </p>

              <p className="font-normal text-xl mt-5">
                For the following Community Standards, we require additional
                information and/or context to enforce:
              </p>
              <p className="font-normal text-xl mt-5">Do not post</p>
              <ul className="disc">
                <li>
                  Content explicitly providing or offering to provide products
                  or services that aim to change people's sexual orientation or
                  gender identity.
                </li>
                <li>
                  Content attacking concepts, institutions, ideas, practices or
                  beliefs associated with protected characteristics, which are
                  likely to contribute to imminent physical harm, intimidation
                  or discrimination against the people associated with that
                  protected characteristic. Twigyy looks at a range of signs to
                  determine whether there is a threat of harm in the content.
                  These include, but are not limited to: content that could
                  incite imminent violence or intimidation; whether there is a
                  period of heightened tension such as an election or ongoing
                  conflict; and whether there is a recent history of violence
                  against the targeted protected group. In some cases, we may
                  also consider whether the speaker is a public figure or
                  occupies a position of authority.
                </li>
                <li>
                  Content targeting a person or group of people on the basis of
                  their protected characteristic(s) with claims that they have
                  or spread the novel coronavirus, are responsible for the
                  existence of the novel coronavirus or are deliberately
                  spreading the novel coronavirus, or mocking them for having or
                  experiencing the novel coronavirus.
                </li>
              </ul>
              <p>
                In certain cases, we will allow content that may otherwise
                violate the Community Standards when it is determined that the
                content is satirical. Content will only be allowed if the
                violating elements of the content are being satirised or
                attributed to something or someone else in order to mock or
                criticise them.
              </p>
            </div>
            <div>
              <h1 className="text-[#007a80] font-medium text-xl mt-5">
                Violent and graphic content:
              </h1>
              <p className="font-normal text-xl mt-5">Policy rationate</p>
              <p>
                To protect users from disturbing imagery, we remove content that
                is particularly violent or graphic, such as videos depicting
                dismemberment, visible innards or charred bodies. We also remove
                content that contains sadistic remarks towards imagery depicting
                the suffering of humans and animals.
              </p>
              <p>
                In the context of discussions about important issues such as
                human rights abuses, armed conflicts or acts of terrorism, we
                allow graphic content (with some limitations) to help people to
                condemn and raise awareness about these situations.
              </p>
              <p>
                We know that people have different sensitivities with regard to
                graphic and violent imagery. For that reason, we add a warning
                label to some graphic or violent imagery so that people are
                aware it may be sensitive before they click through. We also
                restrict the ability for users under 18 to view such content.
              </p>
            </div>
            <p className="font-normal text-xl mt-5">Do not post</p>
            <p className="font-bold">Imagery of people</p>
            <p>
              Videos of people or dead bodies in non-medical settings if they
              depict.
            </p>
            <ul className="disc">
              <li>Dismemberment.</li>
              <li>Visible internal organs; partially decomposed bodies.</li>
              <li>
                Charred or burning people unless in the context of cremation or
                self-immolation when that action is a form of political speech
                or newsworthy.
              </li>
              <li>Victims of cannibalism.</li>
              <li>Throat-slitting.</li>
            </ul>
            <p>Live streams of capital punishment of a person.</p>
            <p className="font-bold">Sadistic remarks</p>
            <ul className="disc">
              <li>
                Sadistic remarks towards imagery that is put behind a warning
                screen under this policy advising people that the content may be
                disturbing, unless there is a self-defence context or medical
                setting.
              </li>
              <li>
                Sadistic remarks towards the following content which includes a
                label so that people are aware that it may be sensitive:
              </li>
              <ol className="disc">
                <li>
                  Imagery of one or more persons subjected to violence and/or
                  humiliating acts by one or more uniformed personnel performing
                  a police function.
                </li>
                <li>
                  Imagery of fetuses and babies outside of the womb that are
                  deceased.
                </li>
                <li>
                  Explicit sadistic remarks towards the suffering of animals
                  depicted in the imagery.
                </li>
              </ol>
              <li>
                Offering or soliciting imagery that is deleted or put behind a
                warning screen under this policy, when accompanied by sadistic
                remarks.
              </li>
            </ul>
            <p className="font-normal text-xl mt-5">
              For the following content, we include a warning screen so that
              people are aware that the content may be disturbing. We also limit
              the ability to view the content to adults aged 18 and older:
            </p>
            <p className="font-bold">Imagery of people</p>
            <p>
              Videos of people or dead bodies in non-medical settings if they
              depict.
            </p>
            <ul className="disc">
              <li>Dismemberment.</li>
              <li>Visible internal organs; partially decomposed bodies.</li>
              <li>
                Charred or burning people unless in the context of cremation or
                self-immolation when that action is a form of political speech
                or newsworthy.
              </li>
              <li>Victims of cannibalism.</li>
              <li>Throat-slitting.</li>
            </ul>
            <p>Photos of wounded or dead people if they show:</p>
            <ul className="disc">
              <li>Dismemberment.</li>
              <li>Visible internal organs; partially decomposed bodies.</li>
              <li>
                Charred or burning people unless in the context of cremation or
                self-immolation when that action is a form of political speech
                or newsworthy.
              </li>
              <li>Victims of cannibalism.</li>
              <li>Throat-slitting.</li>
            </ul>
            <p>
              Imagery depicting a person's violent death (including their moment
              of death or the aftermath) or a person experiencing a
              life-threatening event (such as being struck by a car, falling
              from a great height or experiencing another possibly fatal
              physical injury).
            </p>
            <p>
              Imagery that shows capital punishment of a person Imagery that
              shows acts of torture committed against a person or people Imagery
              of non-medical foreign objects (such as Twiggyl objects, knives,
              nails) inserted or stuck into a person causing grievous injury
            </p>
            <p className="font-bold">Imagery of animals</p>
            <p>The following content involving animals:</p>
            <ul className="disc">
              <li>
                Videos depicting humans killing animals if there is no explicit
                manufacturing, hunting, food consumption, processing or
                preparation context.
              </li>
              <li>
                Imagery of animal-to-animal fights, when there are visible
                innards or dismemberment of non-regenerating body, unless in the
                wild.
              </li>
              <li>
                Imagery of humans committing acts of torture or abuse against
                live animals.
              </li>
              <li>
                Imagery of animals showing wounds or cuts that render visible
                innards or dismemberment, if there is no explicit manufacturing,
                hunting, taxidermy, medical treatment, rescue or food
                consumption, preparation or processing context, or the animal is
                already skinned or with its outer layer fully removed.
              </li>
            </ul>
            <p className="font-normal text-xl mt-5">
              For the following content, we include a label so that people are
              aware that the content may be sensitive:
            </p>
            <ul className="disc">
              <li>
                Imagery of non-medical foreign objects inserted into a person
                through their skin in a religious or cultural context.
              </li>
              <li>Imagery of visible innards in a birthing context.</li>
              <li>
                Imagery of fetuses and babies outside of the womb that are
                deceased, unless another person is present in the image.
              </li>
              <li>
                Imagery of fetuses and babies outside the womb in an abandonment
                context.
              </li>
              <li>
                Imagery of animals in a ritual slaughter context showing
                dismemberment, visible innards or charring or burning.
              </li>
            </ul>
            <p className="font-normal text-xl mt-5">
              For the following Community Standards, we require additional
              information and/or context to enforce:
            </p>
            <ul className="disc">
              <li>
                Videos and photos that show the violent death of someone when a
                family member requests its removal.
              </li>
              <li>
                Videos of violent death of humans where the violent death is not
                visible in the video but the audio is fully or partially
                captured and the death is confirmed by either a law enforcement
                record, death certificate, Trusted Partner report or media
                report.
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-medium text-xl mt-5">
              INTEGRITY AND AUTHENTICITY
            </h1>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Account integrity and authentic identity:
            </h1>
            <p className="font-normal text-xl mt-5">Policy rationate</p>
            <p>
              Authenticity is the cornerstone of our community. We believe that
              authenticity helps create a community where people are accountable
              to each other, and to Twigyy, in meaningful ways. We want to allow
              for the range of diverse ways that identity is expressed across
              our global community, while also preventing impersonation and
              identity misrepresentation. That's why we require people to create
              a Twigyy account using the name they go by in everyday life. Our
              Authenticity Policies are intended to create a safe environment
              where people can trust and hold one another accountable.
            </p>
            <p>
              In order to maintain a safe environment and empower free
              expression, we remove accounts that are harmful to the community,
              including those that compromise the security of other accounts and
              our services. We have built a combination of automated and manual
              systems to block and remove accounts that are used to persistently
              or egregiously abuse our Community Standards.
            </p>
            <p>
              Because account level removal is a severe action, whenever
              possible, we aim to give our community a chance to learn our rules
              and follow our Community Standards. Penalties, including account
              disables, are designed to be proportionate to the severity of the
              violation and the risk of harm posed to the community. Continued
              violations, despite repeated warnings and restrictions, or
              violations that pose severe safety risks, will lead to an account
              being disabled.
            </p>
            <p className="font-normal text-xl mt-5">
              We do not allow the use of our services and will restrict or
              disable accounts or other entities (such as Pages, groups and
              events) if you:
            </p>
            <ul className="disc">
              <li>Severely violate our Community Standards.</li>
              <li>Persistently violate our Community Standards.</li>
              <li>
                Coordinate as part of a network of accounts or other entities in
                order to violate or evade our Community Standards.
              </li>
              <li>Represent dangerous individuals or organisations.</li>
              <li>
                Create or use an account that demonstrates intent to violate our
                Community Standards.
              </li>
              <li>
                Create or use an account by scripted or other inauthentic means.
              </li>
              <li>
                Create or repurpose an account or entity to evade a previous
                removal, including those assessed to have common ownership and
                content as previously removed accounts or entities.
              </li>
              <li>
                Create or use an account that deliberately misrepresents your
                identity in order to mislead or deceive others, or to evade
                enforcement or violate our other Community Standards. We
                consider a number of factors when assessing misleading identity
                misrepresentation, such as:
              </li>
              <ol className="disc">
                <li>
                  Repeated or significant changes to identity details, such as
                  name or age.
                </li>
                <li>
                  Misleading profile information, such as bio details and
                  profile location.
                </li>
                <li>Using stock imagery or stolen photos.</li>
                <li>Other related account activity.</li>
              </ol>
              <li>Impersonate others by:</li>
              <ol className="disc">
                <li>
                  Using their photos with the explicit aim to deceive others.
                </li>
                <li>
                  Creating an account assuming to be or speak for another person
                  or entity.
                </li>
                <li>
                  Creating a Page assuming to be or speak for another person or
                  entity for whom the user is not authorised to do so.
                </li>
              </ol>
              <li>Are under 13 years old</li>
              <li>Are convicted sex offender.</li>
              <li>
                Are prohibited from receiving our products, services or software
                under applicable laws.
              </li>
            </ul>
            <p className="font-normal text-xl mt-5">
              In certain cases, such as those outlined below, we will seek
              further information about an account before taking actions ranging
              from temporarily restricting accounts to permanently disabling
              them.
            </p>
            <ul className="disc">
              <li>
                Accounts misrepresenting their identity (Twigyy and Messenger
                only) by:
              </li>
              <ol className="disc">
                <li>
                  Creating an account using a name that is not the authentic
                  name you go by in everyday life.
                </li>
                <ol className="disc">
                  <li>
                    Using an inherently violating name containing slurs or any
                    other violations of the Community Standards
                  </li>
                </ol>
                <li>Providing a false date of birth.</li>
                <li>
                  Creating a single account that represents or is used by more
                  than one person.
                </li>
                <li>Maintaining multiple accounts as a single user.</li>
              </ol>
              <li>Compromised accounts.</li>
              <li>Emplty accounts with prolonged dormancy.</li>
            </ul>
          </div>
          <div>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Cybersecurity
            </h1>
            <p className="font-normal text-xl mt-5">Policy rationate</p>
            <p>
              We recognise that the safety of our users includes the security of
              their personal information, accounts, profiles and other Twigyy
              entities they may manage, as well as our products and services
              more broadly. Attempts to gather sensitive personal information or
              engage in unauthorised access by deceptive or invasive methods are
              harmful to the authentic, open and safe atmosphere that we want to
              foster. Therefore, we do not allow attempts to gather sensitive
              user information or engage in unauthorised access through the
              abuse of our platform, products or services.
            </p>
            <p className="font-normal text-xl mt-5">Do not</p>
            <p>
              Attempt to compromise user accounts, profiles or other Twigyy
              entities, abuse our products or services, gather sensitive
              information through deceptive means or attempt to engage in
              unauthorised access, including:
            </p>
            <ul className="disc">
              <li>
                Gaining access to accounts, profiles, Twigyy entities, or user
                data other than your own through deceptive means or without
                explicit permission from the account, profile, or entity owner.
              </li>
              <li>
                Encouraging or deceiving users to download or run files or
                programs that compromise a user's online or data security,
                including through malicious software or websites. Such files and
                programs will be deemed malicious software or "malware" if they
                harm or gain unauthorized access to a computer, device, or
                network.
              </li>
              <li>
                Attempting to obtain, acquire, or request another user's login
                credentials, personal information, or other sensitive data –
                whether explicitly or through deceptive means such as phishing
                (e.g., fake surveys designed to capture login info or links to
                fake login pages or impostor websites) or the use of malicious
                software or websites.
              </li>
              <li>
                Publicly sharing your own or others' login information, either
                on the platform or through a third-party service.
              </li>
              <li>
                Creating, sharing, or hosting malicious software, including
                browser extensions and mobile applications, on or off the
                platform that puts our users or products and services at risk.
              </li>
              <li>
                Providing online infrastructure, including web hosting services,
                domain name system servers, and ad networks, that enables
                abusive links such that a majority of those links on Twigyy or
                Instagram violate the spam or cybersecurity sections of the
                Community Standards.
              </li>
            </ul>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Inauthentic behaviour:
            </h1>
            <p className="font-normal text-xl mt-5">Policy rationate</p>
            <p>
              In line with our commitment to authenticity, we do not allow
              people to misrepresent themselves on Twigyy, use fake accounts,
              artificially boost the popularity of content or engage in
              behaviours designed to enable other violations under our Community
              Standards. This policy is intended to protect the security of user
              accounts and our services, and create a space where people can
              trust the people and communities they interact with.
            </p>
            <p className="font-normal text-xl mt-5">Do not post</p>
            <ul className="disc">
              <li>
                Use multiple Twigyy accounts or share accounts between multiple
                people.
              </li>
              <li>
                Misuse Twigyy or Instagram reporting systems to harass others.
              </li>
              <li>
                Conceal a Page's purpose by misleading users about the ownership
                or control of that Page.
              </li>
              <li>
                Engage in or claim to engage in inauthentic behavior, which is
                defined as the use of Twigyy or Instagram assets (accounts,
                Pages, Groups, or Events) to mislead people or Twigyy.
              </li>
              <ol className="disc">
                <li>
                  About the identity, purpose, or origin of the entity that they
                  represent.
                </li>
                <li>
                  About the popularity of Twigyy or Instagram content or assets.
                </li>
                <li>About the purpose of an audience or community.</li>
                <li>About the source or origin of content.</li>
                <li>To evade enforcement under our Community Standards.</li>
              </ol>
            </ul>
            <li>
              Use multiple Twigyy accounts or share accounts between multiple
              people.
            </li>
            <li>
              Misuse Twigyy or Instagram reporting systems to harass others.
            </li>
            <li>
              Conceal a Page's purpose by misleading users about the ownership
              or control of that Page.
            </li>
            <li>
              Engage in or claim to engage in inauthentic behavior, which is
              defined as the use of Twigyy or Instagram assets (accounts, Pages,
              Groups, or Events) to mislead people or Twigyy.
            </li>
            <ol className="disc"></ol>
            <p className="font-normal text-xl mt-5">
              For the following Community Standards, we require additional
              information and/or context to enforce:
            </p>
            <ul className="disc">
              <li>
                We do not allow entities to engage in, or claim to engage in,
                coordinated inauthentic behavior, defined as the use of multiple
                Twigyy or Instagram assets, working in concert to engage in
                inauthentic behavior (as defined above), where the use of fake
                accounts is central to the operation.
              </li>
              <li>
                We do not allow entities to engage in, or claim to engage in,
                foreign or government interference, which is coordinated
                inauthentic behavior conducted on behalf of a foreign or
                government actor.
              </li>
              <li>
                We do not allow governments that have instituted sustained
                blocks of social media to use their official departments,
                agencies, and embassies to deny the use of force or violent
                events in the context of an attack against the territorial
                integrity of another state in violation of Article 2(4) of the
                UN Charter.
              </li>
            </ul>
          </div>
          <div>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Memorialisation:
            </h1>
          </div>
          <p className="font-normal text-xl mt-5">Policy Rationate</p>
          <p>
            When someone passes away, friends and family can request that we
            memorialise the Twigyy account. Once memorialised, the word
            "Remembering" appears above the name on the person's profile so that
            the account is now a memorial site and protects against attempted
            logins and fraudulent activity. To respect the choices someone made
            while alive, we aim to preserve their account after they pass away.
          </p>
          <p>
            We have also made it possible for people to identify a legacy
            contact to look after their account after they pass away. To support
            the bereaved, in some instances, we may remove or change certain
            content when the legacy contact or family members request it. Visit
            Hard questions for more information about our Memorialisation Policy
            and process. And see our Newsroom for the latest tools we are
            building to support people during these difficult times.
          </p>
          <p className="font-normal text-xl mt-5">
            For victims of murder and suicide, we will remove the following
            content if it appears on the deceased's profile photo, cover photo
            or amongst recent timeline posts when requested by a legacy contact
            or family member of the deceased:
          </p>
          <ul className="disc">
            <li>
              Visual depiction of the object used in the deceased's death.
            </li>
            <li>
              Imagery of the convicted or alleged murderer of the deceased.
            </li>
            <li>Content related to the deceased's death.</li>
          </ul>
          <p>
            For victims of murder, we will also remove the convicted or alleged
            murderer from the deceased's profile if referenced in relationship
            status or amongst friends.
          </p>
          <p className="font-normal text-xl mt-5">
            For the following Community Standards, we require additional
            information and/or context to enforce:
          </p>
          <p>
            When brought to our attention by the legacy contact or a family
            member of the deceased, we will:
          </p>
          <ul className="disc">
            <li>
              Remove violating comments on a memorialized profile, which would
              typically require the individual to self-report so that we know
              that they are unwanted.
            </li>
            <li>
              Remove praise or support for the death, disease, or harm of the
              deceased person on a memorialized profile.
            </li>
            <li>
              Change the deceased individual's privacy settings from public to
              friends-only when there is harmful content on the profile.
            </li>
            <li>
              Change a violating account name on the profile of the deceased
              individual.
            </li>
          </ul>
          <h1 className="text-[#007a80] font-medium text-xl mt-5">
            Misinformation
          </h1>
          <p className="font-normal text-xl mt-5">Policy rationate</p>
          <p>
            Misinformation is different from other types of speech addressed in
            our Community Standards because there is no way to articulate a
            comprehensive list of what is prohibited. With graphic violence or
            hate speech, for instance, our policies specify the speech that we
            prohibit and even persons who disagree with those policies can
            follow them. With misinformation, however, we cannot provide such a
            line. The world is changing constantly, and what is true one minute
            may not be true the next minute. People also have different levels
            of information about the world around them, and may believe
            something is true when it is not. A policy that simply prohibits
            "misinformation" would not provide useful notice to the people who
            use our services and would be unenforceable, as we don't have
            perfect access to information.
          </p>
          <p>
            Instead, our policies articulate different categories of
            misinformation and try to provide clear guidance about how we treat
            that speech when we see it. For each category, our approach reflects
            our attempt to balance our values of expression, safety, dignity,
            authenticity and privacy.
          </p>
          <p>
            We remove misinformation where it is likely to directly contribute
            to the risk of imminent physical harm. We also remove content that
            is likely to directly contribute to interference with the
            functioning of political processes and certain highly deceptive
            manipulated media. In determining what constitutes misinformation in
            these categories, we partner with independent experts who possess
            knowledge and expertise to assess the truth of the content and
            whether it is likely to directly contribute to the risk of imminent
            harm. This includes, for instance, partnering with human rights
            organisations with a presence on the ground in a country to
            determine the truth of a rumour about civil conflict, and partnering
            with health organisations during the global COVID-19 pandemic.
          </p>
          <p>
            For all other misinformation, we focus on reducing its prevalence or
            creating an environment that fosters a productive dialogue. We know
            that people often use misinformation in harmless ways, such as to
            exaggerate a point ("This team has the worst record in the history
            of the sport!") or in humour or satire ("My husband just won Husband
            of the Year.") They also may share their experience through stories
            that contain inaccuracies. In some cases, people share deeply held
            personal opinions that others consider false or share information
            that they believe to be true but others consider incomplete or
            misleading.
          </p>
          <p>
            Recognising how common such speech is, we focus on slowing the
            spread of hoaxes and viral misinformation, and directing users to
            authoritative information. As part of that effort, we partner with
            third-party fact-checking organisations to review and rate the
            accuracy of the most viral content on our platforms (see here to
            learn more about how our fact-checking programme works). We also
            provide resources to increase media and digital literacy so that
            people can decide what to read, trust and share themselves. We
            require people to disclose, using our AI disclosure tool, whenever
            they post organic content with photorealistic video or
            realistic-sounding audio that was digitally created or altered, and
            we may apply penalties if they fail to do so. We may also add a
            label to certain digitally created or altered content that creates a
            particularly high risk of misleading people on a matter of public
            importance.
          </p>
          <p>
            Finally, we prohibit content and behaviour in other areas that often
            overlap with the spread of misinformation. For example, our
            Community Standards prohibit fake accounts, fraud and coordinated
            inauthentic behaviour.
          </p>
          <p>
            As online and offline environments change and evolve, we will
            continue to evolve these policies. Pages, groups, profiles and
            Instagram accounts that repeatedly share the misinformation listed
            below may, in addition to having their content removed, receive
            decreased distribution, limitations on their ability to advertise or
            be removed from our platforms. Additional information on what
            happens when Twigyy removes content can be found here.
          </p>
          <p className="font-normal text-xl mt-5">
            Misinformation that we remove:
          </p>
          <p>We remove the following types of misinformation:</p>
          <h1 className="font-bold text-m">1. Physical harm or violence</h1>
          <p>
            We remove misinformation or unverifiable rumours that expert
            partners have determined are likely to directly contribute to a risk
            of imminent violence or physical harm to people. We define
            misinformation as content with a claim that is determined to be
            false by an authoritative third party. We define an unverifiable
            rumour as a claim whose source expert partners confirm is extremely
            hard or impossible to trace, for which authoritative sources are
            absent, where there is not enough specificity for the claim to be
            debunked, or where the claim is too incredulous or too irrational to
            be believed.
          </p>
          <p>
            We know that sometimes misinformation that might appear benign
            could, in a specific context, contribute to a risk of offline harm,
            including threats of violence that could contribute to a heightened
            risk of death, serious injury or other physical harm. We work with a
            global network of non-governmental organisations (NGOs),
            not-for-profit organisations, humanitarian organisations and
            international organisations that have expertise in these local
            dynamics.
          </p>
          <p>
            In countries experiencing a heightened risk of societal violence, we
            work proactively with local partners to understand which false
            claims may directly contribute to a risk of imminent physical harm.
            We then work to identify and remove content making those claims on
            our platform. For example, in consultation with local experts, we
            may remove out-of-context media falsely claiming to depict acts of
            violence, victims or perpetrators of violence, weapons or military
            hardware.
          </p>
          <h1 className="font-bold text-m">2. Harmful health misinformation</h1>
          <p>
            We consult with leading health organisations to identify health
            misinformation likely to directly contribute to imminent harm to
            public health and safety. The harmful health misinformation that we
            remove includes the following:
          </p>
          <ul className="disc">
            <li>
              <strong>Misinformation about vaccines.</strong> We remove
              misinformation primarily about vaccines when public health
              authorities conclude that the information is false and likely to
              directly contribute to imminent vaccine refusals. They include:
            </li>
            <ol className="disc">
              <li>
                Vaccines cause autism (e.g. "Increased vaccinations are why so
                many children have autism these days.")
              </li>
              <li>
                Vaccines cause Sudden Infant Death Syndrome (e.g. "Don't you
                know that vaccines cause SIDS?")
              </li>
              <li>
                Vaccines cause the disease against which they are meant to
                protect, or cause the person receiving the vaccine to be more
                likely to get the disease (e.g. "Taking a vaccine actually makes
                you more likely to get the disease as there's a strain of the
                disease inside. Beware!")
              </li>
              <li>
                Vaccines or their ingredients are deadly, toxic, poisonous,
                harmful or dangerous (e.g. "Sure, you can take vaccines, if you
                don't mind putting poison in your body.")
              </li>
              <li>
                Natural immunity is safer than vaccine-acquired immunity (e.g.
                "It's safest to just get the disease rather than the vaccine.")
              </li>
              <li>
                It is dangerous to get several vaccines in a short period of
                time, even if that timing is medically recommended (e.g. "Never
                take more than one vaccine at the same time, that is dangerous.
                I don't care what your doctor tells you!")
              </li>
              <li>
                Vaccines are not effective at preventing the disease against
                which they purport to protect. However, for the COVID-19, flu
                and malaria vaccines, we do not remove claims that those
                vaccines are not effective in preventing someone from
                contracting those viruses. (e.g. Remove – "The polio vaccine
                doesn't do anything to stop you from getting the disease";
                Remove – "Vaccines actually don't do anything to stop you from
                getting diseases"; Allow – "The vaccine doesn't stop you from
                getting COVID-19, that's why you still need to socially distance
                and wear a mask when you're around others.")
              </li>
              <li>
                Acquiring measles cannot cause death (requires additional
                information and/or context) (e.g. "Don't worry about whether you
                get measles, it can't be fatal.")
              </li>
              <li>
                Vitamin C is as effective as vaccines in preventing diseases for
                which vaccines exist.
              </li>
            </ol>
            <li>
              <strong>
                Misinformation about health during public health emergencies.
              </strong>{" "}
              We remove misinformation during public health emergencies when
              public health authorities conclude that the information is false
              and likely to directly contribute to the risk of imminent physical
              harm, including by contributing to the risk of individuals getting
              or spreading a harmful disease or refusing an associated vaccine.
              We identify public health emergencies in partnership with global
              and local health authorities.
            </li>
            <li>
              <strong>
                {" "}
                Promoting or advocating for harmful miracle cures for health
                issues.
              </strong>{" "}
              These include treatments where the recommended application, in a
              health context, is likely to directly contribute to the risk of
              serious injury or death, and the treatment has no legitimate
              health use (e.g. bleach, disinfectant, black salve, caustic soda).
            </li>
          </ul>
          <h1 className="font-bold text-m">3. Voter or census interference</h1>
          <p>
            In an effort to promote election and census integrity, we remove
            misinformation that is likely to directly contribute to a risk of
            interference with people's ability to participate in those
            processes. This includes the following:
          </p>
          <ul className="disc">
            <li>
              Misinformation about the dates, locations, times, and methods for
              voting, voter registration, or census participation.
            </li>
            <li>
              Misinformation about who can vote, qualifications for voting,
              whether a vote will be counted, and what information or materials
              must be provided in order to vote.
            </li>
            <li>Misinformation about whether a candidate is running or not.</li>
            <li>
              Misinformation about who can participate in the census and what
              information or materials must be provided in order to participate.
            </li>
            <li>
              Misinformation about government involvement in the census,
              including, where applicable, that an individual's census
              information will be shared with another (non-census) government
              agency.
            </li>
            <li>
              False or unverified claims that the U.S. Immigration and Customs
              Enforcement (ICE) is at a voting location.
            </li>
            <li>
              Explicit false claims that people will be infected by COVID-19 (or
              another communicable disease) if they participate in the voting
              process.
            </li>
            <li>
              False claims about current conditions at a US voting location that
              would make it impossible to vote, as verified by an election
              authority.
            </li>
          </ul>
          <p>
            We have additional policies intended to cover calls for violence,
            the promotion of illegal participation and calls for coordinated
            interference in elections, which are represented in other sections
            of our Community Standards.
          </p>
          <h1 className="font-bold text-m">3. Manupulated media</h1>
          <p>
            Media can be edited in a variety of ways. In many cases, these
            changes are benign, such as content being cropped or shortened for
            artistic reasons or music being added. In other cases, the
            manipulation is not apparent and could mislead, particularly in the
            case of video content. We remove this content because it can go
            viral quickly and experts advise that false beliefs regarding
            manipulated media often cannot be corrected through further
            discourse.
          </p>
          <p>
            We remove videos under this policy if specific criteria are met: (1)
            the video has been edited or synthesised, beyond adjustments for
            clarity or quality, in ways that are not apparent to an average
            person and would likely mislead an average person to believe a
            subject of the video said words that they did not say; and (2) the
            video is the product of artificial intelligence or machine learning,
            including deep learning techniques (e.g. a technical deepfake), that
            merges, combines, replaces and/or superimposes content onto a video,
            creating a video that appears authentic.
          </p>
          <p className="font-normal text-xl mt-5">
            For the following content, we include an informative label:
          </p>
          <h1 className="font-bold text-m">
            Content digitally created or altered that may mislead
          </h1>
          <p>
            For content that does not otherwise violate the Community Standards,
            we may place an informative label on the face of content – or reject
            content submitted as an advertisement – when the content is a
            photorealistic image or video, or realistic-sounding audio, that was
            digitally created or altered and creates a particularly high risk of
            materially deceiving the public on a matter of public importance.
          </p>
          <div>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">Spam</h1>
            <p className="font-normal text-xl mt-5">Policy rationate</p>
            <p>
              We work hard to limit the spread of spam because we do not want to
              allow content that is designed to deceive, or that attempts to
              mislead users, to increase viewership. This content creates a
              negative user experience, detracts from people's ability to engage
              authentically in online communities and can threaten the security,
              stability and usability of our services. We also aim to prevent
              people from abusing our platform, products or features to
              artificially increase viewership or distribute content en masse
              for commercial gain.
            </p>
            <p className="font-normal text-xl mt-5">Do not post</p>
            <ul className="disc">
              <li>
                Post, share, or engage with content, or create accounts, groups,
                Pages, events, or other assets, either manually or
                automatically, at very high frequencies.
              </li>
              <li>
                Attempt to or successfully sell, buy, or exchange site
                privileges, engagement, or product features, such as accounts,
                admin roles, permission to post, Pages, groups, and likes,
                except in the case of clearly identified branded content, as
                defined by our Branded Content Policies.
              </li>
              <li>
                Require or claim that users are required to engage with content
                (e.g., liking, sharing) before they are able to view or interact
                with promised content.
              </li>
              <li>
                Encourage likes, shares, follows, clicks, or the use of apps or
                websites under false pretenses, such as:
              </li>
              <ol className="disc">
                <li>
                  Offering false or non-existent services or functionality (e.g.
                  "Get a 'Dislike' button!")
                </li>
                <li>
                  Failing to direct to promised content (e.g. "Click here for a
                  discount code at Nordstrom"; false play buttons)
                </li>
                <li>The deceptive or misleading use of URLs, defined as:</li>
                <ol className="disc">
                  <li>
                    <strong>Cloaking: </strong> Presenting different content to
                    Twigyy users and Twigyy crawlers or tools.
                  </li>
                  <li>
                    <strong>Misleading content: </strong> Content contains a
                    link that promises one type of content but delivers
                    something substantially different.
                  </li>
                  <li>
                    <strong>Deceptive redirect behavior: </strong>Websites that
                    require an action (e.g., captcha, watch ad, click here) in
                    order to view the expected landing page content, and the
                    domain name of the URL changes after the required action is
                    complete.
                  </li>
                  <li>
                    <strong>Like/share-gating:</strong> Landing pages that
                    require users to like, share, or otherwise engage with
                    content before gaining access to content.
                  </li>
                  <li>
                    <strong>Deceptive landing page functionality: </strong>
                    Websites that have a misleading user interface, which
                    results in accidental traffic being generated (e.g.,
                    pop-ups/unders, clickjacking, etc.).
                  </li>
                  <li>
                    <strong>Typosquatting:</strong> An external website pretends
                    to be a reputable brand or service by using a name, domain,
                    or content that features typos, misspellings, or other means
                    to impersonate well-known brands using a landing page
                    similar to another, trusted site to mislead visitors (e.g.,
                    www.twigyyy.com, www.twig_i.com).
                  </li>
                  <li>
                    And other behaviors that are substantially similar to the
                    above.
                  </li>
                </ol>
              </ol>
            </ul>
          </div>
          <div>
            <h1 className="text-xl mt-5">RESPECTFUL INTELLECTUAL PROPERTY</h1>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Intellectual property
            </h1>
            <p className="font-normal text-xl mt-5">Policy rationate</p>
            <p>
              Twigyy takes intellectual property rights seriously and believes
              that they are important to promoting expression, creativity and
              innovation in our community. You own all of the content and
              information that you post on Twigyy, and you control how it is
              shared through your privacy and application settings. However,
              before sharing content on Twigyy, please make sure that you have
              the right to do so. We ask that you respect other people's
              copyrights, trademarks and other legal rights. We are committed to
              helping people and organisations promote and protect their
              intellectual property rights.
            </p>
            <p>
              Twigyy's Terms of Service do not allow people to post content that
              violates someone else's intellectual property rights, including
              copyright and trademark. We publish information about the
              intellectual property reports we receive in our bi-annual
              transparency report.
            </p>
            <p className="font-normal text-xl mt-5">
              Upon receipt of a report from a rights holder or an authorised
              representative, we will remove or restrict content that engages
              in:
            </p>
            <ul className="disc">
              <li>Copyright infringement</li>
              <li>Trademark infringement</li>
            </ul>
          </div>
          <div>
            <h1 className="text-xl mt-5">
              CONTENT-RELATED REQUESTS AND DECISIONS
            </h1>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              Additional protection of minors:
            </h1>
            <p className="font-normal text-xl mt-5">We comply with:</p>
            <ul className="disc">
              <li>Requests for removal of an underage account.</li>
              <li>
                Government requests for removal of child abuse imagery
                depicting, for example, beating by an adult or strangling or
                suffocating by an adult.
              </li>
              <li>
                Legal guardian requests for removal of attacks on
                unintentionally famous minors.
              </li>
            </ul>
            <p className="font-normal text-xl mt-5">
              For the following Community Standards, we require additional
              information and/or context to enforce:
            </p>
            <p>
              We may remove content created for the purpose of identifying a
              private minor if there may be a risk to the minor's safety when
              requested by a user, government, law enforcement or external child
              safety experts.
            </p>
          </div>
          <div>
            <h1 className="text-[#007a80] font-medium text-xl mt-5">
              User requests:
            </h1>
            <p className="font-normal text-xl mt-5">We comply with:</p>
            <ul className="disc">
              <li>User requests for removal of their own account.</li>
              <li>
                Requests for removal of a deceased user's account from a
                verified immediate family member or executor.
              </li>
              <li>
                Requests for removal of an incapacitated user's account from an
                authorised representative.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termsofservices;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';
import './App.css';
import Layout from './Layout';
import Main_pg from './Pages/Main_pg';
import Privacy_main from './Pages/Privacy_main';
import Helppage from './Pages/Helppage';
import Blogpage from './Pages/Blogpage';
import Feedbackpage from './Pages/Feedbackpage';
import PageTitle from './Components/PageTitle';
import Termsofservices from './Components/Termsofservices';
import Cookies from './Components/Cookies';
import Privacy_1 from './Components/Privacy_1';
import ChildSafetyPage from './Pages/ChildSafetyPage';

function App() { 
  clarity.init(process.env.REACT_APP_CLARITY_ID)
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<><Main_pg/> <PageTitle title="Welcome to Twigyy" /></>}/>
          <Route path='/privacy' element={<><Privacy_main/><PageTitle title="Twigyy - Privacy"/></>}/>
          <Route path="/help" element={<><Helppage/><PageTitle title="Twigyy - Help Center" /></>}/>
          <Route path='/terms' element={<><Termsofservices/><PageTitle title="Twigyy - Terms of services"/></>}/>
          <Route path='/cookies' element={<><Cookies/><PageTitle title="Twigyy - Cookies policy"/></>}/>
          <Route path='/cookies' element={<><Cookies/><PageTitle title="Twigyy - Cookies policy"/></>}/>
          <Route path='/privacy1' element={<><Privacy_1/><PageTitle title="Twigyy - Privacy policy"/></>}/>
          <Route path="/blog" element={<><Blogpage/><PageTitle title="Twigyy - Blogs" /></>}/>
          <Route path="/feedback" element={<><Feedbackpage/><PageTitle title="Twigyy - Give your feedback" /></>}/>
          <Route path="/child-safety-policy" element={<><ChildSafetyPage/><PageTitle title="Twigyy - Child Safety Policies" /></>}/>
          {/* Define routes here */}
        </Route>
      </Routes>
      </BrowserRouter>   
    </>
  );
}

export default App;

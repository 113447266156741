import React from "react";
import "../Styles/PolicyPages.css";
import { Link } from "react-router-dom";

const ChildSafetyPage = () => {
  return (
    <section className="px-[100px] py-7 mt-10 policy-pages">
      <h1 className="text-[32px] font-medium mb-5">Child Safety Policy - Twigyy</h1>
      <ul className="list-none">
        <li>
          <h2 className="text-[24px] font-medium">Introduction</h2>
          <p className="para">
            At Twigyy, we are committed to ensuring a safe and secure
            environment for children who engage with our platform. We recognize
            our responsibility to protect minors from harmful content,
            exploitation, and privacy violations. This policy outlines our
            approach to safeguarding children and ensuring compliance with legal
            and ethical standards.
          </p>
        </li>
        <li>
          <h3 className="text-[24px] font-medium">Scope</h3>
          <p>We are working on applying this policy to:</p>
          <ul>
            <li>All children under the age of 18 using our platform.</li>
            <li>
              Parents, guardians, and educators who oversee children’s
              activities.
            </li>
            <li>Content creators, developers, and third-party partners.</li>
            <li>
              Employees and moderators responsible for child safety enforcement.
            </li>
          </ul>
        </li>
        <li>
          <h3>Compliance with Child Protection Laws</h3>
          <p>
            We are working on ensuring compliance with international and
            national child protection laws, including:
          </p>
          <ul>
            <li>
              Children’s Online Privacy Protection Act (COPPA) – Restricting
              data collection from children under 13.
            </li>
            <li>
              General Data Protection Regulation for Kids (GDPR-K) – Special
              protections for children under 16.
            </li>
            <li>
              United Nations Convention on the Rights of the Child (UNCRC) –
              Protecting children’s digital rights globally.
            </li>
          </ul>
        </li>
        <li>
          <h3>Child Protection Measures</h3>
          <ul>
            <li>
              <h3>Age Verification and Parental Controls</h3>
              <p>
                We are working on implementing robust age verification systems
                to prevent underage access to inappropriate content.
                Additionally, we are enhancing parental controls to allow
                guardians to monitor and manage their child's activity.
              </p>
            </li>
            <li>
              <h3>Content Moderation</h3>
              <p>
                We are working on strengthening our content moderation policies
                to prevent harmful materials from reaching children. This
                includes:
              </p>
              <ul>
                <li>
                  AI-powered and human moderation teams to detect and remove
                  inappropriate content.
                </li>
                <li>
                  Strict policies against child exploitation, abuse, and
                  cyberbullying.
                </li>
                <li>
                  Immediate action against any content violating child safety
                  standards.
                </li>
              </ul>
            </li>
            <li>
              <h3>Protection Against Online Exploitation and Harassment</h3>
              <p>
                We are working on improving proactive detection systems to
                identify and block harmful interactions. Our efforts include:
              </p>
              <ul>
                <li>Preventing cyberbullying and online harassment.</li>
                <li>
                  Restricting unauthorized contact between adults and minors.
                </li>
                <li>
                  Implementing stricter controls on sharing personal
                  information.
                </li>
              </ul>
            </li>
            <li>
              <h3>Data Privacy and Security</h3>
              <p>
                We are working on minimizing data collection from children while
                ensuring their privacy is protected. This includes:
              </p>
              <ul>
                <li>
                  No targeted advertising or behavioral tracking for minors.
                </li>
                <li>
                  Allowing parents and children to review and request data
                  deletion.
                </li>
                <li>
                  Strengthening encryption and security measures for child data
                  protection.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h3>Reporting and Response Mechanisms</h3>
          <p>
            We are working on making it easier for children, parents, and users
            to report harmful content or suspicious behavior. Our system
            includes:
          </p>
          <ul>
            <li>
              A 24x7 response team to investigate and act on reports
              immediately.
            </li>
            <li>
              Direct collaboration with law enforcement for illegal activities
              involving minors.
            </li>
            <li>
              Improved tools for children and parents to report safety concerns
              easily.
            </li>
          </ul>
        </li>
        <li>
            <h3>Educating Children and Guardians</h3>
            <p>We are working on expanding our educational resources to promote digital safety. Our initiatives include:</p>
            <ul>
                <li>Interactive safety guides for children, parents, and educators.</li>
                <li>Campaigns on cyberbullying prevention and safe online behavior.</li>
                <li>Partnering with organizations to spread awareness about child protection.</li>
            </ul>
        </li>
        <li>
            <h3>Consequences for Policy Violations</h3>
            <p>We are working on enforcing strict penalties for child safety violations, including:</p>
            <ul>
                <li>Content Removal – Deleting any content that threatens child safety.</li>
                <li>Account Suspension/Bans – Blocking users who violate our child protection policies.</li>
                <li>Legal Action – Reporting criminal activities involving minors to authorities.</li>
            </ul>
        </li>
        <li>
            <h3>Contact and Support</h3>
            <p>We are working on providing better support channels for child safety concerns. For immediate assistance, please contact <a href="mailto:care.twigyy@gmail.com">care.twigyy@gmail.com</a> or visit <Link to="/help">https://twigyy.com/help</Link></p>
        </li>
      </ul>
    </section>
  );
};

export default ChildSafetyPage;
